import { Documenten as DocumentenDlEntry } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { documentenSchema } from "./documenten-schema";

export const mapDocumentenUiToDl = createMapToDl(documentenSchema).to<DocumentenDlEntry>({
  lastenoverzichten: v => ({
    brutoInkomen: v.kolommenInDeLastenoverzichten?.brutoInkomen,
    brutoLast: v.kolommenInDeLastenoverzichten?.brutoLast,
    fiscaalVoordeel: v.kolommenInDeLastenoverzichten?.fiscaalVoordeel,
    huur: v.kolommenInDeLastenoverzichten?.huur,
    hypotheekAflossing: v.kolommenInDeLastenoverzichten?.hypotheekAflossing,
    kredietlast: v.kolommenInDeLastenoverzichten?.kredietLast,
    nettoLast: v.kolommenInDeLastenoverzichten?.nettoLast,
    opnameUitDepot: v.kolommenInDeLastenoverzichten?.opnameUitDepot,
    overigeUitgaven: v.kolommenInDeLastenoverzichten?.overigeUitgaven,
    rente: v.kolommenInDeLastenoverzichten?.rente,
    somHypotheken: v.kolommenInDeLastenoverzichten?.somHypotheken,
    spaarEnBeleggingsdepot: v.kolommenInDeLastenoverzichten?.spaarEnBeleggingsdepot,
    uitkeringKapitaalverzekeringen: v.kolommenInDeLastenoverzichten?.uitkeringKapitaalverzekering,
    verzekeringspremies: v.kolommenInDeLastenoverzichten?.verzekeringspremies,
    vrh: v.kolommenInDeLastenoverzichten?.vrh,
    waardeopbouw: v.kolommenInDeLastenoverzichten?.waardeopbouw
  }),
  nbiOverzicht: v => ({
    brutoInkomen: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.brutoInkomen,
    hypotheek: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.hypotheek,
    inkomstenbelasting: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.inkomstenbelasting,
    kredietLast: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.kredietLast,
    nbi: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.nettoBesteedbaarInkomen,
    overigUitgaven: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.overigeUitgeven,
    spaarEnBeleggingsdepots: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.spaarEnBeleggingsdepots,
    totaleUitgaven: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.totaleUitgaven,
    verzekeringen: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.verzekeringen,
    vrh: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.vrh,
    nettoInkomsten: v.kolommenInHetNettoBesteedbaarInkomenOverzicht?.nettpOnkomsten
  }),
  ondertekening: v => ({
    extraOndertekeningsregel: v.ondertekening?.extraOndertekeningsregel,
    tonenOpFormulieren: v.ondertekening?.tonenOpFormulier,
    weergaveAdviseurnaam: v.ondertekening?.weergaveAdviseurnaam
  }),
  overzichten: v => ({
    bedrijfslogoOverzichtenTonen: v.instelingenOverzichten?.bedrijfslogoTonenOpOverzichten,
    overzichtTonenVan: v.instelingenOverzichten?.overzichtTonenVan,
    toonGrafiekScenarioOverzicht: v.instelingenOverzichten.toonGrafiekenBijScenarioOverzichten
  }),
  voorbladVervolgpaginas: v => ({
    bedrijfsgegevensVervolgpaginasTonen: v.instelingenVoorbladEnVervolgpaginas?.bedrijfslogoOpVervolgpaginaSTonen,
    bedrijfsgegevensVoorbladTonen: v.instelingenVoorbladEnVervolgpaginas?.bedrijfsgegevensTonenOpVoorblad,
    bedrijfslogoVoorbladTonen: v.instelingenVoorbladEnVervolgpaginas?.bedrijfslogoTonenOpVoorblad,
    uitlijningBedrijfslogoVervolgpaginas: v.instelingenVoorbladEnVervolgpaginas?.uitlijningBedrijfslogoOpVervolgpagina,
    uitlijningBedrijfsgegevensVoorblad: v.instelingenVoorbladEnVervolgpaginas?.uitlijningBedrijfsgegevensOpVoorblad,
    uitlijningBedrijfslogoVoorblad: v.instelingenVoorbladEnVervolgpaginas?.uitlijningLogoOpVoorblad
  })
});
