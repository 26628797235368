import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";
import BeschikbareNotarissen from "./beschikbare-notarissen/beschikbare-notarissen";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { SaveButton } from "../save-button/save-button";
import {
  BeschikbareNotarissenProps,
  BeschikbareNotarissenState,
  beschikbareNotarissenSchema
} from "./infra/notarissen-schema";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Notarissen = (
  props: FormikProps<BeschikbareNotarissenState> & WithSaveData<BeschikbareNotarissenState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset disabled={!(isBeheerder || autorisatieNiveau.notarissenNiveau === InstellingNiveauOptions.Medewerker)}>
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className={"p-2"}>
              <h2 className="p-0">Notarissen&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.notarissenNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Beschikbare notarissen" className="w-100">
            <div className="px-1">
              <BeschikbareNotarissen />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Notarissen.displayName = "Notarissen";

export default withAdviesboxFormik<
  BeschikbareNotarissenProps & WithSaveData<BeschikbareNotarissenState>,
  BeschikbareNotarissenState
>({
  mapPropsToValues: (e: BeschikbareNotarissenProps): BeschikbareNotarissenState => e,
  validationSchema: beschikbareNotarissenSchema
})(Notarissen);
