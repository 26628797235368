import { SetDefaultBemiddelaarInputApiModel } from "../../.generated/bemiddeling/bemiddelingtypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { KoppelingType } from "./koppeling-schema";
/*istanbul ignore file */
type AsyncContext = { hasDeleteEvents: boolean };

export const newCoupledAdvisorPair = createISWAsyncSideEffect<KoppelingType, AsyncContext>(
  async ({ settings, fetchData, formik, draft }): Promise<void> => {
    await Promise.all(
      draft.koppelingen.map(async koppeling => {
        const adviseurID = formik.values.adviseurs.find(
          adviseur => adviseur.adviseurId === koppeling.adviseur.adviseurId
        )?.adviseurId;
        const bemiddelaarID = formik.values.bemiddelaars.find(
          bemiddelaar => bemiddelaar.bemiddelaarId === koppeling.bemiddelaar.bemiddelaarId
        )?.bemiddelaarId;

        if (adviseurID && bemiddelaarID) {
          await fetchData<SetDefaultBemiddelaarInputApiModel>({
            url: `${settings.klantdossiersBemiddelingOrigin}/Bemiddelaar/default`,
            method: "PUT",
            body: JSON.stringify({
              adviseurId: adviseurID,
              bemiddelaarId: bemiddelaarID
            })
          });
        }
      })
    );
  }
);

export const deleteCoupledAdvisorPair = createISWAsyncSideEffect<KoppelingType>(
  async ({ settings, fetchData, draft }): Promise<void> => {
    if (draft.deleteEvent.length > 0) {
      await Promise.all(
        draft.deleteEvent.map(async deletedId => {
          const indexAdviseur = draft.adviseurs.findIndex(advisuer => advisuer.adviseurId === deletedId);
          const indexKoppeling = draft.koppelingen.findIndex(koppeling => koppeling.adviseur.adviseurId === deletedId);

          if (indexKoppeling !== -1) {
            return;
          }

          if (indexAdviseur !== -1) {
            draft.adviseurs[indexAdviseur].coupledBemiddelaarId = "";
          }

          await fetchData<SetDefaultBemiddelaarInputApiModel>({
            url: `${settings.klantdossiersBemiddelingOrigin}/Bemiddelaar/default`,
            method: "PUT",
            body: JSON.stringify({
              adviseurId: deletedId,
              bemiddelaarId: null
            })
          });
        })
      );

      draft.deleteEvent = [];
    }
  }
);

export const koppelingAsyncSideEffects = initISWAsyncSideEffect<KoppelingType>(({ has, curr, runAsync }): void => {
  if (has.update.changed) {
    runAsync(newCoupledAdvisorPair({ hasDeleteEvents: curr.deleteEvent.length > 0 }));

    if (curr.deleteEvent.length > 0) {
      runAsync(deleteCoupledAdvisorPair());
    }
  }
})();
