/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { VermogensbeheerdersOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import {
  mapDlTargetToVermogensbeheerdersUiField,
  mapVermogensbeheerdersDlToUi,
  mapVermogensbeheerdersUiToDl
} from "./infra";
import Vermogensbeheerders from "./vermogensbeheerders";

const VermogensbeheerdersAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData
  const { user, requestInit, params, settings } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Vermogensbeheerders`;

  const { loading, error, data } = useAbortableFetch<VermogensbeheerdersOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapVermogensbeheerdersDlToUi("vermogensbeheerders", data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Vermogensbeheerders
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapVermogensbeheerdersUiToDl,
        mapDlTargetToVermogensbeheerdersUiField
      )}
      {...mappedProps}
    />
  );
};

VermogensbeheerdersAjax.displayName = "VermogensbeheerdersAjax";

export default withErrorBoundary(VermogensbeheerdersAjax);
