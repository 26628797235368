/* istanbul ignore file*/
import { StandaardTeksten as StandaardTekstenDlEntry } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

export function mapDlTargetToDStandaardTekstenUiField(target: string): UiName | null {
  const map: FieldMap<StandaardTekstenDlEntry> = {};

  return target2field(map, target);
}
