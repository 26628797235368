import { MenuConfigType } from "adviesbox-shared";
import { Autorisatieniveau } from "../.generated/instellingen-forms/instellingen-formstypes";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";

const hasAuthorization = (usageLevel?: string | null): boolean => {
  return usageLevel === "Medewerker";
};

export const menuConfig = (
  menuInfo: Autorisatieniveau,
  params: RouteParams,
  isBeheerder: boolean
): MenuConfigType[] => [
  {
    name: "algemeen",
    title: "Algemeen",
    lists: [
      {
        name: "dev",
        title: "DEV menu",
        icon: "dev",
        isAllowed: () => process.env.NODE_ENV === "development",
        items: []
      },    
      {
        name: "autorisatieniveau",
        title: "Autorisatieniveau",
        icon: "menuautorisatieniveau",
        link: `/vestiging/${params.vestiging}/autorisatieniveau`
      },
      {
        name: "beheer-partijen",
        title: "Beheer partijen",
        icon: "menubeheerpartijen",
        items: [
          {
            name: "bankgarantie-instellingen",
            title: "Bankgarantieinstellingen",
            link: `/vestiging/${params.vestiging}/bankgarantie-instellingen`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.bankgarantieInstellingenNiveau)
          },
          {
            name: "geldverstrekkers",
            title: "Geldverstrekkers",
            link: `/vestiging/${params.vestiging}/geldverstrekkers`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.geldverstrekkersNiveau)
          },
          {
            name: "notarissen",
            title: "Notarissen",
            link: `/vestiging/${params.vestiging}/notarissen`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.notarissenNiveau)
          },
          {
            name: "serviceproviders",
            title: "Serviceproviders",
            link: `/vestiging/${params.vestiging}/serviceproviders`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.serviceprovidersNiveau)
          },
          {
            name: "software-koppelingen",
            title: "Software koppelingen",
            link: `/vestiging/${params.vestiging}/software-koppelingen`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.softwareKoppelingenNiveau)
          },
          {
            name: "taxatiebureaus",
            title: "Taxatiebureaus",
            link: `/vestiging/${params.vestiging}/taxatiebureaus`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.taxatiebureausNiveau)
          },
          {
            name: "vermogensbeheerders",
            title: "Vermogensbeheerders",
            link: `/vestiging/${params.vestiging}/vermogensbeheerders`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.vermogensbeheerdersNiveau)
          },
          {
            name: "verzekeraars",
            title: "Verzekeraars",
            link: `/vestiging/${params.vestiging}/verzekeraars`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.verzekeraarsNiveau)
          }
        ]
      },

      // {/* niet opgenomen in AB v1*/
      {
        name: "externe-koppelingen",
        title: "Externe koppelingen",
        icon: "menuexternekoppelingen",
        items: [
          // {
          //       name: "generieke-koppelingen",
          //       title: "Generieke koppelingen",
          //       link: `/vestiging/${params.vestiging}/generieke-koppelingen`,
          //       isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.generiekeKoppelingenNiveau)
          //     },
          //     {
          //       name: "hdn",
          //       title: "HDN",
          //       link: `/vestiging/${params.vestiging}/hdn`,
          //       isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.hdnNiveau)
          //     },
          //     {
          //       name: "elements",
          //       title: "Elements",
          //       link: `/vestiging/${params.vestiging}/elements`,
          //       isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.elementsNiveau)
          //     },
          //{
          //  name: "nationale-waarborg",
          //  title: "Nationale Waarborg",
          //  link: `/vestiging/${params.vestiging}/extern/nationale-waarborg`,
          //  isAllowed: () => true //() => isBeheerder || hasAuthorization(menuInfo?.nationaleHypotheekbondNiveau)
          //}
        ]
      },
      
    ]
  },
  {
    name: "advies",
    title: "Advies",
    lists: [
      {
        title: "Voorkeuren",
        name: "voorkeuren",
        icon: "menuvoorkeuren",
        items: [
          {
            title: "Documenten",
            name: "documenten",
            link: `/vestiging/${params.vestiging}/documenten`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.documentNiveau)
          },
          {
            title: "Tarieven",
            name: "tarieven",
            link: `/vestiging/${params.vestiging}/tarieven`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.tarievenNiveau)
          },
          {
            title: "Berekeningen",
            name: "berekeningen",
            link: `/vestiging/${params.vestiging}/berekeningen`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.berekeningenNiveau)
          },
          {
            title: "Lastenoverzicht",
            name: "lastenoverzicht",
            link: `/vestiging/${params.vestiging}/lastenoverzicht`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.lastenoverzichtenNiveau)
          },
          {
            title: "Rapportage samenstellen",
            name: "rapportage-samenstellen",
            link: `/vestiging/${params.vestiging}/rapportage-samenstellen`,
            isAllowed: () => isBeheerder //TODO: 60953 AutorisatieNiveau is missing for rapportage samenstellen
          }
        ]
      },
      {
        name: "adviesinstellingen",
        title: "Adviesinstellingen",
        icon: "menuadviesinstellingen",
        items: [
          {
            name: "klantprofiel-en-motivering",
            title: "Klantprofiel en motivering",
            link: `/vestiging/${params.vestiging}/klantprofiel-en-motivering`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.klantprofielMotiveringNiveau)
          },
          {
            name: "adviesonderwerpen",
            title: "Adviesonderwerpen",
            link: `/vestiging/${params.vestiging}/adviesonderwerpen`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.adviesonderwerpenNiveau)
          },
          {
            name: "uitgangspunten-advies",
            title: "Uitgangspunten Advies",
            link: `/vestiging/${params.vestiging}/uitgangspunten-advies`,
            isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.uitgangspuntenAdviesNiveau)
          }
        ]
      },
      {
        name: "standaardteskten",
        title: "Standaardteksten",
        icon: "menustandaardteksten",
        isAllowed: () => isBeheerder || hasAuthorization(menuInfo?.standaardTekstenNiveau),
        items: [
          {
            name: "importeren-en-exporteren",
            title: "Importeren en exporteren",
            link: `/vestiging/${params.vestiging}/standaardteksten/importeren-en-exporteren`
          },
          {
            name: "standaardteksten-algemeen",
            title: "Algemeen",
            link: `/vestiging/${params.vestiging}/standaardteksten/motivering/algemeen`
          },
          {
            name: "standaardteksten-hypotheek",
            title: "Hypotheek",
            items: [
              {
                name: "standaardteksten-hypotheek-verduurzaming",
                title: "Verduurzaming",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/energie`
              },
              {
                name: "standaardteksten-hypotheek-verantwoorde-woonlasten",
                title: "Verantwoorde woonlasten",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/verantwoordewoonlasten`
              },
              {
                name: "standaardteksten-hypotheek-eigen-geld",
                title: "Eigen geld",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/inbrengeigengeld`
              },
              {
                name: "standaardteksten-hypotheek-dubbele-woonlasten",
                title: "Dubbele woonlasten",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/dubbelewoonlasten`
              },
              {
                name: "standaardteksten-hypotheek-fiscaal",
                title: "Fiscaal",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/fiscaleaftrek`
              },
              {
                name: "standaardteksten-hypotheek-hypotheekvorm",
                title: "Hypotheekvorm",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/hypotheekaflossen`
              },
              {
                name: "standaardteksten-hypotheek-rentevastperiode",
                title: "Rentevastperiode",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/rentevastperiode`
              },
              {
                name: "standaardteksten-hypotheek-geldverstrekker",
                title: "Geldverstrekker",
                link: `/vestiging/${params.vestiging}/standaardteksten/hypotheek/geldverstrekker`
              }
            ]
          },
          {
            name: "standaardteksten-risicos",
            title: "Risico's",
            items: [
              {
                name: "standaardteksten-risicos-overlijden",
                title: "Overlijden",
                link: `/vestiging/${params.vestiging}/standaardteksten/risicos/afdekkenoverlijdensrisico`
              },
              {
                name: "standaardteksten-risicos-arbeidsongeschiktheid",
                title: "Arbeidsongeschiktheid",
                link: `/vestiging/${params.vestiging}/standaardteksten/risicos/afdekkenaoww`
              },
              {
                name: "standaardteksten-risicos-werkloosheid",
                title: "Werkloosheid",
                link: `/vestiging/${params.vestiging}/standaardteksten/risicos/afdekkenww`
              },
              {
                name: "standaardteksten-risicos-pensioen",
                title: "Pensioen",
                link: `/vestiging/${params.vestiging}/standaardteksten/risicos/pensioenvoorziening`
              }
            ]
          },
          {
            name: "standaardteksten-vermogensopbouw",
            title: "Vermogensopbouw",
            items: [
              {
                name: "standaardteksten-vermogensopbouw-opbouw-doelvermogen",
                title: "Opbouw Doelvermogen",
                link: `/vestiging/${params.vestiging}/standaardteksten/vermogensopbouw/wijzeopbouwdoelvermogen`
              },
              {
                name: "standaardteksten-vermogensopbouw-risicoprofiel",
                title: "Risicoprofiel",
                link: `/vestiging/${params.vestiging}/standaardteksten/vermogensopbouw/risicoprofiel`
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: "Exter",
    title: "extern",
    lists:[
      {
        name: "Adviesbox - lokale installatie",
        title: "Koppeling Adviesbox",
        icon: "menuautorisatieniveau",
        link: `/vestiging/${params.vestiging}/koppeling`
      },
    ]
  }
  // Logboek momenteel nog niet bruikbaar
  // {
  //   name: "logboek",
  //   title: "Logboek",
  //   lists: [
  //     {
  //       name: "logboek",
  //       title: "Logboek",
  //       icon: "menulogboek"
  //     }
  //   ]
  // }
];
