import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { beschikbareGeldverstrekkersPartijen, geldverstrekkersSchema } from ".";
import { GeldverstrekkerPartij, Geldverstrekkers } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { hasValue } from "../../shared/utils/helpers";

const mapPartijen = createMapToDl(beschikbareGeldverstrekkersPartijen).to<GeldverstrekkerPartij>({
  aanvullendeRetourwijze: v => (hasValue(v.aanvullendeRetourwijze) ? parseInt(v.aanvullendeRetourwijze) : null),
  actief: v => v.actief,
  agentnummer: v => v.agentnummer,
  code: v => v.code,
  hypotheeklabels: v => v.hypotheeklabels,
  partijnaam: v => v.partijnaam,
  serviceproviderId: v => v.serviceproviderId,
  softwareKoppelingId: v => v.softwareKoppelingId
});

export const mapGeldverstrekkersUiToDl = createMapToDl(geldverstrekkersSchema).to<Geldverstrekkers>({
  partijen: v => v.beschikbareGeldverstrekkers.partijen.map(c => mapPartijen(c)),
  serviceproviders: v => v.beschikbareGeldverstrekkers.serviceProviders,
  softwareKoppelingen: v => v.beschikbareGeldverstrekkers.softwareKoppelingen
});

export function mapDlTargetToGeldverstrekkersUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
