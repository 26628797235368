import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { SoftwareKoppelingenType, softwareKoppelingenSchema } from "./software-koppelingen-schema";

function dl2ui(values: any): SoftwareKoppelingenType {
  const softwareKoppelingen = {
    ...softwareKoppelingenSchema.default(),
    partijen: values.partijen
    ? values.partijen.sort((a: any, b: any): number => {
        return new Intl.Collator(["NL"]).compare(a.naam, b.naam);
      })
    : []
  };

  return softwareKoppelingenSchema.cast(softwareKoppelingen);
}

export function mapSoftwareKoppelingenDlToUi(softwareKoppelingId: string, data: any): SoftwareKoppelingenType | null {
  const softwareKoppelingen = data ? data[softwareKoppelingId] : null;

  if (!softwareKoppelingen) {
    return null;
  }

  return dl2ui(softwareKoppelingen);
}

export function mapSoftwareKoppelingenUiToDl(softwareKoppelingen: SoftwareKoppelingenType): any {
  return {
    partijen: (softwareKoppelingen.partijen || []).map(p => ({
      softwareKoppelingId: p.softwareKoppelingId,
      naam: p.naam,
      actief: p.actief,
      hdnNummer: p.hdnNummer
    }))
  };
}

export function mapDlTargetToSoftwareKoppelingenUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
