import React, { ReactElement, useState } from "react";
import {
  LabeledTextInput,
  FormFirstFocus,
  PlatformFoutenSamenvatting,
  useRequestInit,
  ErrorPage,
  PageLoading,
  AdviesBoxFormik
} from "adviesbox-shared";
import Modal from "../../shared/components/modal/Modal";
import useAbortableFetch from "use-abortable-fetch";
import { ElementsOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { FormikProps } from "formik";
import { ElementsInstellingenType, elementsInstellingenSchema } from "../infra";
import { softwareKoppelingWijzigenModalApi } from "./elements-instellingen-modal-api";

export const ElementsInstellingenModal = ({
  onSave,
  closeModal
}: {
  onSave?: () => void;
  closeModal?: () => void;
}): ReactElement => {
  const { settings, user, params } = useRequestInit<{ vestiging: string }>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/ExterneKoppelingen/Elements`;
  const { data, loading, error } = useAbortableFetch<ElementsOutput>(url, requestInit);

  /* istanbul ignore next */
  if (error || typeof data === "string") {
    const errorMessage = error
      ? error
      : { message: "Ophalen van software koppeling instellingen mislukt", status: 500, name: "elements koppeling" };
    return <ErrorPage error={errorMessage} />;
  }

  /* istanbul ignore next */
  if (loading || !data) {
    return <PageLoading />;
  }

  const elementsInstellingen = { ...data.elements, wachtwoordApi: "" } as ElementsInstellingenType;

  const body = (
    <>
      <PlatformFoutenSamenvatting />
      <FormFirstFocus>
        <LabeledTextInput
          caption="Locatie portaal"
          tooltip="Ga naar het inlogscherm van de Elements omgeving en kopieer de link uit je browser in dit veld"
          name="locatiePortaalUrl"
          fieldSize={"xxl"}
        />
        <LabeledTextInput
          caption="Organisatienummer"
          tooltip="Vraag deze op bij de Elements supportdesk"
          name="organisatienummer"
          fieldSize={"xxl"}
        />
        <LabeledTextInput
          caption="Gebruikersnaam API"
          tooltip="Vraag deze op bij de Elements supportdesk"
          name="gebruikersnaamApi"
          fieldSize={"xxl"}
        />
        <LabeledTextInput
          placeholder="********"
          tooltip="Vraag deze op bij de Elements supportdesk"
          caption="Wachtwoord API"
          name="wachtwoordApi"
          type="password"
          fieldSize={"xxl"}
        />
      </FormFirstFocus>
    </>
  );

  return (
    <>
      <AdviesBoxFormik<ElementsInstellingenType>
        initialValues={{ ...elementsInstellingen }}
        validationSchema={elementsInstellingenSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ submitForm, dirty, values }: FormikProps<ElementsInstellingenType>): ReactElement => (
          <>
            <Modal
              onCancelClick={closeModal}
              title="Instellingen wijzigen"
              body={body}
              onSubmitClick={async () => {
                setSubmitDisabled(true);
                await softwareKoppelingWijzigenModalApi(settings, user, params.vestiging, values);
                setSubmitDisabled(false);
                await submitForm();
              }}
              submitDisabled={submitDisabled || !dirty}
            />
          </>
        )}
      />
    </>
  );
};
