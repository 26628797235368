import * as Yup from "yup";
import {
  TonenOpFormulierenOptions,
  WeergaveAdviseurnaamOptions,
  OverzichtTonenVanOptions,
  UitlijningOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { WithSaveData } from "../../shared/utils/save-data";
import { yupEnum } from "../../shared/utils/yup-enum";

export const ondertekeningSchema = Yup.object({
  tonenOpFormulier: yupEnum(TonenOpFormulierenOptions).default(TonenOpFormulierenOptions.AdviseurnaamVanHoofdkantoor),
  weergaveAdviseurnaam: yupEnum(WeergaveAdviseurnaamOptions).default(WeergaveAdviseurnaamOptions.VoorlettersAchternaam),
  voorbeeldweergaveAdviseurnaam: Yup.string().default("A. de Adviseur"),
  extraOndertekeningsregel: Yup.string().default("")
});
export type OndertekeningType = Yup.InferType<typeof ondertekeningSchema>;

export const instelingenOverzichtenSchema = Yup.object({
  bedrijfslogoTonenOpOverzichten: Yup.boolean().default(false),
  overzichtTonenVan: yupEnum(OverzichtTonenVanOptions).default(OverzichtTonenVanOptions.AlleJaren),
  toonGrafiekenBijScenarioOverzichten: Yup.boolean().default(true)
});
export type InstelingenOverzichtenType = Yup.InferType<typeof instelingenOverzichtenSchema>;

export const instelingenVoorbladEnVervolgpaginasSchema = Yup.object({
  bedrijfslogoTonenOpVoorblad: Yup.boolean().default(true),
  uitlijningLogoOpVoorblad: yupEnum(UitlijningOptions).default(UitlijningOptions.Links),
  bedrijfsgegevensTonenOpVoorblad: Yup.boolean().default(true),
  uitlijningBedrijfsgegevensOpVoorblad: yupEnum(UitlijningOptions).default(UitlijningOptions.Links),
  bedrijfslogoOpVervolgpaginaSTonen: Yup.boolean().default(true),
  uitlijningBedrijfslogoOpVervolgpagina: yupEnum(UitlijningOptions).default(UitlijningOptions.Links)
});
export type InstelingenVoorbladEnVervolgpaginasType = Yup.InferType<typeof instelingenVoorbladEnVervolgpaginasSchema>;

export const kolommenInDeLastenoverzichtenSchema = Yup.object({
  brutoInkomen: Yup.boolean(),
  somHypotheken: Yup.boolean(),
  waardeopbouw: Yup.boolean(),
  huur: Yup.boolean(),
  rente: Yup.boolean(),
  hypotheekAflossing: Yup.boolean(),
  uitkeringKapitaalverzekering: Yup.boolean(),
  verzekeringspremies: Yup.boolean(),
  spaarEnBeleggingsdepot: Yup.boolean(),
  opnameUitDepot: Yup.boolean(),
  kredietLast: Yup.boolean(),
  overigeUitgaven: Yup.boolean(),
  brutoLast: Yup.boolean(),
  fiscaalVoordeel: Yup.boolean(),
  vrh: Yup.boolean(),
  nettoLast: Yup.boolean()
});
export type KolommenInDeLastenoverzichtenType = Yup.InferType<typeof kolommenInDeLastenoverzichtenSchema>;

export const kolommenInHetNettoBesteedbaarInkomenOverzichtSchema = Yup.object({
  hypotheek: Yup.boolean(),
  verzekeringen: Yup.boolean(),
  spaarEnBeleggingsdepots: Yup.boolean(),
  kredietLast: Yup.boolean(),
  overigeUitgeven: Yup.boolean(),
  totaleUitgaven: Yup.boolean(),
  brutoInkomen: Yup.boolean(),
  inkomstenbelasting: Yup.boolean(),
  vrh: Yup.boolean(),
  nettpOnkomsten: Yup.boolean(),
  nettoBesteedbaarInkomen: Yup.boolean()
});
export type KolommenInHetNettoBesteedbaarInkomenOverzichtType = Yup.InferType<
  typeof kolommenInHetNettoBesteedbaarInkomenOverzichtSchema
>;

export const documentenSchema = Yup.object({
  ondertekening: ondertekeningSchema,
  instelingenOverzichten: instelingenOverzichtenSchema,
  instelingenVoorbladEnVervolgpaginas: instelingenVoorbladEnVervolgpaginasSchema,
  afbeeldingVoorblad: Yup.string().nullable(),
  kolommenInDeLastenoverzichten: kolommenInDeLastenoverzichtenSchema,
  kolommenInHetNettoBesteedbaarInkomenOverzicht: kolommenInHetNettoBesteedbaarInkomenOverzichtSchema
});
export type DocumentenState = Yup.InferType<typeof documentenSchema>;
export type DocumentenProps = Yup.InferType<typeof documentenSchema> & WithSaveData<DocumentenState>;
