import React, { ReactElement } from "react";
import { AutorisatieNiveauGridProps, AutorisatieNiveauState } from "./autorisatie-niveau-schema";
import { connect, FormikContextType} from "formik";
import AutorisatieNiveauRow from "./autorisatie-niveau-row";
import classes from "./autorisatie-niveau-grid.module.scss";

const AutorisatieNiveauGrid = ({
  options,
  formikKey
}: AutorisatieNiveauGridProps & { formik: FormikContextType<AutorisatieNiveauState> }): ReactElement => {
  const centerElementByClassNameString = "d-flex align-items-center justify-content-center h-100";

  return options.length ? (
    <>
      <div className="container row p-0 m-0">
        <div className="col-12 row mx-0 px-2 py-1">
          <div className="col-6 p-0"></div>
          <div className="col-2 p-0">
            <span className={`${centerElementByClassNameString} ${classes.table_header}`}>Organisatie</span>
          </div>
          <div className="col-2 p-0">
            <span className={`${centerElementByClassNameString} ${classes.table_header}`}>Vestiging</span>
          </div>
          <div className="col-2 p-0">
            <span className={`${centerElementByClassNameString} ${classes.table_header}`}>Gebruiker</span>
          </div>
        </div>
      </div>
      <div className="container row p-0 m-0 border">
        {options.map(
          (option, index): ReactElement => {
            return (
              <AutorisatieNiveauRow
                key={`formikKey-${option}-${index}`}
                formikKey={formikKey}
                name={option}
                index={index}
              />
            );
          }
        )}
      </div>
    </>
  ) : (
    <div>Geen opties meegeleverd</div>
  );
};
export default connect<AutorisatieNiveauGridProps, AutorisatieNiveauState>(AutorisatieNiveauGrid);
