import { createContext } from "react";

export type vestiging = {
  vestigingId: string;
  naam: string;
  bezoekadres: {
    straatnaam: string;
    huisnummer: string;
    toevoeging: string;
    postcode: string;
    land: string;
    plaats: string;
  };
  postadres: {
    straatnaam: string;
    huisnummer: string;
    toevoeging: string;
    postcode: string;
    land: string;
    plaats: string;
  };
  emailadres: string;
  telefoonnummer: string;
  platformId: string;
  isHoofdvestiging: boolean;
};

export const defaultSettings = {
  vestigingen: [] as vestiging[],
  setVestigingen: (_vestigingen: vestiging[]): void => {}
};

export type VestigingenContextType = typeof defaultSettings;

const VestigingenContext = createContext(defaultSettings);

export default VestigingenContext;
