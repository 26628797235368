import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { StandaardTeksten as StandaardTekstDlEntry } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { standaardTekstenSchema } from "./standaard-teksten-schema";

export const mapStandaardTekstenUiToDl = createMapToDl(standaardTekstenSchema).to<StandaardTekstDlEntry>({
  standaardTekstenMotiveringOnderwerp: v =>
    v.teksten.map(c => {
      return {
        standaardtekstVoor: c.tekstVoor,
        korteOmschrijving: c.korteOmschrijving,
        tekst: c.standaardTekst
      };
    })
});
