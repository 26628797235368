/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { RapportageOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapRapportageSamenstellenDlTargetToUiField } from "./infra/map-rapportage-samenstellen-dl-target-to-ui-field";
import { mapRapportageSamenstellenDlToUi } from "./infra/map-rapportage-samenstellen-dl-to-ui";
import { mapRapportageSamenstellenUiToDl } from "./infra/map-rapportage-samenstellen-ui-to-dl";
import { RapportageSamenstellen } from "./rapportage-samenstellen";

const RapportageSamenstellenAjaxComponent = (): ReactElement => {
  const { settings, user, params, requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/Rapportage`;
  const { loading, error, data } = useAbortableFetch<RapportageOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapRapportageSamenstellenDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <RapportageSamenstellen
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapRapportageSamenstellenUiToDl,
        mapRapportageSamenstellenDlTargetToUiField
      )}
      {...mappedProps}
    />
  );
};

export const RapportageSamenstellenAjax = withErrorBoundary(RapportageSamenstellenAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  RapportageSamenstellenAjax.displayName = "RapportageSamenstellenAjax";
