import React, { ReactElement } from "react";
import { FormikContextType } from "formik";
import { CellInfo } from "react-table-6";
import { UitgangspuntenState } from "../infra";
import classes from "./arbeidsongeschiktheid.module.scss";
import { Icon, CheckBox, AdviesBoxColumn } from "adviesbox-shared";

export const EmptyComponent = (): null => null;

const createSpanWithId = (c: CellInfo): ReactElement => {
  return <span id={`${c.index}-row-scenario-arbeidsongeschiktheid`}>{c.original.scenario}</span>;
};

export const DeleteButton = (formik: FormikContextType<UitgangspuntenState>): ((c: CellInfo) => ReactElement) => (
  c: CellInfo
): ReactElement => {
  const arbeidsongeschiktheid = formik.values.arbeidsongeschiktheid;
  const { setFieldValue } = formik;

  const onClick = (): void => {
    const curr = arbeidsongeschiktheid.scenarios;

    curr?.splice(c.index, 1);
    setFieldValue("arbeidsongeschiktheid.scenarios", curr);
    setFieldValue("dirty", true);
  };

  return c.index === 0 ? (
    <></>
  ) : (
    <div
      data-testid={`delete-arbeidsongeschiktheid-${c.index}`}
      id={`delete-arbeidsongeschiktheid-${c.index}`}
      onClick={onClick}
    >
      <Icon name="prullenbak" alt="prullenbak" />
    </div>
  );
};

export const createActiveCheckboxCell = (
  formik: FormikContextType<UitgangspuntenState>
): ((c: CellInfo) => ReactElement) => {
  const activeCheckboxCell = (c: CellInfo): ReactElement => {
    const arbeidsongeschiktheid = formik.values.arbeidsongeschiktheid;
    const aoPercentage = arbeidsongeschiktheid.scenarios[c.index]?.aoPercentage || 50;
    const minimumAoPercentage = aoPercentage >= 80 ? false : true;
    return (
      <div className={classes.clickable_label_fix}>
        <CheckBox
          value=""
          name={`arbeidsongeschiktheid.scenarios[${c.index}].duurzaamAo`}
          caption=""
          disabled={minimumAoPercentage}
        />
      </div>
    );
  };
  activeCheckboxCell.displayName = "activeCheckboxCell";

  return activeCheckboxCell;
};

export const getColumns = (formik: FormikContextType<UitgangspuntenState>): AdviesBoxColumn[] => {
  return [
    {
      Header: "Scenario",
      Cell: createSpanWithId
    },
    {
      Header: "AO percentage",
      accessor: "aoPercentage",
      Cell: "PercentageInput",
      config: { readonly: false, decimalen: 2 }
    },
    {
      Header: "Duurzaam AO",
      Cell: createActiveCheckboxCell(formik)
    },
    {
      Header: "Benuttiging verdiencapaciteit",
      accessor: "verdiencapaciteitPercentage",
      Cell: "PercentageInput",
      config: { readonly: false, decimalen: 2 }
    },
    {
      Cell: DeleteButton(formik),
      maxWidth: 30
    }
  ];
};
