import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { mapDlTargetToKoppelingUiField } from "./infra/map-koppeling-dl-target-to-ui-field";
import { mapKoppelingDlNaarUi } from "./infra/map-koppeling-dl-to-ui";
import { Ui2dlKoppeling } from "./infra/map-koppeling-ui-to-dl";
import Koppelingscherm from "./koppelingscherm";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string => `${s.klantdossiersBemiddelingOrigin}/Bemiddelaar?actief=true`;

export const KoppelingAjax = (): ReactElement => {
  const { loading, error, data } = useAdviesboxData(
    createUrl,
    p => p.vestiging,
    mapKoppelingDlNaarUi,
    Ui2dlKoppeling,
    mapDlTargetToKoppelingUiField
  );

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return <Koppelingscherm {...data} />;
};
KoppelingAjax.displayName = "KoppelingAjax";
export default withErrorBoundary(KoppelingAjax);
