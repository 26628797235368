import * as Yup from "yup";
import { InferType } from "yup";

export const meenemenSchema = Yup.object({
  kredietlasten: Yup.boolean().default(false),
  erfpacht: Yup.boolean().default(false),
  servicekosten: Yup.boolean().default(false),
  financieringskosten: Yup.boolean().default(false),
  boeterente: Yup.boolean().default(false),
  gespreideBetaling: Yup.boolean().default(false)
});

export const verrekenenSchema = Yup.object({
  uitkeringenFinancieleDekkingen: Yup.boolean().default(false),
  aflossingenHypotheek: Yup.boolean().default(false),
  aanvangsstortingen: Yup.boolean().default(false),
  extraStortingen: Yup.boolean().default(false),
  onttrekkingen: Yup.boolean().default(false),
  extraOpnames: Yup.boolean().default(false),
  jaar1AlleenNieuweSituatie: Yup.boolean().default(false)
});

export const lastenoverzichtSchema = Yup.object({
  meenemen: meenemenSchema,
  verrekenen: verrekenenSchema
});

export type LastenoverzichtState = InferType<typeof lastenoverzichtSchema>;
export type LastenoverzichtProps = InferType<typeof lastenoverzichtSchema>;

export type MeenemenType = InferType<typeof meenemenSchema>;
export type VerrekenenType = InferType<typeof verrekenenSchema>;
