import React, { ReactElement, ChangeEvent, useState, RefObject, useRef } from "react";
import { Column, CellInfo } from "react-table-6";
import { CheckBox, Icon, TooltipWrap } from "adviesbox-shared";

import classes from "./beschikbare-geldverstrekkers.module.scss";
import { Modal as ModalBootstrap } from "react-bootstrap";
import { GeldverstrekkersState, AanvraagType } from "../infra";
import { FormikContextType } from "formik";
import classnames from "classnames";
import { UseHdnDataResult } from "../../shared/components/hdn-keuzelijst/use-hdn-data";
import ModalButton from "../../shared/components/modal/ModalButton";
import HdnKeuzelijst from "../../shared/components/hdn-keuzelijst/HdnKeuzelijst";
import { LabelValuePairs } from "adviesbox-shared/utils/types";

export const EmptyComponent = (): null => null;

const createSpanWithId = (c: CellInfo): ReactElement => {
  return <span id={`${c.index}-row-partijnaam-geldverstrekkers`}>{c.original.partijnaam}</span>;
};

export const HypotheekOptiesModal = ({
  closeModal,
  cellIndex,
  formik
}: {
  cellIndex: number;
  formik: FormikContextType<GeldverstrekkersState>;
  closeModal?: () => void;
}): ReactElement => {
  const { setFieldValue, values } = formik;
  const [hypotheekLabels, setHypotheekLabels] = useState(
    values.beschikbareGeldverstrekkers.partijen[cellIndex].hypotheeklabels
  );

  const onChange = (hlIndex: number, hoIndex: number) => (e: ChangeEvent<HTMLInputElement>): void => {
    const newVal = hypotheekLabels;
    newVal[hlIndex].hypotheekopties[hoIndex].geselecteerd = e.target.checked;
    setHypotheekLabels(newVal);
  };

  const submit = (): void => {
    setFieldValue(`beschikbareGeldverstrekkers.partijen[${cellIndex}].hypotheeklabels`, hypotheekLabels);
    closeModal && closeModal();
  };

  return (
    <div>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>Beschikbare hypotheekopties</ModalBootstrap.Title>
          <button type="button" className={"close ml-auto"} onClick={closeModal} id={`btnClose-hypotheekopties`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body className="pb-0">
        <div>
          {values.beschikbareGeldverstrekkers.partijen[cellIndex].hypotheeklabels.map((hl, hlIndex: number) => {
            return (
              <div key={`${hlIndex}-wrapper`}>
                {values.beschikbareGeldverstrekkers.partijen[cellIndex].hypotheeklabels[hlIndex].hypotheekopties &&
                values.beschikbareGeldverstrekkers.partijen[cellIndex].hypotheeklabels[hlIndex].hypotheekopties
                  .length ? (
                  <div key={`${hl.code}-${cellIndex}-${hlIndex}`} className="mb-3">
                    <h2>{hl.omschrijving}</h2>
                    {values.beschikbareGeldverstrekkers.partijen[cellIndex].hypotheeklabels[
                      hlIndex
                    ].hypotheekopties.map(
                      (ho, hoIndex: number): ReactElement => {
                        return (
                          <div
                            key={`${hl.code}-${cellIndex}-${hlIndex}-${hoIndex}`}
                            // ../../shared/components/checkbox-input/CheckboxInput.module.scss: checkbox
                            className={`checkbox d-flex align-items-center`}
                          >
                            <input
                              id={`checkbox-${hlIndex}-${hoIndex}`}
                              data-testid={`checkbox-${hlIndex}-${hoIndex}`}
                              onChange={onChange(hlIndex, hoIndex)}
                              type="checkbox"
                              defaultChecked={!!ho.geselecteerd}
                            />
                            <label
                              htmlFor={`checkbox-${hlIndex}-${hoIndex}`}
                              // ../../shared/components/checkbox-input/CheckboxInput.module.scss: checkbox_label
                              className={classnames("checkbox_label", "ml-2")}
                            >
                              <span>{ho.omschrijving}</span>
                               <TooltipWrap
                                  name={`${hl.code}-${cellIndex}-${hlIndex}-${hoIndex}`}
                                  warningText={ho.toelichting||""}
                                  iconType="questionmark"
                                  placement="bottom"
                                  tooltipClasses="mx-1"
                                />
                            </label>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer className={classes.modal_footer}>
        <button type="button" className="btn btn-link" id="btnannuleren" onClick={closeModal}>
          Annuleren
        </button>
        <button type="button" className="btn btn-primary" id="btnopslaan" onClick={submit}>
          Bevestigen
        </button>
      </ModalBootstrap.Footer>
    </div>
  );
};

export const createModalButton = (
  formik: FormikContextType<GeldverstrekkersState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values } = formik;
  return (
    <div className={classes.align_center}>
      {values.beschikbareGeldverstrekkers.partijen[c.index].hypotheeklabels.length &&
      values.beschikbareGeldverstrekkers.partijen[c.index].hypotheeklabels.filter(
        hl => hl.hypotheekopties && hl.hypotheekopties.length
      ).length ? (
        <ModalButton
          parent={`beschikbareGeldverstrekkers.partijen[${c.index}]`}
          key={`${c.index}`}
          content={
            <div className={classes.hypotheek_opties_button}>
              <Icon name="motivering" alt="motivering" multiColor={false} />
            </div>
          }
        >
          <HypotheekOptiesModal formik={formik} cellIndex={c.index} />
        </ModalButton>
      ) : null}
    </div>
  );
};

export const createHDNKeuzeLijst = (hdnData: UseHdnDataResult) => (c: CellInfo): ReactElement => {
  return (
    <div className="input-group">
      <div className="w-100">
        <HdnKeuzelijst
          berichtSoortType="AX"
          name={`beschikbareGeldverstrekkers.partijen[${c.index}].aanvullendeRetourwijze`}
          keuzelijst="AanvullendeVerzendwijzeType"
          fieldSize="grid-cell"
          hdnData={hdnData}
        />
      </div>
    </div>
  );
};

const getAanvraagNaarOptions = (values: GeldverstrekkersState): LabelValuePairs => {
  const aanvraagNaarOptions: LabelValuePairs = [];
  values.beschikbareGeldverstrekkers.serviceProviders.forEach(serviceprovider => {
    aanvraagNaarOptions.push({
      label: `${serviceprovider.naam}`,
      value: `${AanvraagType.Serviceprovider}**${serviceprovider.serviceproviderId}`
    });
  });
  values.beschikbareGeldverstrekkers.softwareKoppelingen.forEach(softwareKoppeling => {
    aanvraagNaarOptions.push({
      label: `${softwareKoppeling.naam}`,
      value: `${AanvraagType.SoftwareKoppeling}**${softwareKoppeling.softwareKoppelingId}`
    });
  });

  aanvraagNaarOptions.sort((a, b) => (a.label > b.label) ? 1 : -1);  
  return aanvraagNaarOptions;
}

const createAanvraagNaarSelectInput = (
  formik: FormikContextType<GeldverstrekkersState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { setFieldValue, values } = formik;

  const setAanvraagNaar = (event: ChangeEvent<HTMLSelectElement>): void => {
    const val = event.target.value === "null" ? null : event.target.value;
    const [aanvraagType, aanvraagId] =  event.target.value.split("**");
    const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
    setFieldValue(`beschikbareGeldverstrekkers.partijen[${c.index}].aanvraagNaar`, val);

    if (isServiceprovider) {
      setFieldValue(`beschikbareGeldverstrekkers.partijen[${c.index}].serviceproviderId`, aanvraagId);
      setFieldValue(`beschikbareGeldverstrekkers.partijen[${c.index}].softwareKoppelingId`, null);
    } else {
      setFieldValue(`beschikbareGeldverstrekkers.partijen[${c.index}].serviceproviderId`, null);
      setFieldValue(`beschikbareGeldverstrekkers.partijen[${c.index}].softwareKoppelingId`, aanvraagId);
    }
  };

  const aanvraagNaarOptions = getAanvraagNaarOptions(values);

  return (
    <div className="input-group">
      <select
        id={`aanvraag-naar-select-${c.index}`}
        data-testid={`aanvraag-naar-select-${c.index}`}
        onChange={setAanvraagNaar}
        defaultValue={c.original.aanvraagNaar}
        // ../../shared/components/select-input/SelectInput.module.scss: input_control
        className={classnames("input_control", "grid-cell")}
      >
        <option value={"null"}>Geen</option>
        {aanvraagNaarOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const createTextInputAgentNummerCell = (
  formik: FormikContextType<GeldverstrekkersState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { setFieldValue, values } = formik;
  const inputElement: RefObject<HTMLInputElement> | null = useRef(null);

  const onMouseUp = (): void => {
    inputElement.current?.focus();
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`beschikbareGeldverstrekkers.partijen[${c.index}].agentnummer`, e.target.value);
  };

  return (
    <div className="input-group">
      <input
        data-testid={`agentnummer-input-${c.index}`}
        id={`agentnummer-input-${c.index}`}
        // ../../shared/components/tekst-input/TextInput.module.scss: input_control
        className={classnames("input_control", "grid-cell")}
        type="text"
        defaultValue={values.beschikbareGeldverstrekkers.partijen[c.index].agentnummer || ""}
        onBlur={onBlur}
        ref={inputElement}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

export const createActiveCheckboxCell = (c: CellInfo): ReactElement => {
  return (
    <div className={classes.clickable_label_fix}>
      <CheckBox
        name={`beschikbareGeldverstrekkers.partijen[${c.index}].actief`}
        caption=""
        disabled={c.original.code === "XX"}
      />
    </div>
  );
};

export const getColumns = (formik: FormikContextType<GeldverstrekkersState>, hdnData: UseHdnDataResult): Column[] => [
  {
    Header: "Partijnaam",
    Cell: createSpanWithId
  },
  {
    width: 100,
    Header: "Actief",
    Cell: createActiveCheckboxCell
  },
  {
    width: 100,
    Header: "Agentnummer",
    Cell: createTextInputAgentNummerCell(formik)
  },
  {
    Header: "Aanvraag naar",
    Cell: createAanvraagNaarSelectInput(formik)
  },
  {
    width: 175,
    Header: "Aanvullende retourwijze",
    Cell: createHDNKeuzeLijst(hdnData)
  },
  {
    maxWidth: 100,
    Header: "Hypotheekopties",
    Cell: createModalButton(formik)
  }
];
