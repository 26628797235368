import React, { ReactElement } from "react";
import { connect } from "formik";
import { LabeledRadioInput, LabeledDateInput, LabeledNumberInput, LabeledPercentageInput } from "adviesbox-shared";
import { BerekeningenState } from "../infra";
import { ControleNbiOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { getBerekeningenTextResources } from "../infra/berekeningen-resources";

const Scenarios = (): ReactElement => {
  return (
    <div>
      <LabeledDateInput
        caption="Ingangsdatum scenario's"
        name="scenarios.ingangsdatumScenarios"
        readonly={true}
        tooltip={getBerekeningenTextResources("TooltipIngangsdatumScenarios")}
      ></LabeledDateInput>

      <LabeledNumberInput
        caption="Lengte van het overzicht in jaren"
        name="scenarios.lengteOverzichtInJaren"
        appendChildren={<span>Jaar</span>}
      ></LabeledNumberInput>

      <LabeledNumberInput
        caption="Eindleeftijd scenario's"
        name="scenarios.eindleeftijdScenarios"
        appendChildren={<span>Jaar</span>}
      ></LabeledNumberInput>

      <LabeledPercentageInput
        caption="Rekenrente contante waarde"
        name="scenarios.rekenrenteContanteWaardePercentage"
        tooltip={getBerekeningenTextResources("TooltiprekenrenteContanteWaardePercentage")}
        decimalen={2}
      ></LabeledPercentageInput>

      <LabeledRadioInput
        caption="Controle netto besteedbaar inkomen o.b.v"
        name="scenarios.controleNbi"
        tooltip={getBerekeningenTextResources("TooltipcontroleNbi")}
        options={[
          {
            label: "Huidige situatie",
            value: ControleNbiOptions.HuidigeSituatie
          },
          {
            label: "Situatie na advies",
            value: ControleNbiOptions.SituatieNaAdvies
          }
        ]}
      ></LabeledRadioInput>
    </div>
  );
};

export default connect<{}, BerekeningenState>(Scenarios);
