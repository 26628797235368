import {
  Documenten as DocumentenDlEntry,
  DocumentenOutput as DocumentenDlOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  documentenSchema,
  DocumentenState,
  instelingenOverzichtenSchema,
  instelingenVoorbladEnVervolgpaginasSchema,
  kolommenInDeLastenoverzichtenSchema,
  kolommenInHetNettoBesteedbaarInkomenOverzichtSchema,
  ondertekeningSchema
} from "./documenten-schema";

const mapOndertekening = createMapToUi(ondertekeningSchema).from<DocumentenDlEntry>({
  tonenOpFormulier: v => v.ondertekening?.tonenOpFormulieren,
  weergaveAdviseurnaam: v => v.ondertekening?.weergaveAdviseurnaam,
  voorbeeldweergaveAdviseurnaam: v => null,
  extraOndertekeningsregel: v => v.ondertekening?.extraOndertekeningsregel
});

const mapInstelingenOverzichten = createMapToUi(instelingenOverzichtenSchema).from<DocumentenDlEntry>({
  bedrijfslogoTonenOpOverzichten: v => v.overzichten?.bedrijfslogoOverzichtenTonen,
  overzichtTonenVan: v => v.overzichten?.overzichtTonenVan,
  toonGrafiekenBijScenarioOverzichten: v => v.overzichten?.toonGrafiekScenarioOverzicht
});

const mapInstelingenVoorbladEnVervolgpaginas = createMapToUi(instelingenVoorbladEnVervolgpaginasSchema).from<
  DocumentenDlEntry
>({
  bedrijfslogoTonenOpVoorblad: v => v.voorbladVervolgpaginas?.bedrijfslogoVoorbladTonen,
  uitlijningLogoOpVoorblad: v => v.voorbladVervolgpaginas?.uitlijningBedrijfslogoVoorblad,
  bedrijfsgegevensTonenOpVoorblad: v => v.voorbladVervolgpaginas?.bedrijfsgegevensVoorbladTonen,
  uitlijningBedrijfsgegevensOpVoorblad: v => v.voorbladVervolgpaginas?.uitlijningBedrijfsgegevensVoorblad,
  bedrijfslogoOpVervolgpaginaSTonen: v => v.voorbladVervolgpaginas?.bedrijfsgegevensVervolgpaginasTonen,
  uitlijningBedrijfslogoOpVervolgpagina: v => v.voorbladVervolgpaginas?.uitlijningBedrijfslogoVervolgpaginas
});

const mapKolommenInDeLastenoverzichten = createMapToUi(kolommenInDeLastenoverzichtenSchema).from<DocumentenDlEntry>({
  brutoInkomen: v => v.lastenoverzichten?.brutoInkomen,
  somHypotheken: v => v.lastenoverzichten?.somHypotheken,
  waardeopbouw: v => v.lastenoverzichten?.waardeopbouw,
  huur: v => v.lastenoverzichten?.huur,
  rente: v => v.lastenoverzichten?.rente,
  hypotheekAflossing: v => v.lastenoverzichten?.hypotheekAflossing,
  uitkeringKapitaalverzekering: v => v.lastenoverzichten?.uitkeringKapitaalverzekeringen,
  verzekeringspremies: v => v.lastenoverzichten?.verzekeringspremies,
  spaarEnBeleggingsdepot: v => v.lastenoverzichten?.spaarEnBeleggingsdepot,
  opnameUitDepot: v => v.lastenoverzichten?.opnameUitDepot,
  kredietLast: v => v.lastenoverzichten?.kredietlast,
  overigeUitgaven: v => v.lastenoverzichten?.overigeUitgaven,
  brutoLast: v => v.lastenoverzichten?.brutoLast,
  fiscaalVoordeel: v => v.lastenoverzichten?.fiscaalVoordeel,
  vrh: v => v.lastenoverzichten?.vrh,
  nettoLast: v => v.lastenoverzichten?.nettoLast
});
const mapKolommenInHetNettoBesteedbaarInkomenOverzicht = createMapToUi(
  kolommenInHetNettoBesteedbaarInkomenOverzichtSchema
).from<DocumentenDlEntry>({
  hypotheek: v => v.nbiOverzicht?.hypotheek,
  verzekeringen: v => v.nbiOverzicht?.verzekeringen,
  spaarEnBeleggingsdepots: v => v.nbiOverzicht?.spaarEnBeleggingsdepots,
  kredietLast: v => v.nbiOverzicht?.kredietLast,
  overigeUitgeven: v => v.nbiOverzicht?.overigUitgaven,
  totaleUitgaven: v => v.nbiOverzicht?.totaleUitgaven,
  brutoInkomen: v => v.nbiOverzicht?.brutoInkomen,
  inkomstenbelasting: v => v.nbiOverzicht?.inkomstenbelasting,
  vrh: v => v.nbiOverzicht?.vrh,
  nettpOnkomsten: v => v.nbiOverzicht?.nettoInkomsten,
  nettoBesteedbaarInkomen: v => v.nbiOverzicht?.nbi
});

const mapDocumenten = createMapToUi(documentenSchema).from<DocumentenDlEntry>({
  ondertekening: v => mapOndertekening(v),
  instelingenOverzichten: v => mapInstelingenOverzichten(v),
  instelingenVoorbladEnVervolgpaginas: v => mapInstelingenVoorbladEnVervolgpaginas(v),
  afbeeldingVoorblad: v => null, //todo: #66386
  kolommenInDeLastenoverzichten: v => mapKolommenInDeLastenoverzichten(v),
  kolommenInHetNettoBesteedbaarInkomenOverzicht: v => mapKolommenInHetNettoBesteedbaarInkomenOverzicht(v)
});

export function mapDocumentenDlToUi(documentenId: string, data: DocumentenDlOutput): DocumentenState | null {
  const documenten = data && data.isValid && data.documenten ? data.documenten : null;

  if (documenten) {
    return mapDocumenten(documenten);
  }

  return null;
}
