import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import {
  Card,
  CardWrapper,
  PlatformFoutenSamenvatting,
} from "adviesbox-shared";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import classnames from "classnames";
import classes from "./beschikbare-software-koppelingen/beschikbare-software-koppelingen.module.scss";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";
import {
  SoftwareKoppelingenType,
  softwareKoppelingenSchema,
} from "./infra/software-koppelingen-schema";
import BeschikbareSoftwareKoppelingen from "./beschikbare-software-koppelingen/beschikbare-software-koppelingen";

const SoftwareKoppelingen = (
  props: FormikProps<SoftwareKoppelingenType> &
    WithSaveData<SoftwareKoppelingenType>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={
          !(
            isBeheerder ||
            autorisatieNiveau.serviceprovidersNiveau ===
              InstellingNiveauOptions.Medewerker
          )
        }
      >
        <CardWrapper className="px-3">
          <div className={classnames(classes.text_container, "text-container")}>
            <div className={"p-2"}>
              <h2 className="p-0">Software koppelingen&nbsp;</h2>
              <AutorisatieNiveauManager
                manageFor={autorisatieNiveau.softwareKoppelingenNiveau}
              />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Beschikbare software koppelingen" className="w-100">
            <div className="px-1">
              <BeschikbareSoftwareKoppelingen />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

SoftwareKoppelingen.displayName = "SoftwareKoppelingen";

export default withAdviesboxFormik<
  SoftwareKoppelingenType & WithSaveData<SoftwareKoppelingenType>,
  SoftwareKoppelingenType
>({
  // Transform outer props into form values
  mapPropsToValues: (e: SoftwareKoppelingenType): SoftwareKoppelingenType => e,
  validationSchema: softwareKoppelingenSchema,
})(SoftwareKoppelingen);
