import { LabeledRadioInput, LabeledSelectInput, LabeledText, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext } from "react";
import {
  TonenOpFormulierenOptions,
  WeergaveAdviseurnaamOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { Geslacht } from "../../.generated/licenties/licentiestypes";
import { LabelValuePairs } from "../../shared/types";
import UserDetailsContext, { Medewerker } from "../../user-details/user-details-context";
import { DocumentenState } from "../infra/documenten-schema";

type OndertekeningProps = {};

const TonenOpFormulierenOptionsOptions: LabelValuePairs = [
  { label: "Adviseurnaam van bijkantoor", value: TonenOpFormulierenOptions.AdviseurnaamVanBijkantoor },
  {
    label: "Adviseurnaam van hoofdkantoor",
    value: TonenOpFormulierenOptions.AdviseurnaamVanHoofdkantoor
  }
];

const WeergaveAdviseurnaamOptionsOptions: LabelValuePairs = [
  { label: "Voorletters achternaam", value: WeergaveAdviseurnaamOptions.VoorlettersAchternaam },
  { label: "Titel achternaam", value: WeergaveAdviseurnaamOptions.TitelAchternaam },
  {
    label: "Titel voorletters achternaam",
    value: WeergaveAdviseurnaamOptions.TitelVoorlettersAchternaam
  },
  {
    label: "Voorletters voornamen achternaam",
    value: WeergaveAdviseurnaamOptions.VoorlettersVoornamenAchternaam
  },
  { label: "Voornamen achternaam", value: WeergaveAdviseurnaamOptions.VoornamenAchternaam }
];

export const trimSpaces = (txt: string): string => txt.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
export const getNaamVolgensWeergave = (
  weergave: WeergaveAdviseurnaamOptions | null,
  userDetails: Medewerker
): string => {
  const title =
    userDetails.geslacht === Geslacht.Man
      ? "Dhr."
      : userDetails.geslacht === Geslacht.Vrouw
      ? "Mevr."
      : "";
  if (weergave === WeergaveAdviseurnaamOptions.TitelAchternaam) return trimSpaces(`${title} ${userDetails.tussenvoegsels} ${userDetails.achternaam}`);
  if (weergave === WeergaveAdviseurnaamOptions.TitelVoorlettersAchternaam)
    return trimSpaces(`${title} ${userDetails.voorletters} ${userDetails.tussenvoegsels} ${userDetails.achternaam}`);
  if (weergave === WeergaveAdviseurnaamOptions.VoorlettersAchternaam)
    return trimSpaces(`${userDetails.voorletters} ${userDetails.tussenvoegsels} ${userDetails.achternaam}`);
  if (weergave === WeergaveAdviseurnaamOptions.VoorlettersVoornamenAchternaam)
    return trimSpaces(`${userDetails.voorletters} ${userDetails.voornaam} ${userDetails.tussenvoegsels} ${userDetails.achternaam}`);
  if (weergave === WeergaveAdviseurnaamOptions.VoornamenAchternaam)
    return trimSpaces(`${userDetails.voornaam} ${userDetails.tussenvoegsels} ${userDetails.achternaam}`);
  return "";
};

const Ondertekening = ({
  formik: { values }
}: OndertekeningProps & {
  formik: FormikContextType<DocumentenState>;
}): ReactElement => {
  const { userDetails } = useContext(UserDetailsContext);

  return (
    <>
      <LabeledRadioInput
        name="ondertekening.tonenOpFormulier"
        caption="Tonen op formulier"
        options={TonenOpFormulierenOptionsOptions}
      />
      <LabeledSelectInput
        name="ondertekening.weergaveAdviseurnaam"
        caption="Weergave adviseurnaam"
        options={WeergaveAdviseurnaamOptionsOptions}
      />
      <LabeledText
        name="ondertekening.voorbeeldweergaveAdviseurnaam"
        caption="Voorbeeldweergave adviseurnaam"
        value={getNaamVolgensWeergave(values.ondertekening.weergaveAdviseurnaam, userDetails)}
        fieldSize="no-size"
      />
      <LabeledTextInput name="ondertekening.extraOndertekeningsregel" caption="Extra ondertekeningsregel" />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Ondertekening.displayName = "Ondertekening";

export default connect<OndertekeningProps, DocumentenState>(Ondertekening);
