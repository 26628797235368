import { AuthContext, LabeledTextInput, SettingsContext } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext } from "react";
import { useParams } from "react-router";
import Modal from "../../shared/components/modal/Modal";
import { NationaleWaarborgProps, NationaleWaarborgState } from "../infra/nationale-waarborg-types";

export const NationaleWaarborgModal = ({
  closeModal
}: NationaleWaarborgProps & { closeModal?: () => void }): ReactElement => {
  const { values, setFieldValue } = useFormikContext<NationaleWaarborgState>();
  const settings = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const params = useParams<{ vestiging?: string }>();

  return (
    <div className="d-inline-block">
      <Modal
        title={"Inloggen Nationale Waarborg"}
        body={
          <div className={"px-2"}>
            <div>
              <p>
                Wij gebruiken onderstaande gegevens om eenmalig in te loggen bij Nationale Waarborg en een zogeheten
                identificatietoken op te halen. Wij slaan de ingevoerde inloggegevens nooit op.
              </p>
              <LabeledTextInput caption="E-mailadres" name={"emailaddress"} />
              <LabeledTextInput caption="Wachtwoord" name={"password"} type={"password"} />
              <br />
              <br />
              {values.apifoutNwb && values.apifoutNwb === 401 && (
                <p className="input-rood">E-mailadres of wachtwoord is ongeldig</p>
              )}
              {values.apifoutNwb && values.apifoutNwb !== 401 && (
                <p className="input-rood">Er kan geen token opgehaald worden vanuit Nationale Waarborg</p>
              )}
            </div>
          </div>
        }
        onCancelClick={closeModal}
        saveButtonTekst={"Inloggen"}
        onSubmitClick={async () => {
          try {
            /* istanbul ignore next*/
            if (!values.emailaddress || !values.password) return;

            // ophalen token bij NWB
            const responseNwb = await fetch(settings.nationaleWaarborgApiOrigin, {
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=UTF-8"
              },
              body: JSON.stringify({
                uid: values.emailaddress,
                pwd: values.password
              })
            });

            if (responseNwb.ok) {
              const token = (await responseNwb.json()) as string;
              setFieldValue("token", token);
              // opslaan van token in instellingen-forms
              /* istanbul ignore else */
              if (token) {
                const responseOpslaan = await fetch(
                  `${settings.instellingenFormsOrigin}/ExterneKoppelingen/NationaleWaarborg`,
                  {
                    headers: {
                      authorization: `${user?.token_type} ${user?.access_token}`,
                      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
                      "Content-Type": "application/json;charset=UTF-8",
                      vestigingId: params.vestiging || ""
                    },
                    method: "PUT",
                    body: JSON.stringify({ token: token })
                  }
                );

                if (!responseOpslaan.ok) {
                  setFieldValue("apifout", 0);
                } else {
                  setFieldValue("apifout", null);
                  setFieldValue("reedsActief", true);
                  closeModal && closeModal();
                }
              }
            }

            if (!responseNwb.ok) {
              setFieldValue("apifoutNwb", responseNwb.status);
            }
          } catch (error) {
            // fout tonen
            setFieldValue("apifoutNwb", 9999);
          }
        }}
      />
    </div>
  );
};
