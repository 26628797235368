/* istanbul ignore file */

import { Schema } from "yup";

type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type ReadonlyKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, never, P>;
}[keyof T];

type PickWritable<T> = T extends object
  ? Pick<
      {
        [P in keyof T]: SetReadonlyOptional<T[P]>;
      },
      WritableKeys<T>
    >
  : T;

type PickReadonly<T> = T extends object
  ? Pick<
      {
        [P in keyof T]+?: SetReadonlyOptional<T[P]>;
      },
      ReadonlyKeys<T>
    >
  : T;

type SetReadonlyOptional<T> = PickWritable<T> & PickReadonly<T>;

type MapIt<S, D, T> = {
  [P in keyof D]: Exclude<T, undefined> extends never ? (from: S) => D[P] : (from: S, extra: T) => D[P];
};

type Mapper<S, D, T> = Exclude<T, undefined> extends never ? (source: S) => D : (source: S, extra: T) => D;

type CreateMapToDlReturnType<S> = {
  with: <T>(_?: Schema<T>) => { to: <D = never>(map: MapIt<S, SetReadonlyOptional<D>, T>) => Mapper<S, D, T> };
  to: <D = never>(map: MapIt<S, SetReadonlyOptional<D>, undefined>) => Mapper<S, D, undefined>;
};

export const createMapToDl = <S>(_: Schema<S>): CreateMapToDlReturnType<S> => {
  const to = <D = never, T = undefined>(map: MapIt<S, SetReadonlyOptional<D>, T>): Mapper<S, D, T> => {
    const mapper = (source: S, extra?: T): D => {
      const keys = Object.keys(map);
      const result: any = {};
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        result[prop] = (map as any)[prop](source, extra);
      }
      return result;
    };
    return mapper as Mapper<S, D, T>;
  };

  return {
    with: (): { to: typeof to } => ({ to }),
    to: to
  };
};
