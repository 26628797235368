/* istanbul ignore file*/

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { StandaardTekstenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToDStandaardTekstenUiField } from "./infra/map-standaard-teksten-dl-target-to-ui-field";
import { mapStandaardTekstenDlToUi } from "./infra/map-standaard-teksten-dl-to-ui";
import { mapStandaardTekstenUiToDl } from "./infra/map-standaard-teksten-ui-to-dl";
import { StandaardTeksten } from "./standaard-teksten";

export enum StandaardteksenOnderwerpen {
  "algemeen" = "algemeen",
  "energie" = "energie",
  "verantwoordewoonlasten" = "verantwoordewoonlasten",
  "inbrengeigengeld" = "inbrengeigengeld",
  "dubbelewoonlasten" = "dubbelewoonlasten",
  "fiscaleaftrek" = "fiscaleaftrek",
  "hypotheekaflossen" = "hypotheekaflossen",
  "rentevastperiode" = "rentevastperiode",
  "geldverstrekker" = "geldverstrekker",
  "pensioenvoorziening" = "pensioenvoorziening",
  "afdekkenoverlijdensrisico" = "afdekkenoverlijdensrisico",
  "afdekkenaoww" = "afdekkenaoww",
  "afdekkenww" = "afdekkenww",
  "wijzeopbouwdoelvermogen" = "wijzeopbouwdoelvermogen",
  "risicoprofiel" = "risicoprofiel"
}

type RouteParamsWithOnderwerp = RouteParams & { onderwerp: StandaardteksenOnderwerpen };

const StandaardTekstenAjaxComponent = (): ReactElement => {
  const { onderwerp } = useParams<RouteParamsWithOnderwerp>();

  const { settings, user, params, requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/Standaardteksten?onderwerpsoort=${onderwerp}`;

  const { loading, error, data } = useAbortableFetch<StandaardTekstenOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapStandaardTekstenDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <StandaardTeksten
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapStandaardTekstenUiToDl,
        mapDlTargetToDStandaardTekstenUiField
      )}
      onderwerp={(onderwerp as unknown) as StandaardteksenOnderwerpen}
      {...mappedProps}
    />
  );
};

export const StandaardTekstenAjax = withErrorBoundary(StandaardTekstenAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  StandaardTekstenAjax.displayName = "StandaardTekstenAjax";

// function mapOnderwerpToOnderwerpSoort(onderwerp: StandaardteksenOnderwerpen): Onderwerpsoort {
//   switch (onderwerp) {
//     case StandaardteksenOnderwerpen.algemeen:
//       return Onderwerpsoort.Algemeen;
//     case StandaardteksenOnderwerpen.arbeidsongeschiktheid:
//       return Onderwerpsoort.AfdekkenAOWW;
//     case StandaardteksenOnderwerpen["dubbele-woonlasten"]:
//       return Onderwerpsoort.DubbeleWoonlasten;
//     case StandaardteksenOnderwerpen["eigen-geld"]:
//       return Onderwerpsoort.InbrengEigenGeld;
//     case StandaardteksenOnderwerpen.fiscaal:
//       return Onderwerpsoort.FiscaleAftrek;
//     case StandaardteksenOnderwerpen.geldverstrekker:
//       return Onderwerpsoort.Geldverstrekker;
//     case StandaardteksenOnderwerpen.hypotheekvorm:
//       return Onderwerpsoort.HypotheekAflossen;
//     case StandaardteksenOnderwerpen["opbouw-doelvermogen"]:
//       return Onderwerpsoort.WijzeOpbouwDoelvermogen;
//     case StandaardteksenOnderwerpen.overlijden:
//       return Onderwerpsoort.AfdekkenOverlijdensrisico;
//     case StandaardteksenOnderwerpen.pensioen:
//       return Onderwerpsoort.Pensioenvoorziening;
//     case StandaardteksenOnderwerpen.rentevastperiode:
//       return Onderwerpsoort.Rentevastperiode;
//     case StandaardteksenOnderwerpen.risicoprofiel:
//       return Onderwerpsoort.Risicoprofiel;
//     case StandaardteksenOnderwerpen["verantwoorde-woonlasten"]:
//       return Onderwerpsoort.VerantwoordeWoonlasten;
//     case StandaardteksenOnderwerpen.verduurzaming:
//       return Onderwerpsoort.Energie;
//     case StandaardteksenOnderwerpen.werkloosheid:
//       return Onderwerpsoort.AfdekkenWW;
//     default:
//       return Onderwerpsoort.Geen;
//   }
// }
