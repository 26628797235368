import React, { ReactElement } from "react";
import { connect, FormikContextType} from "formik";
import { AutorisatieNiveauState } from "../infra";
import AutorisatieNiveauGrid from "../infra/autorisatie-niveau-grid";

const Instellingen = ({ formik: { values } }: { formik: FormikContextType<AutorisatieNiveauState> }): ReactElement => {
  return <AutorisatieNiveauGrid options={Object.keys(values.instellingen)} formikKey={"instellingen"} />;
};

export default connect<{}, AutorisatieNiveauState>(Instellingen);
