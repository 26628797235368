import React, { ReactElement } from "react";

import { LabeledBevestigingInput } from "adviesbox-shared";

type InstelingenVoorbladEnVervolgpaginasProps = {};
const logoFunctionaliteitTonen = false;

// const uitlijningOptions: LabelValuePairs = [
//   { label: "Links", value: UitlijningOptions.Links },
//   { label: "Rechts", value: UitlijningOptions.Rechts },
//   { label: "Midden", value: UitlijningOptions.Midden }
// ];

// const uitlijningBedrijfsgegevensOpVoorbladOptions: LabelValuePairs = [
//   { label: "Links", value: UitlijningOptions.Links },
//   { label: "Rechts", value: UitlijningOptions.Rechts },
//   { label: "Midden", value: UitlijningOptions.Midden }
// ];

// const uitlijningBedrijfslogoOpVervolgpaginaOptions: LabelValuePairs = [
//   { label: "Links", value: UitlijningOptions.Links },
//   { label: "Rechts", value: UitlijningOptions.Rechts },
//   { label: "Midden", value: UitlijningOptions.Midden }
// ];

export const InstelingenVoorbladEnVervolgpaginas = (_props: InstelingenVoorbladEnVervolgpaginasProps): ReactElement => {
  return (
    <>
      {logoFunctionaliteitTonen && (      
        <LabeledBevestigingInput
          name="instelingenVoorbladEnVervolgpaginas.bedrijfslogoTonenOpVoorblad"
          caption="Bedrijfslogo tonen op voorblad"
        />
      )}
      {/* <LabeledSelectInput
        name="instelingenVoorbladEnVervolgpaginas.uitlijningLogoOpVoorblad"
        caption="Uitlijning logo op voorblad"
        options={uitlijningOptions}
      /> */}
      <LabeledBevestigingInput
        name="instelingenVoorbladEnVervolgpaginas.bedrijfsgegevensTonenOpVoorblad"
        caption="Bedrijfsgegevens tonen op voorblad"
      />
      {/* <LabeledSelectInput
        name="instelingenVoorbladEnVervolgpaginas.uitlijningBedrijfsgegevensOpVoorblad"
        caption="Uitlijning bedrijfsgegevens op voorblad"
        options={uitlijningBedrijfsgegevensOpVoorbladOptions}
      /> */}
      {logoFunctionaliteitTonen && (      
        <LabeledBevestigingInput
          name="instelingenVoorbladEnVervolgpaginas.bedrijfslogoOpVervolgpaginaSTonen"
          caption="Bedrijfslogo op vervolgpagina s tonen"
        />
      )}
      {/* <LabeledSelectInput
        name="instelingenVoorbladEnVervolgpaginas.uitlijningBedrijfslogoOpVervolgpagina"
        caption="Uitlijning bedrijfslogo op vervolgpagina"
        options={uitlijningBedrijfslogoOpVervolgpaginaOptions}
      /> */}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  InstelingenVoorbladEnVervolgpaginas.displayName = "InstelingenVoorbladEnVervolgpaginas";
