import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput, LabeledCurrencyInput, LabeledPercentageInput } from "adviesbox-shared";
import { TarievenState } from "../infra";
import {
  VastBedragBedragPerUurOptions,
  VastBedragOptions,
  VastBedragPercentageOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";

const Financieringsopzet = ({
  formik: {
    setFieldValue,
    values: { financieringsopzet }
  }
}: {
  formik: FormikContextType<TarievenState>;
}): ReactElement => {
  return (
    <div>
      <LabeledRadioInput
        caption="Leveringsakte"
        name="financieringsopzet.leveringsAkte"
        options={[
          {
            label: "Indicatieve waarde",
            value: VastBedragPercentageOptions.IndicatieveWaarde
          },
          {
            label: "Vast bedrag",
            value: VastBedragPercentageOptions.VastBedrag
          }
        ]}
      />
      {financieringsopzet.leveringsAkte === VastBedragOptions.VastBedrag && (
        <LabeledCurrencyInput caption="Bedrag leveringsakte" name="financieringsopzet.bedragLeveringsAkte" />
      )}
      <LabeledRadioInput
        caption="Hypotheekakte"
        name="financieringsopzet.hypotheekAkte"
        options={[
          {
            label: "Indicatieve waarde",
            value: VastBedragOptions.IndicatieveWaarde
          },
          {
            label: "Vast bedrag",
            value: VastBedragOptions.VastBedrag
          }
        ]}
      />
      {financieringsopzet.hypotheekAkte === VastBedragOptions.VastBedrag && (
        <LabeledCurrencyInput caption="Bedrag hypotheekakte" name="financieringsopzet.bedragHypotheekAkte" />
      )}
      <LabeledRadioInput
        caption="Taxatiekosten"
        name="financieringsopzet.taxatieKosten"
        options={[
          {
            label: "Indicatieve waarde",
            value: VastBedragOptions.IndicatieveWaarde
          },
          {
            label: "Vast bedrag",
            value: VastBedragOptions.VastBedrag
          }
        ]}
      />
      {financieringsopzet.taxatieKosten === VastBedragOptions.VastBedrag && (
        <LabeledCurrencyInput caption="Bedrag taxatiekosten" name="financieringsopzet.bedragTaxatieKosten" />
      )}
      <LabeledRadioInput
        caption="Hypothecaire inschrijving"
        name="financieringsopzet.hypothecaireInschrijving"
        options={[
          {
            label: "Gelijk aan gewenste hypotheek",
            value: VastBedragBedragPerUurOptions.IndicatieveWaarde
          },
          {
            label: "Percentage opslag op de hypotheek",
            value: VastBedragPercentageOptions.Percentage
          },
          {
            label: "Vast bedrag boven gewenste hypotheek",
            value: VastBedragBedragPerUurOptions.VastBedrag
          }
        ]}
      />

      {financieringsopzet.hypothecaireInschrijving === VastBedragPercentageOptions.VastBedrag && (
        <LabeledCurrencyInput caption="Bedrag" name="financieringsopzet.bedragHypothecaireInschrijving" />
      )}
      {financieringsopzet.hypothecaireInschrijving === VastBedragPercentageOptions.Percentage && (
        <LabeledPercentageInput
          caption="Percentage"
          name="financieringsopzet.percentageHypothecaireInschrijving"
          decimalen={2}
        />
      )}
    </div>
  );
};

export default connect<{}, TarievenState>(Financieringsopzet);
