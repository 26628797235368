import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { UitgangspuntenState } from "../infra";

const Uitleg = ({ formik }: { formik: FormikContextType<UitgangspuntenState> }): ReactElement => {
  // const { setFieldValue } = useFormikContext<UitgangspuntenState>();

  return (
    <div className={"p-3"}>
      <span>Onderstaande instellingen worden overgenomen voor alle gebruikers.</span>
    </div>
  );
};

export default connect<{}, UitgangspuntenState>(Uitleg);
