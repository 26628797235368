import {
  Bankgarantieinstellingen,
  BankgarantieinstellingPartij
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { FieldMap, UiName } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";
import {
  bankgarantieinstellingenSchema,
  BankgarantieinstellingenState,
  bankgarantieInstellingPartij,
  beschikbareBankgarantieinstellingenSchema
} from "./bankgarantieinstellingen-schema";

const mapBankgarantieinstellingPartij = createMapToUi(bankgarantieInstellingPartij).from<BankgarantieinstellingPartij>({
  actief: v => v.actief,
  agentnummer: v => v.agentnummer,
  code: v => v.code,
  partijnaam: v => v.partijnaam
});

const mapBeschikbareBankgarantieinstellingenSchema = createMapToUi(beschikbareBankgarantieinstellingenSchema).from<
  Bankgarantieinstellingen
>({
  partijen: v =>
    v.partijen?.map(mapBankgarantieinstellingPartij)
});

const mapBankgarantieInstellingen = createMapToUi(bankgarantieinstellingenSchema).from<Bankgarantieinstellingen>({
  beschikbareBankgarantieinstellingen: v => mapBeschikbareBankgarantieinstellingenSchema(v)
});

function dl2ui(values: Bankgarantieinstellingen): BankgarantieinstellingenState {
  const bankgarantieinstellingen = mapBankgarantieInstellingen(values);

  return bankgarantieinstellingenSchema.cast(bankgarantieinstellingen);
}

export function mapBankgarantieinstellingenDlToUi(
  bankgarantieinstellingenId: string,
  data: any
): BankgarantieinstellingenState | null {
  const bankgarantieinstellingen = data ? data[bankgarantieinstellingenId] : null;

  if (!bankgarantieinstellingen) {
    return null;
  }

  return dl2ui(bankgarantieinstellingen);
}

export const mapBankgarantieinstellingenUiToDl = createMapToDl(bankgarantieinstellingenSchema).to<
  Bankgarantieinstellingen
>({
  partijen: v => v.beschikbareBankgarantieinstellingen.partijen
});

export function mapDlTargetToBankgarantieinstellingenUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
