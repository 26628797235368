import { LabeledJaarMaandInput, LabeledNumberInput, LabeledSelectInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import {
  ControleGewenstInkomenOptions,
  DuurOverzichtOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { UitgangspuntenState } from "../infra";
import ControleNormEnGewenstInkomen from "../infra/controle-norm-gewenst-inkomen";
import { syncScenarioMinimaalUitgangspuntSideEffects } from "../infra/determine-sync-inkomensuitgangspunt";

const Overlijden = ({ formik }: { formik: FormikContextType<UitgangspuntenState> }): ReactElement => {
  const overlijden = formik.values.overlijden;

  const UitgangspuntenOverlijdenGewensteDuurOverzichtOptions = [
    {
      label: "Tot leeftijd",
      value: DuurOverzichtOptions.TotLeeftijd
    },
    {
      label: "Vaste duur in jaren",
      value: DuurOverzichtOptions.VasteDuurInJaren
    },
    {
      label: "Levenslang",
      value: DuurOverzichtOptions.Levenslang
    }
  ];

  const tonenConfig =
    overlijden.controleGewenstInkomen.controleOpGewenstInkomen !== ControleGewenstInkomenOptions.Geen &&
    overlijden.controleGewenstInkomen.controleOpGewenstInkomen !== ControleGewenstInkomenOptions.BrutoInkomenswens;

  return (
    <>
      <ISWSideEffects
        sync={initISWSideEffect<UitgangspuntenState>(
          /* istanbul ignore next */ ({ subset }) => {
            syncScenarioMinimaalUitgangspuntSideEffects(subset.overlijden.create());
          }
        )()}
      />

      <ControleNormEnGewenstInkomen formik={formik} namePrefix="overlijden" />

      {overlijden.controleGewenstInkomen.controleOpGewenstInkomen ===
        ControleGewenstInkomenOptions[ControleGewenstInkomenOptions.NettoBesteedbaarInkomen] && (
        <LabeledSelectInput
          caption="Gewenste duur overzicht"
          name="overlijden.duurOverzicht"
          options={UitgangspuntenOverlijdenGewensteDuurOverzichtOptions}
        />
      )}
      {tonenConfig && overlijden.duurOverzicht === DuurOverzichtOptions[DuurOverzichtOptions.TotLeeftijd] && (
        <>
          <LabeledJaarMaandInput caption="Leeftijd" name="overlijden.overzichtTotLeeftijdInMaanden" />
        </>
      )}
      {tonenConfig && overlijden.duurOverzicht === DuurOverzichtOptions[DuurOverzichtOptions.VasteDuurInJaren] && (
        <>
          <LabeledNumberInput
            caption="Aantal jaar"
            name="overlijden.overzichtDuurInJaren"
            appendChildren={<span>Jaar</span>}
          />
        </>
      )}
    </>
  );
};

export default connect<{ namePrefix: string }, UitgangspuntenState>(Overlijden);
