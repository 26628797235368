import { LabeledRadioInput, LabeledTextInput } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { StandaardtekstVoorOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

type StandaardTekstDetailsProps = { selected: number };

export const StandaardTekstDetails = (props: StandaardTekstDetailsProps): ReactElement => {
  const { selected } = props;
  return (
    <>
      <LabeledRadioInput
        caption={"Standaardtekst voor"}
        name={`teksten[${selected}].tekstVoor`}
        options={[
          { label: "Ons advies", value: StandaardtekstVoorOptions.OnsAdvies },
          { label: "Afwijkende keuze", value: StandaardtekstVoorOptions.AfwijkendeKeuze }
        ]}
      />

      <LabeledTextInput caption="Korte omschrijving" name={`teksten[${selected}].korteOmschrijving`} />

      <LabeledTextInput caption="Standaardtekst" name={`teksten[${selected}].standaardTekst`} textarea={3} />
    </>
  );
};
