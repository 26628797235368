import {
  VerzekeraarPartij,
  ServiceproviderReadOnly,
  SoftwareKoppelingReadOnly,
  Verzekeraars,
  VerzekeraarsOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  beschikbareVerzekeraarsPartijen,
  beschikbareVerzekeraarsServiceProviders,
  beschikbareVerzekeraarsSoftwareKoppelingen,
  VerzekeraarsState
} from ".";
import { UiName } from "adviesbox-shared/utils/types";
import { target2field } from "../../shared/utils/target-to-field";
import { FieldMap } from "../../shared/types";

const getAanvraagNaar = (verzekeraarPartij: VerzekeraarPartij): string | null => {
  if (verzekeraarPartij.serviceproviderId) return `Serviceprovider**${verzekeraarPartij.serviceproviderId}`;
  else if (verzekeraarPartij.softwareKoppelingId) return `SoftwareKoppeling**${verzekeraarPartij.softwareKoppelingId}`;
  return null;
};

const mapPartijen = createMapToUi(beschikbareVerzekeraarsPartijen).from<VerzekeraarPartij>({
  actief: v => v.actief,
  agentnummer: v => v.agentnummer,
  code: v => v.code,
  partijnaam: v => v.partijnaam,
  serviceproviderId: v => v.serviceproviderId,
  softwareKoppelingId: v => v.softwareKoppelingId,
  aanvraagNaar: v => getAanvraagNaar(v)
});

const mapServiceProvider = createMapToUi(beschikbareVerzekeraarsServiceProviders).from<ServiceproviderReadOnly>({
  naam: v => v.naam,
  serviceproviderId: v => v.serviceproviderId,
  hdnNummer: v => v.hdnNummer
});

const mapSoftwareKoppeling = createMapToUi(beschikbareVerzekeraarsSoftwareKoppelingen).from<SoftwareKoppelingReadOnly>({
  naam: v => v.naam,
  softwareKoppelingId: v => v.softwareKoppelingId
});

function dl2ui(values: Verzekeraars): VerzekeraarsState {
  const verzekeraars = {
    beschikbareVerzekeraars: {
      partijen: values.partijen?.map(c => mapPartijen(c)) || [],
      serviceProviders:
        values.serviceproviders
          ?.map(c => mapServiceProvider(c))
          .sort((a: any, b: any): number => {
            return new Intl.Collator(["NL"]).compare(a.naam || "", b.naam || "");
          }) || [],
      softwareKoppelingen:
        values.softwareKoppelingen
          ?.map(c => mapSoftwareKoppeling(c))
          .sort((a: any, b: any): number => {
            return new Intl.Collator(["NL"]).compare(a.naam || "", b.naam || "");
          }) || []
    }
  };
  return verzekeraars;
}

export function mapverzekeraarsDlToUi(data: VerzekeraarsOutput): VerzekeraarsState | null {
  const verzekeraars = data && data.verzekeraars ? data.verzekeraars : /* istanbul ignore next*/ null;

  if (!verzekeraars) {
    return null;
  }
  return dl2ui(verzekeraars);
}

export function mapDlTargetToverzekeraarsUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
