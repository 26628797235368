import { Card, CardWrapper, ModalButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import withAdviesboxFormik from "../../shared/utils/with-adviesbox-formik";
import {
  NationaleWaarborgProps,
  NationaleWaarborgSchema,
  NationaleWaarborgState
} from "../infra/nationale-waarborg-types";
import { NationaleWaarborgModal } from "./nationalewaarborg-modal";

const NationaleWaarborg = (props: NationaleWaarborgProps): ReactElement => {
  const { values } = useFormikContext<NationaleWaarborgState>();
  return (
    <CardWrapper className="px-2">
      <Card title="Koppeling Nationale Waarborg">
        <div className="d-flex justify-content-center">
          <p className="text-center">
            {values.reedsActief && <>Uw koppeling is reeds geactiveerd</>}
            {!values.reedsActief && <>Koppeling niet actief, klik op onderstaande knop om de koppeling te activeren</>}
            <br />
            <br />
            <ModalButton
              parent={`dummy`}
              key={"nwb-modal"}
              content={<div>Koppeling {values.reedsActief ? "opnieuw activeren" : "activeren"}</div>}
              className={"btn-primary"}
            >
              <NationaleWaarborgModal {...NationaleWaarborgSchema.default()} />
            </ModalButton>
          </p>
        </div>
      </Card>
    </CardWrapper>
  );
};

export default withAdviesboxFormik<NationaleWaarborgProps, NationaleWaarborgState>({
  // Transform outer props into form values
  mapPropsToValues: (e: NationaleWaarborgProps): any => e,
  validationSchema: NationaleWaarborgSchema
})(NationaleWaarborg);
