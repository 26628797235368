/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { NotarissenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToNotarissenUiField, mapNotarissenDlToUi } from "./infra/map-notarissen-dl-to-ui";
import { mapNotarissenUiToDl } from "./infra/map-notarissen-ui-to-dl";
import Notarissen from "./notarissen";

const NotarissenAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData

  const { settings, params, requestInit, user } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Notarissen`;
  const { loading, error, data } = useAbortableFetch<NotarissenOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapNotarissenDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Notarissen
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapNotarissenUiToDl,
        mapDlTargetToNotarissenUiField
      )}
      {...mappedProps}
    />
  );
};

NotarissenAjax.displayName = "NotarissenAjax";

export default withErrorBoundary(NotarissenAjax);
