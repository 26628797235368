import React, { ReactElement, useContext } from "react";
import { FormikProps, Form } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { getBerekeningenTextResources } from "./infra/berekeningen-resources";

import { berekeningenSchema, BerekeningenProps, BerekeningenState } from "./infra";
import Algemeen from "./algemeen/algemeen";
import Heffingskortingen from "./heffingskortingen/heffingskortingen";
import Scenarios from "./scenarios/scenarios";
import { SaveButton } from "../save-button/save-button";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Berekeningen = (props: FormikProps<BerekeningenState> & WithSaveData<BerekeningenState>): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />
      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.berekeningenNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className={"p-2"}>
              <h2 className="p-0">Berekeningen&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.berekeningenNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Algemeen">
            <Algemeen />
          </Card>
          <Card title="Scenario's">
            <Scenarios />
          </Card>
          <Card title="Heffingskortingen" tooltip={getBerekeningenTextResources("TooltipHeffingskortingen")}>
            <Heffingskortingen />
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Berekeningen.displayName = "berekeningen";

export default withAdviesboxFormik<BerekeningenProps & WithSaveData<BerekeningenState>, BerekeningenState>({
  // Transform outer props into form values
  mapPropsToValues: (e: BerekeningenProps): BerekeningenState => e,
  validationSchema: berekeningenSchema
})(Berekeningen);
