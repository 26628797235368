import { PostcodeInfo, PostcodeInfoOutput } from "../../../.generated/postcodes/postcodestypes";

import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";

async function getPostcodeInfo(
  user: User | null,
  settings: SettingsType,
  postcode: string,
  huisnummer: number
): Promise<PostcodeInfo | null> {
  if (!user) {
    return null;
  }

  const url = `${settings.postcodeOrigin}/Postcodes/${postcode}/Huisnummers/${huisnummer}`;
  const rsp = await fetch(url, {
    headers: {
      "Ocp-Apim-Subscription-Key": `${settings.OcpApimSubscriptionKey}`
    }
  });

  if (rsp.ok) {
    const data: PostcodeInfoOutput = await rsp.json();

    if (data.isValid && data.postcodeInfo) {
      return data.postcodeInfo;
    }
  }

  return null;
}

export default getPostcodeInfo;
