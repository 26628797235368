/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { VerzekeraarsOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToverzekeraarsUiField, mapverzekeraarsDlToUi, mapverzekeraarsUiToDl } from "./infra";
import Verzekeraars from "./verzekeraars";

const VerzekeraarsAjax = (): ReactElement => {
  const { settings, requestInit, user, params } = useRequestInit<{ vestiging: string }>();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Verzekeraars`;
  const { loading, error, data } = useAbortableFetch<VerzekeraarsOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapverzekeraarsDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Verzekeraars
      saveData={saveData(
        user,
        params.vestiging,
        url,
        settings.OcpApimSubscriptionKey,
        mapverzekeraarsUiToDl,
        mapDlTargetToverzekeraarsUiField
      )}
      {...mappedProps}
    />
  );
};

VerzekeraarsAjax.displayName = "verzekeraarsAjax";

export default withErrorBoundary(VerzekeraarsAjax);
