import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

import {
  adviesonderwerpenSchema,
  onderwerpenSchema,
  menuonderdelenSchema,
  AdviesonderwerpenState
} from "./adviesonderwerpen-schema";
import {
  Adviesonderwerpen,
  AdviesonderwerpenOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapMenuOnderdelen = createMapToUi(menuonderdelenSchema).from<Adviesonderwerpen>({
  kapitaalVerzekering: v => v.onderdeelKapitaalverzekeringTonen,
  ORV: v => v.onderdeelOrvTonen,
  AOV: v => v.onderdeelAovTonen,
  vermogen: v => v.onderdeelVermogenTonen,
  krediet: v => v.onderdeelKredietTonen,
  uitkerendeLijfrente: v => v.onderdeelUitkerendeLijfrenteTonen,
  overig: v => v.onderdeelOverigTonen,
  hypotheek: _ => true
});

const mapOnderwerpen = createMapToUi(onderwerpenSchema).from<Adviesonderwerpen>({
  hypotheek: v => v.onderwerpHypotheekTonen,
  overlijden: v => v.onderwerpOverlijdenTonen,
  arbeidsongeschiktheid: v => v.onderwerpArbeidsongeschiktheidTonen,
  werkloosheid: v => v.onderwerpWerkloosheidTonen,
  pensioen: v => v.onderwerpPensioenTonen,
  vermogensopbouw: v => v.onderwerpVermogensopbouwTonen
});

const mapAdviesOnderwerpen = createMapToUi(adviesonderwerpenSchema).from<Adviesonderwerpen>({
  menuonderdelen: v => mapMenuOnderdelen(v),
  onderwerpen: v => mapOnderwerpen(v)
});

function dl2ui(values: Adviesonderwerpen): AdviesonderwerpenState {
  const adviesonderwerpen = mapAdviesOnderwerpen(values);

  return adviesonderwerpenSchema.cast(adviesonderwerpen);
}

export function mapAdviesonderwerpenDlToUi(data: AdviesonderwerpenOutput): AdviesonderwerpenState | null {
  const adviesonderwerpen = data && data.adviesonderwerpen ? data.adviesonderwerpen : null;

  if (!adviesonderwerpen) {
    return null;
  }

  return dl2ui(adviesonderwerpen);
}

export const mapAdviesonderwerpenUiToDl = createMapToDl(adviesonderwerpenSchema).to<Adviesonderwerpen>({
  onderwerpHypotheekTonen: v => v.onderwerpen.hypotheek,
  onderwerpOverlijdenTonen: v => v.onderwerpen.overlijden,
  onderwerpArbeidsongeschiktheidTonen: v => v.onderwerpen.arbeidsongeschiktheid,
  onderwerpWerkloosheidTonen: v => v.onderwerpen.werkloosheid,
  onderwerpPensioenTonen: v => v.onderwerpen.pensioen,
  onderwerpVermogensopbouwTonen: v => v.onderwerpen.vermogensopbouw,
  onderdeelKapitaalverzekeringTonen: v => v.menuonderdelen.kapitaalVerzekering,
  onderdeelOrvTonen: v => v.menuonderdelen.ORV,
  onderdeelAovTonen: v => v.menuonderdelen.AOV,
  onderdeelVermogenTonen: v => v.menuonderdelen.vermogen,
  onderdeelKredietTonen: v => v.menuonderdelen.krediet,
  onderdeelUitkerendeLijfrenteTonen: v => v.menuonderdelen.uitkerendeLijfrente,
  onderdeelOverigTonen: v => v.menuonderdelen.overig
});

export function mapDlTargetToAdviesonderwerpenUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
