/*istanbul ignore file*/
import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { Dossier as DossierDlEntry } from "../../.generated/bemiddeling/bemiddelingtypes";

export function mapDlTargetToKoppelingUiField(target: string): UiName | null {
  const map: FieldMap<DossierDlEntry> = {};

  return target2field(map, target);
}
