/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { ServiceprovidersOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToServiceprovidersUiField, mapServiceprovidersDlToUi, mapServiceprovidersUiToDl } from "./infra";
import Serviceproviders from "./serviceproviders";

const ServiceprovidersAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData
  const { requestInit, settings, user, params } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Serviceproviders`;
  const { loading, error, data } = useAbortableFetch<ServiceprovidersOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapServiceprovidersDlToUi("serviceproviders", data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Serviceproviders
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapServiceprovidersUiToDl,
        mapDlTargetToServiceprovidersUiField
      )}
      {...mappedProps}
    />
  );
};

ServiceprovidersAjax.displayName = "ServiceprovidersAjax";

export default withErrorBoundary(ServiceprovidersAjax);
