import { DataGrid } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { VerzekeraarsState } from "../infra";
import { EmptyComponent, getColumns } from "./verzekeraars-data-grid-components";

const BeschikbareVerzekeraars = ({ formik }: { formik: FormikContextType<VerzekeraarsState> }): ReactElement => {
  const { values } = formik;

  return (
    <DataGrid
      name="beschikbareVerzekeraars.partijen"
      editable={true}
      sortable={false}
      filterable={false}
      resizable={false}
      columns={getColumns(formik)}
      minRows={values.beschikbareVerzekeraars.partijen.length || 0}
      pageSize={values.beschikbareVerzekeraars.partijen.length || 0}
      showPagination={false}
      NoDataComponent={EmptyComponent}
    />
  );
};

export default connect<{}, VerzekeraarsState>(BeschikbareVerzekeraars);
