import { FormikContextType, setNestedObjectValues } from "formik";
import { UiError } from "../types";

/* istanbul ignore next */
export const formikIsValidAsync = async function<T>(
  formikContext: FormikContextType<T & { platformApiFouten?: UiError[] | null }>
): Promise<boolean> {
  const indicesRegex = /(?<=\[)([^.])(?=\])/gm;
  const regexCleanup = /\[([^.])\]/gm;
  const errors = await formikContext.validateForm(formikContext.values);
  const uiErrors: any = errors;

  formikContext.values?.platformApiFouten?.forEach((mappedError: UiError) => {
    const baseKey = mappedError.field?.split(".").shift() ?? "";
    const baseKeyIndex = baseKey.match(indicesRegex)?.pop() ?? "";
    const baseKeyClean = baseKey.replace(regexCleanup, "");
    let remainingPath = mappedError.field;

    if (!remainingPath) {
      return;
    }

    while (remainingPath) {
      remainingPath = checkAndDeletePropertyPath(uiErrors, remainingPath);
    }

    if (baseKeyIndex && Object.keys(uiErrors[baseKeyClean][baseKeyIndex]).length === 0) {
      delete uiErrors[baseKeyClean][baseKeyIndex];
    }

    if (Object.keys(uiErrors[baseKeyClean]).length === 0) {
      delete uiErrors[baseKeyClean];
    }
  });

  if (Object.keys(uiErrors).length === 0) {
    return true;
  }

  formikContext.setTouched(setNestedObjectValues(uiErrors, true));
  formikContext.setErrors(uiErrors);
  return false;
};

/* istanbul ignore next */
function checkAndDeletePropertyPath(baseObj: Record<string | number, any>, path: string): string {
  const indicesRegex = /(?<=\[)([^.])(?=\])/gm;
  const regexCleanup = /\[([^.])\]/gm;
  const pathArray = path.split(".");
  let obj = { ...baseObj };

  if (!obj || pathArray.length < 1) {
    return "";
  }

  for (let i = 0; i < pathArray.length - 1; i++) {
    let path = pathArray[i];
    const indexedPath = path.match(indicesRegex)?.pop() ?? "";

    if (indexedPath) {
      path = path.replace(regexCleanup, "");
      obj = obj[path][indexedPath];
    }

    if (!indexedPath) {
      obj = obj[path];
    }

    if (typeof obj === "undefined") {
      return pathArray[i];
    }
  }

  const deleteKey = pathArray.pop() ?? "";
  delete obj[deleteKey];

  if (Object.keys(obj).length) {
    return "";
  }

  return pathArray.join(".");
}
