import React, { ReactElement } from "react";
import { connect } from "formik";
import { CheckBox } from "adviesbox-shared";
import { BerekeningenState } from "../infra";

const Heffingskortingen = (): ReactElement => {
  return (
    <div className="p-3">
      <p>Hieronder zijn de heffingskortingen voor de fiscale berekeningen in te stellen.</p>
      <>
        <CheckBox
          caption="Algemene heffingskortingen"
          name="heffingskortingen.algemeneHeffingskorting"
          disabled={true}
        ></CheckBox>
        <CheckBox caption="Arbeidskorting" name="heffingskortingen.arbeidskorting"></CheckBox>
        <CheckBox
          caption="Inkomensafhankelijke combinatiekorting"
          name="heffingskortingen.inkomensafhankelijkeCombinatiekorting"
        ></CheckBox>
        <CheckBox caption="Ouderenkorting" name="heffingskortingen.ouderenkorting"></CheckBox>
        <CheckBox
          caption="Alleenstaande ouderenkorting"
          name="heffingskortingen.alleenstaandeOuderenkorting"
        ></CheckBox>
        <CheckBox caption="Werkbonus" name="heffingskortingen.werkbonus"></CheckBox>
        <CheckBox caption="Kindgebonden budget" name="heffingskortingen.kindgebondenBudget"></CheckBox>
      </>
    </div>
  );
};

export default connect<{}, BerekeningenState>(Heffingskortingen);
