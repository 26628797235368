/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { UitgangspuntenAdviesOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToUitgangspuntenUiField, mapUitgangspuntenDlToUi, mapUitgangspuntenUiToDl } from "./infra";
import Uitgangspunten from "./uitgangspunten";

const UitgangspuntenAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData
  const { user, requestInit, settings, params } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/UitgangspuntenAdvies`;

  const { loading, error, data } = useAbortableFetch<UitgangspuntenAdviesOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapUitgangspuntenDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Uitgangspunten
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapUitgangspuntenUiToDl,
        mapDlTargetToUitgangspuntenUiField
      )}
      {...mappedProps}
    />
  );
};

UitgangspuntenAjax.displayName = "UitgangspuntenAjax";

export default withErrorBoundary(UitgangspuntenAjax);
