import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import {
  tarievenSchema,
  TarievenState,
  advieskostenMetHypotheekSchema,
  advieskostenZonderHypotheekSchema,
  financieringsopzetSchema
} from "./tarieven-schema";
import {
  TarievenOutput,
  Tarieven,
  VastBedragPercentageOptions,
  VastBedragBedragPerUurOptions,
  VastBedragOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

function mapKostenDl2Ui(kostenVorm: string | null, vast: number | null, perUur: number | null): number | null {
  return kostenVorm === "VastBedrag" ? vast : kostenVorm === "BedragPerUur" ? perUur : null;
}

const mapFinancieringsopzetDl2Ui = createMapToUi(financieringsopzetSchema).from<Tarieven>({
  leveringsAkte: v => v.leveringsakteVaststellingWaarde,
  bedragLeveringsAkte: v => v.leveringsakteVastBedrag,
  hypotheekAkte: v => v.hypotheekakteVaststellingWaarde,
  bedragHypotheekAkte: v => v.hypotheekakteVastBedrag,
  taxatieKosten: v => v.taxatiekostenVaststellingWaarde,
  bedragTaxatieKosten: v => v.taxatiekostenVastBedrag,
  hypothecaireInschrijving: v => v.hypothecaireInschrijvingVaststellingWaarde,
  bedragHypothecaireInschrijving: v => v.hypothecaireInschrijvingVastBedrag,
  percentageHypothecaireInschrijving: v => v.hypothecaireInschrijvingPercentage
});

const mapAdvieskostenMetHypotheekDl2Ui = createMapToUi(advieskostenMetHypotheekSchema).from<Tarieven>({
  adviesKosten: v => v.hypotheekAdvieskostenVaststellingWaarde,
  bedragAdviesKosten: v =>
    mapKostenDl2Ui(
      v.hypotheekAdvieskostenVaststellingWaarde,
      v.hypotheekAdvieskostenVastBedrag,
      v.hypotheekAdvieskostenBedragPerUur
    ),
  bemiddelingsVergoeding: v => v.bemiddelingsvergoedingGeldleningVaststellingWaarde,
  bedragBemiddelingsVergoeding: v =>
    mapKostenDl2Ui(
      v.bemiddelingsvergoedingGeldleningVaststellingWaarde,
      v.bemiddelingsvergoedingGeldleningVastBedrag,
      v.bemiddelingsvergoedingGeldleningBedragPerUur
    ),
  overigeBemiddelingsVergoeding: v => v.overigeBemiddelingsvergoedingVaststellingWaarde,
  bedragOverigeBemiddelingsVergoeding: v =>
    mapKostenDl2Ui(
      v.overigeBemiddelingsvergoedingVaststellingWaarde,
      v.overigeBemiddelingsvergoedingVastBedrag,
      v.overigeBemiddelingsvergoedingBedragPerUur
    ),
  dossierVergoeding: v => v.dossiervergoedingVastBedrag
});

const mapAdvieskostenZondeHypotheekDl2Ui = createMapToUi(advieskostenZonderHypotheekSchema).from<Tarieven>({
  adviesKosten: v => v.advieskostenVaststellingWaarde,
  bedragAdviesKosten: v =>
    mapKostenDl2Ui(v.advieskostenVaststellingWaarde, v.advieskostenVastBedrag, v.advieskostenBedragPerUur),
  bemiddelingsVergoeding: v => v.bemiddelingsvergoedingVaststellingWaarde,
  bedragBemiddelingsVergoeding: v =>
    mapKostenDl2Ui(
      v.bemiddelingsvergoedingVaststellingWaarde,
      v.bemiddelingsvergoedingVastBedrag,
      v.bemiddelingsvergoedingBedragPerUur
    )
});

const dl2ui = createMapToUi(tarievenSchema).from<Tarieven>({
  financieringsopzet: v => mapFinancieringsopzetDl2Ui(v),
  advieskostenMetHypotheek: v => mapAdvieskostenMetHypotheekDl2Ui(v),
  advieskostenZonderHypotheek: v => mapAdvieskostenZondeHypotheekDl2Ui(v)
});

export function mapTarievenDlToUi(data: TarievenOutput): TarievenState | null {
  const tarieven = data && data.tarieven ? data.tarieven : null;

  if (!tarieven) {
    return null;
  }

  return dl2ui(tarieven);
}

const mapVastBedrag = (kostenVorm: string | null, bedrag: number | null): number | null => {
  return kostenVorm === "VastBedrag" ? bedrag : null;
};

export const mapTarievenUiToDl = createMapToDl(tarievenSchema).to<Tarieven>({
  leveringsakteVaststellingWaarde: v => v.financieringsopzet.leveringsAkte as VastBedragOptions,
  leveringsakteVastBedrag: v =>
    mapVastBedrag(v.financieringsopzet.leveringsAkte, v.financieringsopzet.bedragLeveringsAkte),
  hypotheekakteVaststellingWaarde: v => v.financieringsopzet.hypotheekAkte as VastBedragOptions,
  hypotheekakteVastBedrag: v =>
    mapVastBedrag(v.financieringsopzet.hypotheekAkte, v.financieringsopzet.bedragHypotheekAkte),
  taxatiekostenVaststellingWaarde: v => v.financieringsopzet.taxatieKosten as VastBedragOptions,
  taxatiekostenVastBedrag: v =>
    mapVastBedrag(v.financieringsopzet.taxatieKosten, v.financieringsopzet.bedragTaxatieKosten),
  hypothecaireInschrijvingVaststellingWaarde: v =>
    v.financieringsopzet.hypothecaireInschrijving as VastBedragPercentageOptions,
  hypothecaireInschrijvingVastBedrag: v =>
    mapVastBedrag(v.financieringsopzet.hypothecaireInschrijving, v.financieringsopzet.bedragHypothecaireInschrijving),
  hypothecaireInschrijvingPercentage: v =>
    v.financieringsopzet.hypothecaireInschrijving === VastBedragPercentageOptions.Percentage
      ? v.financieringsopzet.percentageHypothecaireInschrijving
      : null,
  hypotheekAdvieskostenVaststellingWaarde: v => v.advieskostenMetHypotheek.adviesKosten,
  hypotheekAdvieskostenVastBedrag: v =>
    mapVastBedrag(v.advieskostenMetHypotheek.adviesKosten, v.advieskostenMetHypotheek.bedragAdviesKosten),
  hypotheekAdvieskostenBedragPerUur: v =>
    v.advieskostenMetHypotheek.adviesKosten === VastBedragBedragPerUurOptions.BedragPerUur
      ? v.advieskostenMetHypotheek.bedragAdviesKosten
      : null,
  bemiddelingsvergoedingGeldleningVaststellingWaarde: v => v.advieskostenMetHypotheek.bemiddelingsVergoeding,
  bemiddelingsvergoedingGeldleningVastBedrag: v =>
    mapVastBedrag(
      v.advieskostenMetHypotheek.bemiddelingsVergoeding,
      v.advieskostenMetHypotheek.bedragBemiddelingsVergoeding
    ),
  bemiddelingsvergoedingGeldleningBedragPerUur: v =>
    v.advieskostenMetHypotheek.bemiddelingsVergoeding === VastBedragBedragPerUurOptions.BedragPerUur
      ? v.advieskostenMetHypotheek.bedragBemiddelingsVergoeding
      : null,
  overigeBemiddelingsvergoedingVaststellingWaarde: v => v.advieskostenMetHypotheek.overigeBemiddelingsVergoeding,
  overigeBemiddelingsvergoedingVastBedrag: v =>
    mapVastBedrag(
      v.advieskostenMetHypotheek.overigeBemiddelingsVergoeding,
      v.advieskostenMetHypotheek.bedragOverigeBemiddelingsVergoeding
    ),
  overigeBemiddelingsvergoedingBedragPerUur: v =>
    v.advieskostenMetHypotheek.overigeBemiddelingsVergoeding === VastBedragBedragPerUurOptions.BedragPerUur
      ? v.advieskostenMetHypotheek.bedragOverigeBemiddelingsVergoeding
      : null,
  dossiervergoedingVastBedrag: v => v.advieskostenMetHypotheek.dossierVergoeding,
  advieskostenVaststellingWaarde: v => v.advieskostenZonderHypotheek.adviesKosten,
  advieskostenVastBedrag: v =>
    mapVastBedrag(v.advieskostenZonderHypotheek.adviesKosten, v.advieskostenZonderHypotheek.bedragAdviesKosten),
  advieskostenBedragPerUur: v =>
    v.advieskostenZonderHypotheek.adviesKosten === VastBedragBedragPerUurOptions.BedragPerUur
      ? v.advieskostenZonderHypotheek.bedragAdviesKosten
      : null,
  bemiddelingsvergoedingVaststellingWaarde: v => v.advieskostenZonderHypotheek.bemiddelingsVergoeding,
  bemiddelingsvergoedingVastBedrag: v =>
    mapVastBedrag(
      v.advieskostenZonderHypotheek.bemiddelingsVergoeding,
      v.advieskostenZonderHypotheek.bedragBemiddelingsVergoeding
    ),
  bemiddelingsvergoedingBedragPerUur: v =>
    v.advieskostenZonderHypotheek.bemiddelingsVergoeding === VastBedragBedragPerUurOptions.BedragPerUur
      ? v.advieskostenZonderHypotheek.bedragBemiddelingsVergoeding
      : null
});

export function mapDlTargetToTarievenUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
