import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { KolommenInstellenModalState } from "../shared/components/rapportage/infra/rapportage-samenstellen-types";
import { kolommenInstellenModalSchema } from "../shared/components/rapportage/rapportage-samenstellen-schema";
import Modal from "../shared/components/modal/Modal";
import { AdviesBoxFormik, CheckBox } from "adviesbox-shared";

export type KolommenInstellenModalProps = {
  saveModal: (data: KolommenInstellenModalState) => void;
  closeModal?: () => void;
  values: KolommenInstellenModalState;
  rapportNaam: string;
};
const KolommenInstellenModal = ({
  closeModal,
  saveModal,
  values,
  rapportNaam
}: KolommenInstellenModalProps): ReactElement => {
  const body = (): ReactElement => {
    return (
      <div className={"p-3"}>
        <p>
          In dit scherm kunt u aangeven welke kolommen in de lastenoverzichten en in het overzicht netto ‘besteedbaar
          inkomen’ moeten worden opgenomen. Het aantal kolommen dat op de pagina past is afhankelijk van de afdrukstand.
          Kolommen met alleen nulwaarden worden niet in de tabellen opgenomen.
        </p>
        <p>
          Let op! Bij de ‘staande’ afdrukstand passen maximaal 9 kolommen op de pagina. In ‘liggende’ afdrukstand passen
          maximaal 14 kolommen op de pagina.
        </p>
        <h2 className={"px-0"}>Kolommen in de lastenoverzichten</h2>
        <CheckBox name={"kolommenLasten.brutoInkomen"} caption={"Bruto inkomen"}></CheckBox>
        <CheckBox name={"kolommenLasten.somHypotheken"} caption={"Som hypotheken"}></CheckBox>
        <CheckBox name={"kolommenLasten.waardeopbouw"} caption={"Waardeopbouw"}></CheckBox>
        <CheckBox name={"kolommenLasten.huur"} caption={"Huur"}></CheckBox>
        <CheckBox name={"kolommenLasten.rente"} caption={"Rente"}></CheckBox>
        <CheckBox name={"kolommenLasten.hypotheekAflossing"} caption={"Hypotheek aflossing"}></CheckBox>
        <CheckBox
          name={"kolommenLasten.uitkeringKapitaalverzekering"}
          caption={"Uitkering kapitaalverzekering"}
        ></CheckBox>
        <CheckBox name={"kolommenLasten.verzekeringspremies"} caption={"Verzekeringspremies"}></CheckBox>
        <CheckBox name={"kolommenLasten.spaarBeleggingsdepots"} caption={"Spaar- en beleggingsdepots"}></CheckBox>
        <CheckBox name={"kolommenLasten.opnameUitDepot"} caption={"Opname uit depot"}></CheckBox>
        <CheckBox name={"kolommenLasten.kredietLast"} caption={"Krediet last"}></CheckBox>
        <CheckBox name={"kolommenLasten.overigeUitgaven"} caption={"Overige uitgaven"}></CheckBox>
        <CheckBox name={"kolommenLasten.brutoLast"} caption={"Bruto last"}></CheckBox>
        <CheckBox name={"kolommenLasten.fiscaalVoordeel"} caption={"Fiscaal voordeel"}></CheckBox>
        <CheckBox name={"kolommenLasten.vrh"} caption={"VRH"}></CheckBox>
        <CheckBox name={"kolommenLasten.nettoLast"} caption={"Netto last"}></CheckBox>
        <h2 className={"px-0"}>Kolommen in het netto besteedbaar inkomen overzicht</h2>
        <CheckBox name={"kolommenNbi.hypotheek"} caption={"Hypotheek"}></CheckBox>
        <CheckBox name={"kolommenNbi.verzekeringen"} caption={"Verzekeringen"}></CheckBox>
        <CheckBox name={"kolommenNbi.spaarBeleggingsdepots"} caption={"Spaar- en beleggingsdepots"}></CheckBox>
        <CheckBox name={"kolommenNbi.kredietLast"} caption={"Krediet last"}></CheckBox>
        <CheckBox name={"kolommenNbi.overigeUitgaven"} caption={"Overige uitgaven"}></CheckBox>
        <CheckBox name={"kolommenNbi.brutoLast"} caption={"Bruto last"}></CheckBox>
        <CheckBox name={"kolommenNbi.inkomstenbelasting"} caption={"Inkomstenbelasting"}></CheckBox>
        <CheckBox name={"kolommenNbi.vrh"} caption={"VRH"}></CheckBox>
        <CheckBox name={"kolommenNbi.nettoInkomen"} caption={"Netto inkomen"}></CheckBox>
        <CheckBox name={"kolommenNbi.nbi"} caption={"Netto besteedbaar inkomen"}></CheckBox>
      </div>
    );
  };

  return (
    <AdviesBoxFormik<KolommenInstellenModalState>
      initialValues={values}
      validationSchema={kolommenInstellenModalSchema}
      onSave={saveModal}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<KolommenInstellenModalState>): ReactElement => {
        return (
          <>
            <Modal
              title={`Kolomselectie overzichten in ${rapportNaam}`}
              body={body()}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        );
      }}
    />
  );
};

KolommenInstellenModal.displayName = "KolommenInstellenModal";

export default KolommenInstellenModal;
