import { ErrorBoundaryContext, firstInputFocus } from "adviesbox-shared";
import classnames from "classnames";
import { FormikConsumer, getIn } from "formik";
import React, { ReactElement, ReactNode, useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import classes from "./ModalButton.module.scss";

type ModalButtonProps = {
  content: ReactNode;
  children: ReactElement<any, any>;
  size?: "sm" | "lg";
  parent: string;
  disabled?: boolean;
  render?: boolean;
  validate?: boolean;
  modalIndex?: number;
  className?: string;
};

const ModalButton = ({
  content,
  parent,
  children,
  size,
  disabled,
  render = true,
  modalIndex,
  className = "btn-outline-secondary",
  validate = true,
  ...props
}: ModalButtonProps): ReactElement => {
  const [isModalOpen, setModalOpen] = useState(false);
  const appHasError = useContext(ErrorBoundaryContext).error ? true : false;
  const dialog = useRef<HTMLDivElement | null>(null);
  const onMobile = /Android|Tablet|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return render ? (
    <FormikConsumer>
      {({ errors, values }): ReactElement => {
        const hasError = !!getIn(errors, parent);

        if (process.env.NODE_ENV === "development") {
          const hasNoValue = !getIn(values, parent);
          if (hasNoValue) {
            /* eslint-disable-next-line no-console */
            console.warn(
              `De <ModalButton/> met parent: '${parent}' wijst de parent prop niet naar de data in de values!`
            );
          }
        }

        return (
          <>
            <button
              {...props}
              disabled={appHasError || disabled}
              id={parent + "-popup"}
              data-testid={parent + "-popup"}
              type="button"
              onClick={(evt): void => (!evt.currentTarget.matches(":disabled") ? setModalOpen(true) : undefined)}
              className={classnames(`btn ${className}`, { "is-invalid": hasError && validate })}
            >
              {content}
            </button>

            <Modal
              backdropClassName={classes[`backdrop-${modalIndex}`]}
              className={classes[`dialog-${modalIndex}`]}
              onEnter={(): void | undefined => (onMobile ? undefined : firstInputFocus(dialog))}
              keyboard={false}
              show={isModalOpen}
              backdrop={"static"}
              onHide={(): void => setModalOpen(false)}
              size={size}
              data-testid="modal-form"
            >
              <div ref={dialog} />
              {React.cloneElement(children, {
                closeModal: (): void => setModalOpen(false)
              })}
            </Modal>
          </>
        );
      }}
    </FormikConsumer>
  ) : (
    <></>
  );
};

ModalButton.displayName = "ModalButton";

export default ModalButton;
