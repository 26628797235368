import React, { ChangeEvent, ReactElement, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useRequestInit, withErrorBoundary } from "adviesbox-shared";

import VestigingenContext from "../vestigingen-context/vestigingen-context";

const VestigingenSelect = ({ history }: RouteComponentProps): ReactElement | null => {
  const { vestigingen } = useContext(VestigingenContext);
  const { params } = useRequestInit();

  const onChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    if (params.vestiging) {
      history.push(window.location.pathname.replace(params.vestiging, event.target.value));
    }
  };

  return (
    <div className={"d-inline-block"}>
      {vestigingen && (
        <div className={"d-inline-block"}>
          <h2 className={"p-0"}>voor&nbsp;</h2>
          <select
            id="vestiging-select"
            data-testid="vestiging-select"
            value={params.vestiging}
            onChange={onChange}
            // ../shared/components/select-input/SelectInput.module.scss: input_control
            className={`input_control d-inline`}
          >
            {vestigingen.map((v, index) => (
              <option value={v.vestigingId} key={`${v.naam}-${index}`}>
                {v.naam}
              </option>
            ))}
          </select>
          &nbsp;
        </div>
      )}
    </div>
  );
};

export default withRouter(withErrorBoundary(VestigingenSelect));
