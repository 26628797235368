import { FieldMap, UiName } from "../../shared/types";
import {
  klantprofielMotiveringSchema,
  klantprofielMotiveringState,
  volledigheidSchema
} from "./klantprofiel-motivering-schema";
import { target2field } from "../../shared/utils/target-to-field";
import {
  KlantprofielMotivering,
  KlantprofielMotiveringOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapVolledigheidDl2Ui = createMapToUi(volledigheidSchema).from<KlantprofielMotivering>({
  soortControle: v => v.soortControleVolledigheid
});

const dl2ui = createMapToUi(klantprofielMotiveringSchema).from<KlantprofielMotivering>({
  volledigheid: v => mapVolledigheidDl2Ui(v)
});

export function mapklantprofielmotiveringDlToUi(
  data: KlantprofielMotiveringOutput
): klantprofielMotiveringState | null {
  const klantprofielMotivering = data && data.isValid ? data.klantprofielMotivering : null;

  if (klantprofielMotivering) {
    return dl2ui(klantprofielMotivering);
  }

  return null;
}

export const mapklantprofielmotiveringUiToDl = createMapToDl(klantprofielMotiveringSchema).to<KlantprofielMotivering>({
  soortControleVolledigheid: v => v.volledigheid.soortControle
});

export function mapDlTargetToklantprofielmotiveringUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
