import React, { ReactElement } from "react";
import { DataGrid, AdviesBoxColumn, SortType, SelectInput } from "adviesbox-shared";
import { koppelingenSchema, MeldingType, KoppelSchemaType, KoppelingState, CoupledAdviseurSchemaType } from "../infra";
import { CellInfo } from "react-table-6";
import { useFormikContext } from "formik";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { AdviseurModel } from "../../.generated/bemiddeling/bemiddelingtypes";
/*istanbul ignore file */
export type KoppelingGridProps = {
  selected: number;
  type: MeldingType;
};

const KoppelingDataGrid = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<KoppelingState>();

  const uncouple = (adviseurId: string | null, columnId: string): { onMouseDown: () => void } => ({
    onMouseDown: (): void => {
      if (columnId !== "delete") {
        return;
      }

      if (adviseurId && typeof adviseurId === "string") {
        const array = values.deleteEvent ? [...values.deleteEvent, adviseurId] : [adviseurId];
        setFieldValue(`deleteEvent`, array);
      }
    }
  });

  const SelectBemiddelaarComponentCell = (c: CellInfo): ReactElement => {
    const bemiddelaarOptions = values.bemiddelaars.map(bemiddelaar => {
      return { label: bemiddelaar.naam, value: bemiddelaar.bemiddelaarId };
    });
    return (
      <>
        <SelectInput
          key={`bemiddelaarSelect-${c.index}`}
          readonly={bemiddelaarOptions.length <= 1}
          name={`koppelingen[${c.index}].bemiddelaar.bemiddelaarId`}
          className="grid-cell"
          options={bemiddelaarOptions}
        />
      </>
    );
  };

  const SelectAdviseurComponentCell = (c: CellInfo): ReactElement => {
    const adviseursOptions: LabelValuePairs = values.adviseurs
      .filter(adviseur => {
        return (
          adviseur.adviseurId === values.koppelingen[c.index].adviseur.adviseurId ||
          adviseur.coupledBemiddelaarId === "" ||
          !adviseur.coupledBemiddelaarId
        );
      })
      .map(filteredAdviseur => {
        return { label: filteredAdviseur.naam, value: filteredAdviseur.adviseurId };
      });

    return (
      <>
        <SelectInput
          key={`adviseurSelect-${c.index}`}
          readonly={adviseursOptions.length <= 1}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const prevAdviseurId = values.koppelingen[c.index].adviseur.adviseurId;
            const index = values.adviseurs.findIndex(adviseur => adviseur.adviseurId === e.target.value);
            if (index !== -1) {
              setFieldValue(`koppelingen[${c.index}].adviseur.adviseurId`, e.target.value);
            }

            const usedAdivseurIds = values.koppelingen.map(koppeling => koppeling.adviseur.adviseurId);
            const update: AdviseurModel[] = values.adviseurs.map(adviseur => {
              if (adviseur.adviseurId === e.target.value) {
                return {
                  ...adviseur,
                  coupledBemiddelaarId: values.koppelingen[c.index].bemiddelaar.bemiddelaarId
                };
              }
              if (
                usedAdivseurIds.findIndex(usedId => usedId === adviseur.adviseurId) === -1 ||
                (adviseur.adviseurId === prevAdviseurId && prevAdviseurId !== e.target.value)
              ) {
                return {
                  ...adviseur,
                  coupledBemiddelaarId: ""
                };
              }

              return adviseur;
            });
            setFieldValue(`adviseurs`, update);
          }}
          name={`koppelingen[${c.index}].adviseur.adviseurId`}
          className="grid-cell"
          options={adviseursOptions ?? { label: "Geen adviseurs", value: "" }}
        />
      </>
    );
  };

  const columns: AdviesBoxColumn[] = [
    {
      id: "adviseur",
      Header: "Adviseur",
      accessor: "adviseur",
      Cell: SelectAdviseurComponentCell,
      maxWidth: 500
    },
    {
      id: "bemiddelaar",
      Header: "Bemiddelaar",
      accessor: "bemiddelaar",
      Cell: SelectBemiddelaarComponentCell,
      maxWidth: 500
    },
    {
      id: "delete",
      Cell: "DeleteButton"
    }
  ];

  return (
    <DataGrid
      columns={columns}
      name="koppelingen"
      sortable={true}
      sortConfig={{
        datum: SortType.Ascending
      }}
      validationSchema={koppelingenSchema}
      showButtonAddRow={
        values.adviseurs.filter(
          advisuer => advisuer.coupledBemiddelaarId === null || advisuer.coupledBemiddelaarId === ""
        ).length > 0
      }
      rowCaption="Koppeling"
      getNewRowValues={(): KoppelSchemaType => {
        const advisuerIds: string[] = [];
        const availableAdviseurs: CoupledAdviseurSchemaType[] = [];

        values.koppelingen.forEach(koppeling => {
          advisuerIds.push(koppeling.adviseur.adviseurId);
        });

        values.adviseurs.forEach(advisuer => {
          if (advisuerIds.includes(advisuer.adviseurId)) {
            return;
          }
          availableAdviseurs.push(advisuer);
        });

        if (availableAdviseurs[0]?.adviseurId) {
          const adviseur = values.adviseurs.find(
            (adviseur: CoupledAdviseurSchemaType) => adviseur.adviseurId === availableAdviseurs[0].adviseurId
          );
          if (adviseur) {
            const adviseurIndex = values.adviseurs.indexOf(adviseur ?? {});
            const update = {
              ...values.adviseurs[adviseurIndex],
              coupledBemiddelaarId: values.bemiddelaars[0].bemiddelaarId
            };

            if (adviseurIndex > -1) {
              setFieldValue(`adviseurs[${adviseurIndex}]`, update);
            }
          }
        }

        const koppelObject = {
          adviseur: {
            adviseurId: availableAdviseurs[0]?.adviseurId ?? "",
            naam: availableAdviseurs[0]?.naam ?? "",
            emailadres: availableAdviseurs[0]?.emailadres
          },
          bemiddelaar: {
            bemiddelaarId: values.bemiddelaars[0].bemiddelaarId,
            naam: values.bemiddelaars[0].naam,
            emailadres: values.bemiddelaars[0]?.emailadres
          }
        };

        return koppelObject;
      }}
      getTdProps={(_state: any, row: any, column: any): { onMouseDown: () => void } | undefined => {
        return uncouple(row.original?.adviseur?.adviseurId, column.id);
      }}
    />
  );
};

KoppelingDataGrid.displayName = "KoppelingDataGrid";

export default KoppelingDataGrid;
