import React, { ReactElement } from "react";
import { LabeledTextInput, FormFirstFocus, AdviesBoxFormik } from "adviesbox-shared";
import { softwareKoppelingSchema, SoftwareKoppelingType } from "../infra";
import { FormikProps } from "formik";
import Modal from "../../shared/components/modal/Modal";

export const HdnInstellingenModal = ({
  data,
  onSave,
  closeModal
}: {
  data: SoftwareKoppelingType | any;
  onSave?: (data: SoftwareKoppelingType) => void;
  closeModal?: () => void
}): ReactElement => { 
  const body = (
    <>
      <FormFirstFocus>
        <LabeledTextInput caption="HDN nummer ontvanger" name="hdnNummer" />
      </FormFirstFocus>
    </>
  );

  return (
    <>
      <AdviesBoxFormik<SoftwareKoppelingType>
        initialValues={{...data}}
        validationSchema={softwareKoppelingSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ submitForm, dirty }: FormikProps<SoftwareKoppelingType>): ReactElement => (
          <>
            <Modal
              onCancelClick={closeModal}
              title="Instellingen wijzigen"
              body={body}
              onSubmitClick={submitForm}
              submitDisabled={!dirty}
            />
          </>
        )}
      />
    </>
  );
};