import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import produce from "immer";

import { EmptyComponent, getColumns } from "./notarissen-data-grid-components";
import { NotariskantoorToevoegenModal } from "./notariskantoor-toevoegen-modal";
import ModalButton from "../../shared/components/modal/ModalButton";
import { DataGrid } from "adviesbox-shared";
import { partijSchema, BeschikbareNotarissenState } from "../infra/notarissen-schema";

const BeschikbareNotarissen = ({ formik }: { formik: FormikContextType<BeschikbareNotarissenState> }): ReactElement => {
  const { values } = formik;

  return (
    <>
      <DataGrid
        name="partijen"
        resizable={false}
        sortable={false}
        filterable={false}
        NoDataComponent={EmptyComponent}
        minRows={values.partijen.length || 0}
        pageSize={values.partijen.length || 0}
        columns={getColumns(formik)}
        showPagination={false}
      />

      <div className={"button-container"}>
        <ModalButton
          className="btn btn-primary"
          parent="beschikbareNotarissen-nieuw"
          content={
            <div>
              <span id="notariskantoor-toevoeg-button">Notariskantoor toevoegen</span>
            </div>
          }
        >
          <NotariskantoorToevoegenModal
            onSave={p =>
              formik.setValues(
                produce(formik.values, v => {
                  v.partijen.push(p);
                })
              )
            }
            data={partijSchema.default()}
          />
        </ModalButton>
      </div>
    </>
  );
};

export default connect<{}, BeschikbareNotarissenState>(BeschikbareNotarissen);
