import React, { ReactElement, ReactNode, useState } from "react";
import AutorisatieNiveauContext, { AutorisatieNiveauContextType } from "./autorisatie-niveau-context";
import { InstellingNiveauOptions, Autorisatieniveau } from "../.generated/instellingen-forms/instellingen-formstypes";

const setContext = (
  autorisatieNiveau: AutorisatieNiveauContextType["autorisatieNiveau"],
  autorisatieNiveauSetter: (vestiging: AutorisatieNiveauContextType["autorisatieNiveau"]) => void,
  isBeheerder: boolean,
  setIsBeheerder: (isBeheerder: boolean) => void
): AutorisatieNiveauContextType => ({
  autorisatieNiveau,
  setAutorisatieNiveau: autorisatieNiveauSetter,
  isBeheerder,
  setIsBeheerder
});

const AutorisatieNiveauProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [isBeheerder, setIsBeheerder] = useState(false);
  const [autorisatieNiveau, setAutorisatieNiveau] = useState({
    algemeenNiveau: InstellingNiveauOptions.Medewerker,
    documentNiveau: InstellingNiveauOptions.Medewerker,
    tarievenNiveau: InstellingNiveauOptions.Medewerker,
    berekeningenNiveau: InstellingNiveauOptions.Medewerker,
    lastenoverzichtenNiveau: InstellingNiveauOptions.Medewerker,
    geldverstrekkersNiveau: InstellingNiveauOptions.Medewerker,
    verzekeraarsNiveau: InstellingNiveauOptions.Medewerker,
    vermogensbeheerdersNiveau: InstellingNiveauOptions.Medewerker,
    bankgarantieInstellingenNiveau: InstellingNiveauOptions.Medewerker,
    taxatiebureausNiveau: InstellingNiveauOptions.Medewerker,
    notarissenNiveau: InstellingNiveauOptions.Medewerker,
    serviceprovidersNiveau: InstellingNiveauOptions.Medewerker,
    klantprofielMotiveringNiveau: InstellingNiveauOptions.Medewerker,
    adviesonderwerpenNiveau: InstellingNiveauOptions.Medewerker,
    uitgangspuntenAdviesNiveau: InstellingNiveauOptions.Medewerker,
    standaardTekstenNiveau: InstellingNiveauOptions.Medewerker,
    generiekeKoppelingenNiveau: InstellingNiveauOptions.Medewerker,
    hdnNiveau: InstellingNiveauOptions.Medewerker,
    elementsNiveau: InstellingNiveauOptions.Medewerker,
    nationaleHypotheekbondNiveau: InstellingNiveauOptions.Medewerker
  } as Autorisatieniveau);

  return (
    <AutorisatieNiveauContext.Provider
      value={setContext(autorisatieNiveau, setAutorisatieNiveau, isBeheerder, setIsBeheerder)}
    >
      {children}
    </AutorisatieNiveauContext.Provider>
  );
};

export default AutorisatieNiveauProvider;
