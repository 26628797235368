import { useContext } from "react";
import { getEnvironment } from "../../utils/environment-helper";
import { getAanvragerNaam } from "../../utils/menu-info";
import { AppDataContext } from "../../../navigation/appdata-context";

// use altTitle when a page has no NavigationList (side menu) loaded (ie. zoekscherm::Advies).
type TitleProps = { appName: string; altTitle?: string };
export const Title = ({ appName, altTitle }: TitleProps): null => {
  const { menuInfo, activePage } = useContext(AppDataContext);

  const hostnamePrefix = getEnvironment(window.location.hostname)
    ? `${getEnvironment(window.location.hostname)}\xa0`
    : "";
  const dossierTitel = menuInfo && menuInfo.aanvrager1 ? `${getAanvragerNaam(menuInfo.aanvrager1)}\xa0` : "";
  const activePageTitle = activePage ? `${activePage}\xa0` : "";
  const altPageTitel = `${altTitle ? `${altTitle}\xa0` : ""}`;

  window.document.title =
    hostnamePrefix +
    (dossierTitel ? dossierTitel : activePageTitle ? activePageTitle : altPageTitel) +
    `|\xa0${appName}`;

  return null;
};
