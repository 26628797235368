import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useContext } from "react";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";
import { SaveButton } from "../save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import BeschikbareVerzekeraars from "./beschikbare-verzekeraars/beschikbare-verzekeraars";
import { VerzekeraarsProps, verzekeraarsSchema, VerzekeraarsState } from "./infra";

const Verzekeraars = (props: FormikProps<VerzekeraarsState> & WithSaveData<VerzekeraarsState>): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />
      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.verzekeraarsNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className={"p-2"}>
              <h2 className={"p-0"}>Verzekeraars&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.verzekeraarsNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Beschikbare verzekeraars" className="w-100">
            <div className="px-1">
              <BeschikbareVerzekeraars />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Verzekeraars.displayName = "verzekeraars";

export default withAdviesboxFormik<VerzekeraarsProps & WithSaveData<VerzekeraarsState>, VerzekeraarsState>({
  // Transform outer props into form values
  mapPropsToValues: (e: VerzekeraarsProps): VerzekeraarsState => e,
  validationSchema: verzekeraarsSchema
})(Verzekeraars);
