import React, { ReactElement } from "react";
import { connect } from "formik";
import { TarievenState } from "../infra";

const Uitleg = (): ReactElement => {
  return (
    <div className={"p-3"}>
      <span>
        Hier kunnen standaard waarden ingesteld worden die in een nieuw voorstel worden opgenomen. In het voorstel
        kunnen deze waarden nog aangepast worden.
      </span>
    </div>
  );
};

export default connect<{}, TarievenState>(Uitleg);
