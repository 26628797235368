import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

import {
  berekeningenSchema,
  algemeenSchema,
  heffingskortingenSchema,
  scenariosSchema,
  BerekeningenState
} from "./berekeningen-schema";
import {
  Berekeningen,
  BerekeningenOutput,
  ControleNbiOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { convertUtcToTimeZone } from "adviesbox-shared";

const mapAlgemeen = createMapToUi(algemeenSchema).from<Berekeningen>({
  huurstijgingPercentage: v => v.algemeen.huurstijgingPercentage,
  indexatieWaardePandPercentage: v => v.algemeen.indexatieWaardePandPercentage,
  minderingOverwaardeGewensteHypotheek: v => v.algemeen.minderingOverwaardeGewensteHypotheek
});

const mapHeffingskortingen = createMapToUi(heffingskortingenSchema).from<Berekeningen>({
  algemeneHeffingskorting: v => v.heffingskortingen.algemeneHeffingskorting,
  arbeidskorting: v => v.heffingskortingen.arbeidskorting,
  inkomensafhankelijkeCombinatiekorting: v => v.heffingskortingen.inkomensafhankelijkeCombinatiekorting,
  ouderenkorting: v => v.heffingskortingen.ouderenkorting,
  alleenstaandeOuderenkorting: v => v.heffingskortingen.alleenstaandeOuderenkorting,
  werkbonus: v => v.heffingskortingen.werkbonus,
  kindgebondenBudget: v => v.heffingskortingen.kindgebondenBudget
});

const mapScenarios = createMapToUi(scenariosSchema).from<Berekeningen>({
  ingangsdatumScenarios: v =>
    v.scenarios.ingangsdatumScenarios ? convertUtcToTimeZone(v.scenarios.ingangsdatumScenarios)?.toLocalDate() : null,
  lengteOverzichtInJaren: v => v.scenarios.lengteOverzichtInJaren,
  eindleeftijdScenarios: v => v.scenarios.eindleeftijdScenarios,
  rekenrenteContanteWaardePercentage: v => v.scenarios.rekenrenteContanteWaardePercentage,
  controleNbi: v => v.scenarios.controleNbi || ControleNbiOptions.HuidigeSituatie
});

function dl2ui(values: Berekeningen): BerekeningenState {
  const berekeningen = {
    algemeen: mapAlgemeen(values),
    heffingskortingen: mapHeffingskortingen(values),
    scenarios: mapScenarios(values)
  };

  return berekeningenSchema.cast(berekeningen);
}

export function mapberekeningenDlToUi(data: BerekeningenOutput): BerekeningenState | null {
  const berekeningen = data && data?.berekeningen ? data.berekeningen : null;

  if (!berekeningen) {
    return null;
  }

  return dl2ui(berekeningen);
}

export const mapberekeningenUiToDl = createMapToDl(berekeningenSchema).to<Berekeningen>({
  algemeen: v => ({
    huurstijgingPercentage: v.algemeen.huurstijgingPercentage,
    indexatieWaardePandPercentage: v.algemeen.indexatieWaardePandPercentage,
    minderingOverwaardeGewensteHypotheek: v.algemeen.minderingOverwaardeGewensteHypotheek
  }),
  heffingskortingen: v => ({
    algemeneHeffingskorting: v.heffingskortingen.algemeneHeffingskorting,
    arbeidskorting: v.heffingskortingen.arbeidskorting,
    inkomensafhankelijkeCombinatiekorting: v.heffingskortingen.inkomensafhankelijkeCombinatiekorting,
    ouderenkorting: v.heffingskortingen.ouderenkorting,
    alleenstaandeOuderenkorting: v.heffingskortingen.alleenstaandeOuderenkorting,
    werkbonus: v.heffingskortingen.werkbonus,
    kindgebondenBudget: v.heffingskortingen.kindgebondenBudget
  }),
  scenarios: v => ({
    ingangsdatumScenarios: "",
    eindleeftijdScenarios: v.scenarios.eindleeftijdScenarios,
    controleNbi: v.scenarios.controleNbi,
    lengteOverzichtInJaren: v.scenarios.lengteOverzichtInJaren,
    rekenrenteContanteWaardePercentage: v.scenarios.rekenrenteContanteWaardePercentage
  })
});

export function mapDlTargetToberekeningenUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
