import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { BankgarantieinstellingenState, BankgarantieinstellingenProps, bankgarantieinstellingenSchema } from "./infra";
import BeschikbareBankgarantieinstellingen from "./beschikbare-bankgarantieinstellingen/beschikbare-bankgarantieinstellingen";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import classnames from "classnames";
import classes from "./beschikbare-bankgarantieinstellingen/beschikbare-bankgarantieinstellingen.module.scss";
import { SaveButton } from "../save-button/save-button";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Bankgarantieinstellingen = (
  props: FormikProps<BankgarantieinstellingenState> & WithSaveData<BankgarantieinstellingenState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />
      <fieldset
        disabled={
          !(isBeheerder || autorisatieNiveau.bankgarantieInstellingenNiveau === InstellingNiveauOptions.Medewerker)
        }
      >
        <CardWrapper className="px-3">
          <div className={classnames(classes.text_container, "text-container")}>
            <div className={"p-2"}>
              <h2>Bankgarantieinstellingen&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.bankgarantieInstellingenNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Beschikbare bankgarantieinstellingen" className="w-100">
            <div className="px-1">
              <BeschikbareBankgarantieinstellingen />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Bankgarantieinstellingen.displayName = "Bankgarantieinstellingen";

export default withAdviesboxFormik<
  BankgarantieinstellingenProps & WithSaveData<BankgarantieinstellingenState>,
  BankgarantieinstellingenState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: BankgarantieinstellingenProps): BankgarantieinstellingenState => e,
  validationSchema: bankgarantieinstellingenSchema
})(Bankgarantieinstellingen);
