import * as Yup from "yup";
import { InferType } from "yup";

import { SoortControleVolledigheidOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { yupEnum } from "../../shared/utils/yup-enum";

export const volledigheidSchema = Yup.object({
  soortControle: yupEnum(SoortControleVolledigheidOptions).default(SoortControleVolledigheidOptions.GeenControle)
});

export const klantprofielMotiveringSchema = Yup.object({
  volledigheid: volledigheidSchema
});

export type VolledigheidType = InferType<typeof volledigheidSchema>;
export type klantprofielMotiveringState = InferType<typeof klantprofielMotiveringSchema>;
export type klantprofielMotiveringProps = InferType<typeof klantprofielMotiveringSchema>;
