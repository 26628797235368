import * as Yup from "yup";
import { InferType } from "yup";

export const beschikbareVerzekeraarsPartijen = Yup.object({
  serviceproviderId: Yup.string().nullable(),
  softwareKoppelingId: Yup.string().nullable(),
  aanvraagNaar: Yup.string()
    .default("")
    .nullable(),
  code: Yup.string(),
  partijnaam: Yup.string(),
  actief: Yup.boolean(),
  agentnummer: Yup.string().nullable()
});

export const beschikbareVerzekeraarsServiceProviders = Yup.object({
  serviceproviderId: Yup.string(),
  naam: Yup.string().nullable(),
  hdnNummer: Yup.string().nullable()
});

export const beschikbareVerzekeraarsSoftwareKoppelingen = Yup.object({
  softwareKoppelingId: Yup.string().default(""),
  naam: Yup.string().nullable()
});

export const beschikbareVerzekeraarsSchema = Yup.object({
  partijen: Yup.array(beschikbareVerzekeraarsPartijen).default([]),
  serviceProviders: Yup.array(beschikbareVerzekeraarsServiceProviders).default([]),
  softwareKoppelingen: Yup.array(beschikbareVerzekeraarsSoftwareKoppelingen).default([])
});

export const verzekeraarsSchema = Yup.object({
  beschikbareVerzekeraars: beschikbareVerzekeraarsSchema
});

export type VerzekeraarsState = InferType<typeof verzekeraarsSchema>;
export type VerzekeraarsProps = InferType<typeof verzekeraarsSchema>;

export type BeschikbareVerzekeraarsType = InferType<typeof beschikbareVerzekeraarsSchema>;
