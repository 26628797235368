import { FieldMap, UiName } from "../../shared/types";

import {
  uitgangspuntenSchema,
  UitgangspuntenState,
  werkloosheidSchema,
  pensioenSchema,
  overlijdenSchema,
  arbeidsongeschiktheidSchema,
  ControleHypotheeknormType,
  ControleGewenstInkomenType,
  arbeidsongeschiktheidScenarioSchema,
  controleHypotheeknormSchema,
  controleGewenstInkomenSchema
} from "./uitgangspunten-schema";
import {
  ControleMaandlastOptions,
  ControleGewenstNbiOptions,
  UitgangspuntenAdvies,
  UitgangspuntenAdviesOutput,
  UitgangspuntenAdviesControleHypotheeknorm,
  UitgangspuntenAdviesControleGewenstInkomen,
  UitgangspuntenAdviesOverlijden,
  UitgangspuntenAdviesWerkloosheid,
  UitgangspuntenAdviesPensioen,
  UitgangspuntenAdviesArbeidongeschiktheid,
  DuurOverzichtOptions,
  UitgangspuntenAdviesAoScenario
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { jaarMaandInMaanden, mapPeriodeInMaandenDl2Ui } from "adviesbox-shared";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapControleHypotheekNorm = (val: UitgangspuntenAdviesControleHypotheeknorm): ControleHypotheeknormType => ({
  controleMaandlast: !!val.controleMaandlast,
  controleMaandlastEnumWaarde: val.controleMaandlast ?? ControleMaandlastOptions.BenodigdInkomen,
  controleerMaximaleHypotheek: val.controleerMaximaleHypotheek
});

const mapControleGewenstInkomen = (val: UitgangspuntenAdviesControleGewenstInkomen): ControleGewenstInkomenType => ({
  controleOpGewenstInkomen: val.controleOpGewenstInkomen,
  controleGewenstNbi: val.controleGewenstNbi ?? ControleGewenstNbiOptions.BehoudenHuidigNbi,
  margeOndergrensPercentage: val.margeOndergrensPercentage,
  margeBovengrensPercentage: val.margeBovengrensPercentage,
  vastBedrag: val.vastBedrag,
  huidigNbiPercentage: val.huidigNbiPercentage,
  gewijzigdeJarenTonen: val.gewijzigdeJarenTonen,
  benodigdKapitaalTonen: val.benodigdKapitaalTonen
});

const mapWerkloosheid = createMapToUi(werkloosheidSchema).from<UitgangspuntenAdvies>({
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.werkloosheid.controleHypotheeknorm),
  controleGewenstInkomen: v => mapControleGewenstInkomen(v.werkloosheid.controleGewenstInkomen)
});
const mapPensioen = createMapToUi(pensioenSchema).from<UitgangspuntenAdvies>({
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.pensioen.controleHypotheeknorm),
  controleGewenstInkomen: v => mapControleGewenstInkomen(v.pensioen.controleGewenstInkomen)
});
const mapOverlijden = createMapToUi(overlijdenSchema).from<UitgangspuntenAdvies>({
  duurOverzicht: v => v.overlijden.duurOverzicht,
  overzichtDuurInJaren: v => v.overlijden.overzichtDuurInJaren,
  overzichtTotLeeftijdInMaanden: v => mapPeriodeInMaandenDl2Ui(v.overlijden.overzichtTotLeeftijdInMaanden ?? 67 * 12),
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.overlijden.controleHypotheeknorm),
  controleGewenstInkomen: v => mapControleGewenstInkomen(v.overlijden.controleGewenstInkomen)
});
const mapArbeidsongeschikt = createMapToUi(arbeidsongeschiktheidSchema).from<UitgangspuntenAdvies>({
  toetsjaarTekort: v => v.arbeidongeschiktheid.toetsjaarTekort,
  scenarios: v => v.arbeidongeschiktheid.scenarios,
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.arbeidongeschiktheid.controleHypotheeknorm),
  controleGewenstInkomen: v => mapControleGewenstInkomen(v.arbeidongeschiktheid.controleGewenstInkomen)
});

function dl2ui(values: UitgangspuntenAdvies): UitgangspuntenState {
  const uitgangspuntenAdvies = {
    werkloosheid: mapWerkloosheid(values),
    pensioen: mapPensioen(values),
    overlijden: mapOverlijden(values),
    arbeidsongeschiktheid: mapArbeidsongeschikt(values)
  };

  return uitgangspuntenSchema.cast(uitgangspuntenAdvies);
}

export function mapDlTargetToUitgangspuntenUiField(target: string): UiName | null {
  const map: FieldMap<UitgangspuntenAdvies> = {};

  return target2field(map, target);
}

export function mapUitgangspuntenDlToUi(data: UitgangspuntenAdviesOutput): UitgangspuntenState | null {
  const uitgangspunten = data?.uitgangspuntenAdvies ?? null;

  if (!uitgangspunten) {
    return null;
  }

  return dl2ui(uitgangspunten);
}

const mapUitgangspuntenControleHypotheeknorm = createMapToDl(controleHypotheeknormSchema).to<
  UitgangspuntenAdviesControleHypotheeknorm
>({
  controleMaandlast: v => v.controleMaandlastEnumWaarde,
  controleerMaximaleHypotheek: v => v.controleerMaximaleHypotheek
});

const mapUitgangspuntenControleGewenstInkomen = createMapToDl(controleGewenstInkomenSchema).to<
  UitgangspuntenAdviesControleGewenstInkomen
>({
  controleOpGewenstInkomen: v => v.controleOpGewenstInkomen,
  controleGewenstNbi: v => v.controleGewenstNbi,
  margeOndergrensPercentage: v => v.margeOndergrensPercentage ?? null,
  margeBovengrensPercentage: v => v.margeBovengrensPercentage ?? null,
  vastBedrag: v => v.vastBedrag ?? null,
  huidigNbiPercentage: v => v.huidigNbiPercentage ?? null,
  gewijzigdeJarenTonen: v => v.gewijzigdeJarenTonen,
  benodigdKapitaalTonen: v => v.benodigdKapitaalTonen
});

const mapUitgangspuntenWerkloosheid = createMapToDl(werkloosheidSchema).to<UitgangspuntenAdviesWerkloosheid>({
  controleHypotheeknorm: v => mapUitgangspuntenControleHypotheeknorm(v.controleHypotheeknorm),
  controleGewenstInkomen: v => mapUitgangspuntenControleGewenstInkomen(v.controleGewenstInkomen)
});

const mapUitgangspuntenPensioen = createMapToDl(pensioenSchema).to<UitgangspuntenAdviesPensioen>({
  controleHypotheeknorm: v => mapUitgangspuntenControleHypotheeknorm(v.controleHypotheeknorm),
  controleGewenstInkomen: v => mapUitgangspuntenControleGewenstInkomen(v.controleGewenstInkomen)
});

const mapUitgangspuntenOverlijden = createMapToDl(overlijdenSchema).to<UitgangspuntenAdviesOverlijden>({
  duurOverzicht: v => v.duurOverzicht,
  overzichtDuurInJaren: v =>
    v.duurOverzicht === DuurOverzichtOptions.VasteDuurInJaren ? v.overzichtDuurInJaren : null,
  overzichtTotLeeftijdInMaanden: v =>
    v.duurOverzicht === DuurOverzichtOptions.TotLeeftijd ? jaarMaandInMaanden(v.overzichtTotLeeftijdInMaanden) : null,
  controleHypotheeknorm: v => mapUitgangspuntenControleHypotheeknorm(v.controleHypotheeknorm),
  controleGewenstInkomen: v => mapUitgangspuntenControleGewenstInkomen(v.controleGewenstInkomen)
});

const mapArbeidsongeschiktheidScenario = createMapToDl(arbeidsongeschiktheidScenarioSchema).to<
  UitgangspuntenAdviesAoScenario
>({
  aoPercentage: v => v.aoPercentage,
  duurzaamAo: v => (v.aoPercentage && v.aoPercentage >= 80 ? v.duurzaamAo : null),
  verdiencapaciteitPercentage: v => v.verdiencapaciteitPercentage,
  scenario: v => v.scenario
});

const mapUitgangspuntenArbeidsongeschiktheid = createMapToDl(arbeidsongeschiktheidSchema).to<
  UitgangspuntenAdviesArbeidongeschiktheid
>({
  toetsjaarTekort: v => v.toetsjaarTekort,
  scenarios: v => v.scenarios.map(mapArbeidsongeschiktheidScenario),
  controleHypotheeknorm: v => mapUitgangspuntenControleHypotheeknorm(v.controleHypotheeknorm),
  controleGewenstInkomen: v => mapUitgangspuntenControleGewenstInkomen(v.controleGewenstInkomen)
});

export const mapUitgangspuntenUiToDl = createMapToDl(uitgangspuntenSchema).to<UitgangspuntenAdvies>({
  instellingenScherm: v => true,
  werkloosheid: v => mapUitgangspuntenWerkloosheid(v.werkloosheid),
  pensioen: v => mapUitgangspuntenPensioen(v.pensioen),
  overlijden: v => mapUitgangspuntenOverlijden(v.overlijden),
  arbeidongeschiktheid: v => mapUitgangspuntenArbeidsongeschiktheid(v.arbeidsongeschiktheid)
});
