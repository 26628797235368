/* istanbul ignore file */
import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

import {
  partijSchema,
  beschikbareNotarissenSchema,
  contactpersoonSchema,
  notarisAdresSchema,
  BeschikbareNotarissenState
} from "./notarissen-schema";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  NotarissenOutput,
  Notarissen,
  Notariskantoor,
  NotarisPersoon,
  Adres
} from "../../.generated/instellingen-forms/instellingen-formstypes";

const mapContactpersoon = createMapToUi(contactpersoonSchema).from<NotarisPersoon>({
  achternaam: v => v.achternaam,
  geslacht: v => v.geslacht,
  voorvoegsel: v => v.voorvoegsel,
  voorletters: v => v.voorletters
});

const mapAdres = createMapToUi(notarisAdresSchema).from<Adres>({
  huisnummer: v => v.huisnummer,
  plaats: v => v.plaats,
  postcode: v => {
    if (v.postcode && v.landCode === "NL") {
      const postcode = v.postcode.toUpperCase().replace(/\s/g, "");
      const letters = postcode.slice(4, 6);
      const numbers = postcode.slice(0, 4);
      if (numbers.charAt(0) !== "") {
        return `${numbers} ${letters}`;
      }
    }
    return v.postcode;
  },
  straat: v => v.straat,
  toevoeging: v => v.toevoeging,
  landCode: v => v.landCode
});

const mapPartij = createMapToUi(partijSchema).from<Notariskantoor>({
  adres: v => mapAdres(v.adres),
  contactpersoon: v => mapContactpersoon(v.contactpersoon),
  kantoornaam: v => v.kantoornaam,
  notariskantoorId: v => v.notariskantoorId,
  telefoonnummer: v => v.telefoonnummer
});

const mapPartijen = createMapToUi(beschikbareNotarissenSchema).from<Notarissen>({
  partijen: v => (v.partijen ? v.partijen.map(partij => mapPartij(partij)) : [])
});

export function mapNotarissenDlToUi(data: NotarissenOutput | null): BeschikbareNotarissenState | null {
  const notarissen = data && data.isValid && data.notarissen ? data.notarissen : null;

  if (notarissen) {
    return mapPartijen(data?.notarissen);
  }

  return null;
}

export function mapDlTargetToNotarissenUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
