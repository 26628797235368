/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { saveData } from "../shared/utils/save-data";
import Berekeningen from "./berekeningen";
import { mapberekeningenDlToUi, mapberekeningenUiToDl, mapDlTargetToberekeningenUiField } from "./infra";

const BerekeningenAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData

  const { params, requestInit, settings, user } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/Berekeningen`;
  const { loading, error, data } = useAbortableFetch<any>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapberekeningenDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Berekeningen
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapberekeningenUiToDl,
        mapDlTargetToberekeningenUiField
      )}
      {...mappedProps}
    />
  );
};

BerekeningenAjax.displayName = "berekeningenAjax";

export default withErrorBoundary(BerekeningenAjax);
