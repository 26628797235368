import React, { useContext, ReactElement } from "react";
import { AuthContext, withErrorBoundary, CardWrapper, ErrorPage, Card } from "adviesbox-shared";
import { AccessTokenDownload } from "./acces-token-download";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";

const AbcKoppeling = (): ReactElement => {
  const { user } = useContext(AuthContext);
  const authresult = useContext(AutorisatieNiveauContext);
  const {isBeheerder} = authresult;
  
  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  return (
    <CardWrapper className="px-3">
      <Card title="Download koppeling">
        <div className="text-container px-3">
          {isBeheerder ? (
            <>
              <p>
                Download hier het koppelingsbestand om de lokale versie van Adviesbox te kunnen verbinden met Adviesbox Online.
              </p>
              <AccessTokenDownload user={user} document={document} />
            </>
          ) : (
            <p>
              U heeft geen toegang tot deze instellingen. Om het koppeling-bestand te downloaden heeft u &apos;beheerder&apos;
              rechten nodig.
            </p>
          )}
        </div>
      </Card>
    </CardWrapper>
  );
};

AbcKoppeling.displayName = "AutorisatieNiveauAjax";

export default withErrorBoundary(AbcKoppeling);
