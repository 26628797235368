/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { AdviesonderwerpenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import Adviesonderwerpen from "./adviesonderwerpen";
import { mapAdviesonderwerpenDlToUi, mapAdviesonderwerpenUiToDl, mapDlTargetToAdviesonderwerpenUiField } from "./infra";

type RouteParams = {
  vestiging: string;
};

const AdviesonderwerpenAjax = (): ReactElement => {
  const { user, settings, requestInit, params } = useRequestInit<RouteParams>();
  const url = `${settings.instellingenFormsOrigin}/Adviesonderwerpen`;
  const { loading, error, data } = useAbortableFetch<AdviesonderwerpenOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapAdviesonderwerpenDlToUi(data);

  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Adviesonderwerpen
      saveData={saveData(
        user,
        params.vestiging,
        url,
        settings.OcpApimSubscriptionKey,
        mapAdviesonderwerpenUiToDl,
        mapDlTargetToAdviesonderwerpenUiField
      )}
      {...mappedProps}
    />
  );
};

AdviesonderwerpenAjax.displayName = "AdviesonderwerpenAjax";

export default withErrorBoundary(AdviesonderwerpenAjax);
