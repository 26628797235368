import React, { ReactElement, ReactNode, useState } from "react";
import VestigingenContext, { VestigingenContextType, vestiging } from "./vestigingen-context";

import { withErrorBoundary } from "adviesbox-shared";

const setContext = (
  vestigingen: vestiging[],
  setVestigingen: (vestigingen: vestiging[]) => void
): VestigingenContextType => ({
  vestigingen,
  setVestigingen
});

const VestigingenProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [vestigingen, setVestigingen] = useState([] as vestiging[]);

  return (
    <VestigingenContext.Provider value={setContext(vestigingen, setVestigingen)}>
      {children}
    </VestigingenContext.Provider>
  );
};

export default withErrorBoundary(VestigingenProvider);
