import { UiName } from "../types";

export const target2field = (map: { [key: string]: any }, target: string): UiName | null => {
  const regexIndex = /(?<=\[)([^.])(?=\])/gm;
  const regexCleanup = /\[([^.])\]/gm;
  const regexIndexFirstOccurance = /(?<=\[)([i])(?=\])/m;
  const indexArray = target.match(regexIndex);
  const parts = target.replace(regexCleanup, "").split(".");

  while (parts.length > 0) {
    const partPascal = parts.shift() as string;

    const part = partPascal.charAt(0).toLowerCase() + partPascal.slice(1);
    if (part && map[part]) {
      map = map[part];
    }
  }

  if (indexArray && map.field) {
    indexArray.forEach(i => {
      map.field = map.field.replace(regexIndexFirstOccurance, i);
    });
  }

  if (map) return { label: target, field: null, ...map };

  return null;
};
