import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";

import { VermogensbeheerdersState, VermogensbeheerdersProps, vermogensbeheerdersSchema } from "./infra";

import Beschikbarebeheerders from "./beschikbarebeheerders/beschikbarebeheerders";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Vermogensbeheerders = (
  props: FormikProps<VermogensbeheerdersState> & WithSaveData<VermogensbeheerdersState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.vermogensbeheerdersNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className={"p-2"}>
              <h2 className={"p-0"}>Vermogensbeheerders&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.vermogensbeheerdersNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Beschikbare Vermogensbeheerders" className="w-100">
            <div className="px-1">
              <Beschikbarebeheerders />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Vermogensbeheerders.displayName = "Vermogensbeheerders";

export default withAdviesboxFormik<
  VermogensbeheerdersProps & WithSaveData<VermogensbeheerdersState>,
  VermogensbeheerdersState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: VermogensbeheerdersProps): VermogensbeheerdersState => e,
  validationSchema: vermogensbeheerdersSchema
})(Vermogensbeheerders);
