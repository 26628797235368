import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getBerekeningenTextResources = createGetTextResources({
  TooltipHeffingskortingen:
    "Geef hieronder aan welke heffingskortingen meegenomen moeten worden in de fiscale berekeningen. In de doorrekening van de rapportagejaren wordt per jaar gekeken of de aanvrager(s) ervoor in aanmerking komen. Ook in de doorrekening van de scenario's wordt gekeken of de aanvrager(s) in dat geval in aanmerking komen voor deze heffingskortingen.",
  TooltipIndexatieWaardePandPercentage:
    "De indexatie waarde pand heeft geen invloed op de 'automatische rentedaling'. Die wordt gebaseerd op de initiële marktwaarde die bekend is bij de geldverstrekker.",
  TooltipminderingOverwaardeGewensteHypotheek:
    "Kies 'Ja' indien u de overwaarde van een te verkopen woning standaard als inbreng eigen geld wilt inzetten.",
  TooltipIngangsdatumScenarios:
    "Deze ingangsdatum is een jaar na het startjaar van de overzichten. In dit voorbeeld is uitgegaan van het huidige jaar. Het betreft de scenario's overlijden, arbeidsongeschiktheid en werkloosheid.",
  TooltiprekenrenteContanteWaardePercentage:
    "Dit tarief wordt gebruikt om de totale waarde van het tekort c.q. overschot over een vastgestelde duur te bepalen.",
  TooltipcontroleNbi:
    "Met deze instelling kunt u aangeven of de controle van het gewenste netto besteedbaar inkomen moet plaatsvinden op basis van de huidige situatie of situatie na advies (voorstel). \n• Bij de keuze voor 'Huidige situatie' wordt het huidige netto besteedbaar inkomen van de klant als uitgangspunt genomen in de nbi-scenario's. De huidige financiële ruimte van het lopende kalenderjaar wordt gebruikt als het gewenste netto besteedbaar inkomen.\n• Bij de keuze voor 'Situatie na advies' wordt het nieuwe netto besteedbaar inkomen van de klant als uitgangspunt genomen in de nbi-scenario's. De financiële ruimte van het betreffende voorstel van het eerste kalenderjaar na advies wordt gebruikt als het gewenste netto besteedbaar inkomen.",
  ErrorlengteOverzichtInJaren: "Waarde tussen 1 t/m 50 invullen.",
  ErrorindexatieWaardePandPercentage: "Maximale waarde 99% (niet 100%).",
  ErrorrekenrenteContanteWaardePercentage: "Waarde tussen 0 t/m 20% invullen."
});
