import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  partijSchema,
  beschikbareNotarissenSchema,
  contactpersoonSchema,
  notarisAdresSchema
} from "./notarissen-schema";
import {
  Notarissen,
  Notariskantoor,
  NotarisPersoon,
  Adres
} from "../../.generated/instellingen-forms/instellingen-formstypes";

const mapContactpersoon = createMapToDl(contactpersoonSchema).to<NotarisPersoon>({
  achternaam: v => v.achternaam,
  geslacht: v => v.geslacht,
  voorvoegsel: v => v.voorvoegsel,
  voorletters: v => v.voorletters
});

const mapAdres = createMapToDl(notarisAdresSchema).to<Adres>({
  huisnummer: v => v.huisnummer,
  plaats: v => v.plaats,
  postcode: v => {
    if (v.postcode && v.landCode === "NL") {
      const postcode = v.postcode.toUpperCase().replace(/\s/g, "");
      const letters = postcode.slice(4, 6);
      const numbers = postcode.slice(0, 4);
      if (numbers.charAt(0) !== "") {
        return `${numbers} ${letters}`;
      }
    }
    return v.postcode;
  },
  straat: v => v.straat,
  toevoeging: v => v.toevoeging,
  landCode: v => v.landCode
});

const mapPartij = createMapToDl(partijSchema).to<Notariskantoor>({
  adres: v => mapAdres(v.adres),
  contactpersoon: v => mapContactpersoon(v.contactpersoon),
  kantoornaam: v => v.kantoornaam,
  notariskantoorId: v => v.notariskantoorId,
  telefoonnummer: v => v.telefoonnummer
});

export const mapNotarissenUiToDl = createMapToDl(beschikbareNotarissenSchema).to<Notarissen>({
  partijen: v => v.partijen.map(partij => mapPartij(partij))
});
