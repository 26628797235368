import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { serviceprovidersSchema, ServiceprovidersState } from "./serviceproviders-schema";

function dl2ui(values: any): ServiceprovidersState {
  const serviceproviders = {
    ...serviceprovidersSchema.default(),
    beschikbareServiceproviders: {
      ...serviceprovidersSchema.default().beschikbareServiceproviders,
      partijen: values.partijen
        ? values.partijen.sort((a: any, b: any): number => {
            return new Intl.Collator(["NL"]).compare(a.naam, b.naam);
          })
        : []
    }
  };

  return serviceprovidersSchema.cast(serviceproviders);
}

export function mapServiceprovidersDlToUi(ServiceprovidersId: string, data: any): ServiceprovidersState | null {
  const serviceproviders = data ? data[ServiceprovidersId] : null;

  if (!serviceproviders) {
    return null;
  }

  return dl2ui(serviceproviders);
}

export function mapServiceprovidersUiToDl(serviceproviders: ServiceprovidersState): any {
  return {
    partijen: (serviceproviders.beschikbareServiceproviders.partijen || []).map(p => ({
      serviceproviderId: p.serviceproviderId,
      naam: p.naam,
      actief: p.actief,
      hdnNummer: p.hdnNummer
    }))
  };
}

export function mapDlTargetToServiceprovidersUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
