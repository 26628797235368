import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";

import { ServiceprovidersState, ServiceprovidersProps, serviceprovidersSchema } from "./infra";

import BeschikbareServiceproviders from "./beschikbare-serviceproviders/beschikbare-serviceproviders";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import classnames from "classnames";
import classes from "./beschikbare-serviceproviders/beschikbare-serviceproviders.module.scss";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Serviceproviders = (
  props: FormikProps<ServiceprovidersState> & WithSaveData<ServiceprovidersState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.serviceprovidersNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className={classnames(classes.text_container, "text-container")}>
            <div className={"p-2"}>
              <h2 className="p-0">Serviceproviders&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.serviceprovidersNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Beschikbare serviceproviders" className="w-100">
            <div className="px-1">
              <BeschikbareServiceproviders />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Serviceproviders.displayName = "Serviceproviders";

export default withAdviesboxFormik<ServiceprovidersProps & WithSaveData<ServiceprovidersState>, ServiceprovidersState>({
  // Transform outer props into form values
  mapPropsToValues: (e: ServiceprovidersProps): ServiceprovidersState => e,
  validationSchema: serviceprovidersSchema
})(Serviceproviders);
