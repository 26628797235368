import { ItemType, MenuConfigType } from "adviesbox-shared";
import { KlantnaamReadOnly } from "../../.generated/forms/formstypes";

export const getAanvragerNaam = (aanvrager: KlantnaamReadOnly): string => {
  const voorletters = aanvrager?.voorletters?.length
    ? `\xa0${aanvrager?.voorletters}`
    : aanvrager?.voornamen
    ? `\xa0${aanvrager.voornamen}`
    : /* istanbul ignore next */ "";

  const voorvoegsel = aanvrager?.voorvoegsel ? `\xa0${aanvrager?.voorvoegsel}` : /* istanbul ignore next */ "";

  const achternaam = aanvrager?.achternaam ? `${aanvrager.achternaam}` : /* istanbul ignore next */ "";

  return `${achternaam},${voorletters}${voorvoegsel}`;
};

export const getActivePageTitle = (menuConfig: MenuConfigType[], locationPath: string): string => {
  const result: ItemType[] = [];
  const collect = (item: ItemType): any => {
    result.push(item);
    if (item.items) item.items.forEach(c => collect(c));
  };

  menuConfig.forEach(k => {
    k.lists.forEach(c => {
      collect(c);
      c.items?.forEach(item => collect(item));
    });
  });

  return result.find(c => c.link === locationPath)?.name || "Page name";
};
