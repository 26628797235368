import React, { ReactElement, useContext } from "react";
import { CardWrapper, Card, FormFirstFocus } from "adviesbox-shared";
import { KoppelingProps, KoppelingState, koppelingenSchema, KoppelingType } from "./infra";
import { koppelingAsyncSideEffects } from "./infra/determine-koppeling-async-side-effects";
import KoppelingDataGrid from "./koppelingdatagrid/koppelingDataGrid";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Form, FormikProps, useFormikContext } from "formik";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Koppelingscherm = (props: FormikProps<KoppelingState>): ReactElement => {
  const { isBeheerder } = useContext(AutorisatieNiveauContext);
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { values, setFieldValue } = useFormikContext<KoppelingState>();
  /*istanbul ignore next */
  const saveEditedData = (): void => {
    setFieldValue(`update`, !values.update);
  };

  return (
    <FormFirstFocus>
      <Form>
        <fieldset
          disabled={
            !(isBeheerder || autorisatieNiveau.generiekeKoppelingenNiveau === InstellingNiveauOptions.Medewerker)
          }
        >
          <ISWSideEffects<KoppelingType> async={koppelingAsyncSideEffects} />
          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>{"Koppeling adviseur & bemiddelaar"}</h2>
              <div className="save-btn-position">
                <SaveButton context={{ ...props, saveData: saveEditedData }} />
              </div>
            </div>
          </CardWrapper>
          <CardWrapper className="px-3" maxRowCount={4}>
            <Card title={`Koppeling adviseur & bemiddelaar`} className=" w-md-50 w-50">
              <div>
                <p>
                  Je kunt hier een standaardkoppeling tussen adviseur en bemiddelaar toevoegen. Als je hier een adviseur
                  aan een bemiddelaar koppelt zullen de adviesdossiers bij het aanvragen direct gekoppeld worden aan de
                  genoemde bemiddelaar. Als de adviseur niet in onderstaande lijst staat moet de adviseur bij elke
                  aanvraag een bemiddelaar selecteren.
                </p>
                <p>Let op; een adviseur kan maximaal één keer gekoppeld worden aan een bemiddelaar.</p>
              </div>
              <KoppelingDataGrid />
            </Card>
          </CardWrapper>
        </fieldset>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

/*istanbul ignore next */
Koppelingscherm.displayName = "Koppelingscherm";
export default withAdviesboxFormik<KoppelingProps, KoppelingState>({
  // Transform outer props into form values
  mapPropsToValues: (e: KoppelingProps): KoppelingState => e,
  validationSchema: koppelingenSchema
})(Koppelingscherm);
