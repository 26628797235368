import { PlatformData } from "adviesbox-shared";
import { User } from "oidc-client";
import { UiError, UiName } from "../../shared/types";
import { validationResultsToUiError } from "./validation-results-to-ui-error";

export type WithSaveData<T> = {
  saveData: (values: T) => Promise<UiError[] | null>;
};

export type FileWithName = File & { name: string };

export function saveDataWithFiles(
  user: User,
  vestigingId: string,
  url: string,
  ocpApimSubscriptionKey: string,
  files: FileWithName | FileWithName[],
  mapperDlNameToUiName: (target: string) => UiName | null,
  singleFormFilename?: string | null
): (values: any) => Promise<UiError[] | null> {
  if (!user) {
    return async (): Promise<null> => {
      throw new Error("Geen user object.");
    };
  }

  const headers: Record<string, string> = {
    "Ocp-Apim-Subscription-Key": ocpApimSubscriptionKey,
    authorization: `${user.token_type} ${user.access_token}`,
    vestigingId,
  };

  const requestInit = { headers };

  return saveDataWithFilesRequestInit(
    url,
    requestInit,
    files,
    mapperDlNameToUiName,
    singleFormFilename
  );
}

export function saveDataWithFilesRequestInit(
  url: string,
  requestInit: RequestInit,
  files: FileWithName | FileWithName[],
  mapperDlNameToUiName: (target: string) => UiName | null,
  singleFormFilename?: string | null | undefined
): (values: any) => Promise<UiError[] | null> {
  return async (): Promise<UiError[] | null> => {
    try {
      const formData = filesToFormData(files, singleFormFilename);
      const rsp = await fetch(url, {
        ...requestInit,
        ...{
          headers: {
            ...requestInit.headers,
          },
        },
        method: "POST",
        body: formData,
      });

      if (!rsp.ok) {
        if (contentTypeIsJson(rsp)) {
          const data: PlatformData = await rsp.json();
          if (data && data.validationResults) {
            return validationResultsToUiError(
              data.validationResults,
              mapperDlNameToUiName
            );
          }
        }
      }
      return null;
    } catch (e) {
      return [
        {
          field: "",
          label: "",
          error: e.message,
        },
      ];
    }
  };
}

export function filesToFormData(
  files: FileWithName | FileWithName[],
  singleFormFilename?: string | null
): FormData {
  const formData = new FormData();

  if (files instanceof Array && files.length > 1) {
    files.map(async (file: FileWithName) => {
      formData.append(file.name, file);
    });
  } else if (singleFormFilename) {
    const file: FileWithName =
      files instanceof Array && files.length === 1
        ? files[0]
        : (files as FileWithName);
    formData.append(singleFormFilename, file);
  } else {
    const file: FileWithName =
      files instanceof Array && files.length === 1
        ? files[0]
        : (files as FileWithName);
    formData.append(file.name, file);
  }
  return formData;
}

export function saveData<T>(
  user: User,
  vestigingId: string,
  url: string,
  ocpApimSubscriptionKey: string,
  mapper: (values: T) => any,
  mapperDlNameToUiName: (target: string) => UiName | null
): (values: T) => Promise<UiError[] | null> {
  if (!user) {
    return async (): Promise<null> => {
      throw new Error("Geen user object.");
    };
  }

  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": ocpApimSubscriptionKey,
    authorization: `${user.token_type} ${user.access_token}`,
    vestigingId,
  };

  const requestInit = { headers };

  return saveDataRequestInit<T>(url, requestInit, mapper, mapperDlNameToUiName);
}

export function saveDataRequestInit<T>(
  url: string,
  requestInit: RequestInit,
  mapper: (values: T) => any,
  mapperDlNameToUiName: (target: string) => UiName | null,
  reloadNavigation?: () => void
) {
  return async (values: T): Promise<UiError[] | null> => {
    const mappedValues = mapper(values);
    const rsp = await fetch(url, {
      ...requestInit,
      ...{
        headers: {
          ...requestInit.headers,
          "Content-Type": "application/json",
        },
      },
      method: "PUT",
      body: JSON.stringify(mappedValues),
    });

    if (!rsp.ok) {
      if (contentTypeIsJson(rsp)) {
        const data: PlatformData = await rsp.json();
        if (data && data.validationResults) {
          return validationResultsToUiError(
            data.validationResults,
            mapperDlNameToUiName
          );
        }
      }
    } else if (reloadNavigation) {
      reloadNavigation();
    }

    return null;
  };
}

function contentTypeIsJson(rsp: Response): boolean {
  const contentType = rsp.headers.get("content-type");
  return !!(contentType && contentType.indexOf("application/json") !== -1);
}
