/* istanbul ignore file */

import {
  beschikbareHypotheekProducten,
  ErrorPage,
  PageLoading,
  useRequestInit,
  withErrorBoundary
} from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { GeldverstrekkersOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { HypotheekvormBasis } from "../.generated/producten/productentypes";
import { partijFilter } from "../shared/utils/partij-filter";
import { saveData } from "../shared/utils/save-data";
import Geldverstrekkers from "./geldverstrekkers";
import { mapGeldverstrekkersDlToUi } from "./infra";
import { mapDlTargetToGeldverstrekkersUiField, mapGeldverstrekkersUiToDl } from "./infra/map-geldverstrekkers-ui-to-dl";

const GeldverstrekkersAjax = (): ReactElement => {
  const { requestInit, settings, params, user } = useRequestInit<{ vestiging: string }>();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Geldverstrekkers`;
  const { loading, error, data } = useAbortableFetch<GeldverstrekkersOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapGeldverstrekkersDlToUi(data);

  if (!mappedProps) {
    return <PageLoading />;
  }

  const filteredPartijen = mappedProps.beschikbareGeldverstrekkers.partijen.filter(
    c =>
      c.hypotheeklabels.find(l =>
        partijFilter({ maatschappijCode: c.code, code: l.code } as HypotheekvormBasis, beschikbareHypotheekProducten)
      ) !== undefined
  );

  mappedProps.beschikbareGeldverstrekkers.partijen = filteredPartijen;

  return (
    <Geldverstrekkers
      saveData={saveData(
        user,
        params.vestiging,
        url,
        settings.OcpApimSubscriptionKey,
        mapGeldverstrekkersUiToDl,
        mapDlTargetToGeldverstrekkersUiField
      )}
      {...mappedProps}
    />
  );
};

GeldverstrekkersAjax.displayName = "GeldverstrekkersAjax";

export default withErrorBoundary(GeldverstrekkersAjax);
