import { getBerekeningenTextResources } from "./berekeningen-resources";
import * as Yup from "yup";
import { ControleNbiOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";

import { InferType } from "yup";
import { hasValue } from "../../shared/utils/helpers";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const algemeenSchema = Yup.object({
  huurstijgingPercentage: Yup.number().default(0),
  indexatieWaardePandPercentage: Yup.number()
    .default(0)
    .test({
      message: getBerekeningenTextResources("ErrorindexatieWaardePandPercentage"),
      test: function(value: number): boolean {
        return !!value ? value <= 99 : true;
      }
    }),
  minderingOverwaardeGewensteHypotheek: Yup.boolean().default(false)
});

export const heffingskortingenSchema = Yup.object({
  algemeneHeffingskorting: Yup.boolean().default(true),
  arbeidskorting: Yup.boolean().default(false),
  inkomensafhankelijkeCombinatiekorting: Yup.boolean().default(false),
  ouderenkorting: Yup.boolean().default(false),
  alleenstaandeOuderenkorting: Yup.boolean().default(false),
  werkbonus: Yup.boolean().default(false),
  kindgebondenBudget: Yup.boolean().default(false)
});

export const scenariosSchema = Yup.object({
  ingangsdatumScenarios: nullableLocalDateSchema,
  lengteOverzichtInJaren: Yup.number()
    .default(0)
    .test({
      message: getBerekeningenTextResources("ErrorlengteOverzichtInJaren"),
      test: function(value: number): boolean {
        return hasValue(value) ? value >= 1 && value <= 50 : true;
      }
    }),
  eindleeftijdScenarios: Yup.number().default(0),
  rekenrenteContanteWaardePercentage: Yup.number()
    .default(0)
    .test({
      message: getBerekeningenTextResources("ErrorrekenrenteContanteWaardePercentage"),
      test: function(value: number): boolean {
        return hasValue(value) ? value >= 0 && value <= 20 : true;
      }
    }),
  controleNbi: Yup.mixed()
    .oneOf(Object.values(ControleNbiOptions))
    .default(ControleNbiOptions.HuidigeSituatie)
});

export const berekeningenSchema = Yup.object({
  algemeen: algemeenSchema,
  heffingskortingen: heffingskortingenSchema,
  scenarios: scenariosSchema
});

export type BerekeningenState = InferType<typeof berekeningenSchema>;
export type BerekeningenProps = InferType<typeof berekeningenSchema>;

export type AlgemeenType = InferType<typeof algemeenSchema>;
export type HeffingskortingenType = InferType<typeof heffingskortingenSchema>;
export type ScenariosType = InferType<typeof scenariosSchema>;
