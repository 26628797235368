import React, { ReactElement, ChangeEvent } from "react";
import { RadioInputOption, TooltipWrap } from "adviesbox-shared";
import {
  AutorisatieNiveauRowProps,
  AutorisatieNiveauState,
} from "./autorisatie-niveau-schema";
import { connect, getIn, FormikContextType } from "formik";
import autoristatieNiveauClasses from "./autorisatie-niveau-row.module.scss";

export enum AutorisatieNiveauUniverseel {
  Organisatie = "Organisatie",
  Vestiging = "Vestiging",
  Medewerker = "Medewerker",
}

const AutorisatieNiveauRow = ({
  formikKey,
  name,
  index,
  formik: { values, setFieldValue, initialValues },
}: AutorisatieNiveauRowProps & {
  formik: FormikContextType<AutorisatieNiveauState>;
}): ReactElement => {
  const centerElementByClassNameString =
    "d-flex align-items-center justify-content-center h-100";
  const value = getIn(values, `${formikKey}.${name}`);
  const initialValue = getIn(initialValues, `${formikKey}.${name}`);
  const tooltipMessage =
    "Let op; Instellingen worden per autorisatieniveau opgeslagen. Als u het autorisatieniveau aanpast worden de instellingen behorende bij het nieuw geselecteerde autorisatieniveau geactiveerd. Controleer per scherm of de instellingen overeen komen met de gewenste instellingen.";

  const showWarning = {
    vestiging:
      value === AutorisatieNiveauUniverseel.Vestiging &&
      initialValue !== AutorisatieNiveauUniverseel.Vestiging,
    organisatie:
      value === AutorisatieNiveauUniverseel.Organisatie &&
      initialValue !== AutorisatieNiveauUniverseel.Organisatie,
    medewerker:
      value === AutorisatieNiveauUniverseel.Medewerker &&
      initialValue !== AutorisatieNiveauUniverseel.Medewerker,
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`${formikKey}.${name}`, event.target.value);
  };

  return (
    <div className={`col-12 row m-0 px-2 py-1 ${!!index && `border-top`}`}>
      <div className="col-6 p-0">{name}</div>
      <div className={`col-2 p-0 d-flex`}>
        {/* ../../shared/components/radio-input/RadioInputGroup.module.scss: radio */}
        <div className={`radio mx-auto ${autoristatieNiveauClasses.relative}`}>
          <RadioInputOption
            onChange={onChange}
            layoutClass={centerElementByClassNameString}
            name={`${formikKey}.${name}`}
            value={value}
            noMarginLabel={true}
            item={{
              label: "",
              value: AutorisatieNiveauUniverseel.Organisatie,
            }}
          />
          {showWarning.organisatie && (
            <TooltipWrap
              name="organisatie"
              warningText={tooltipMessage}
              iconType="waarschuwing"
              placement="bottom"
              tooltipClasses={`${autoristatieNiveauClasses.warning} mt-2`}
            />
          )}
        </div>
      </div>
      <div className={`col-2 p-0 d-flex`}>
        <div className={`radio mx-auto ${autoristatieNiveauClasses.relative}`}>
          <RadioInputOption
            onChange={onChange}
            layoutClass={centerElementByClassNameString}
            name={`${formikKey}.${name}`}
            noMarginLabel={true}
            value={value}
            item={{
              label: "",
              value: AutorisatieNiveauUniverseel.Vestiging,
            }}
          />
          {showWarning.vestiging && (
            <TooltipWrap
              name="vestiging"
              warningText={tooltipMessage}
              iconType="waarschuwing"
              placement="bottom"
            />
          )}
        </div>
      </div>
      <div className={`col-2 p-0 ${autoristatieNiveauClasses.relative}`}>
        <div className={`radio mx-auto`}>
          <RadioInputOption
            onChange={onChange}
            layoutClass={centerElementByClassNameString}
            name={`${formikKey}.${name}`}
            noMarginLabel={true}
            value={value}
            item={{
              label: "",
              value: AutorisatieNiveauUniverseel.Medewerker,
            }}
          />
          {showWarning.medewerker && (
            <TooltipWrap
              name="medewerker"
              warningText={tooltipMessage}
              iconType="waarschuwing"
              placement="bottom"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect<AutorisatieNiveauRowProps, AutorisatieNiveauState>(
  AutorisatieNiveauRow
);
