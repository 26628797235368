import React, { ReactElement } from "react";
import { FormikContextType } from "formik";
import { Column, CellInfo } from "react-table-6";
import { Icon } from "adviesbox-shared";
import { GeslachtOpties } from "../../.generated/forms/formstypes";

import { Modal as ModalBootstrap } from "react-bootstrap";
import { NotariskantoorToevoegenModal } from "./notariskantoor-toevoegen-modal";
import classes from "./beschikbare-notarissen.module.scss";
import ModalButton from "../../shared/components/modal/ModalButton";
import produce from "immer";
import { BeschikbareNotarissenState } from "../infra/notarissen-schema";

const titleMapper = {
  [GeslachtOpties.Geen]: "",
  [GeslachtOpties.Man]: "Dhr.",
  [GeslachtOpties.Vrouw]: "Mevr."
};

export const EmptyComponent = (): null => null;

const createSpanWithId = (name: string): ((c: CellInfo) => ReactElement) => (c): ReactElement => {
  return <span id={`${c.index}-row-${name}-notarissen`}>{c.original[name]}</span>;
};

export const DeleteNotarisModal = ({
  closeModal,
  formik,
  index
}: {
  formik: FormikContextType<BeschikbareNotarissenState>;
  index: number;
  closeModal?: () => void;
}): ReactElement => {
  const { values, setFieldValue } = formik;

  const deleteNotaris = (): void => {
    setFieldValue(
      "partijen",
      produce(values.partijen, v => {
        v.splice(index, 1);
      })
    );
    setFieldValue("dirty", true);
  };

  return (
    <>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>
            Weet u zeker dat u {values.partijen[index].kantoornaam} wilt verwijderen?
          </ModalBootstrap.Title>
          <button type="button" className={"close ml-auto"} onClick={closeModal} id={`btnClose-notaris-toevoegen`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <p className="px-2">U raakt alle gegevens kwijt als u dit notariskantoor verwijderd.</p>
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer className={classes.modal_footer}>
        <button type="button" className="btn btn-link" id="annuleer-verwijderen-notaris" onClick={closeModal}>
          Annuleren
        </button>
        <button type="button" className="btn btn-primary" id="bevestig-verwijderen-notaris" onClick={deleteNotaris}>
          Notariskantoor verwijderen
        </button>
      </ModalBootstrap.Footer>
    </>
  );
};

export const DeleteModalButton = (formik: FormikContextType<BeschikbareNotarissenState>) => (
  c: CellInfo
): ReactElement => {
  return (
    <ModalButton
      parent={`beschikbareNotarissen-delete-${c.index}`}
      content={<Icon name="prullenbak" alt={"prullenbak"} />}
    >
      <DeleteNotarisModal formik={formik} index={c.index} />
    </ModalButton>
  );
};

export const EditModalButton = (formik: FormikContextType<BeschikbareNotarissenState>) => (
  c: CellInfo
): ReactElement => {
  return (
    <ModalButton parent={`beschikbareNotarissen-edit-${c.index}`} content={<Icon name="pencil" alt={"pencil"} />}>
      <NotariskantoorToevoegenModal
        onSave={p =>
          formik.setValues(
            produce(formik.values, v => {
              v.partijen[c.index] = p;
            })
          )
        }
        data={formik.values.partijen[c.index]}
      />
    </ModalButton>
  );
};

export const AdresComponent = (c: CellInfo): ReactElement => {
  const getAdres = (adres: {
    plaats: string;
    postcode: string;
    toevoeging: string;
    huisnummer: string;
    straat: string;
  }): string =>
    `${adres.plaats && adres.plaats + ",\xa0"}` +
    `${adres.postcode && adres.postcode.substring(0, 4) + "\xa0" + adres.postcode.substring(4) + ",\xa0"}` +
    `${adres.straat && adres.straat + "\xa0"}` +
    `${adres.huisnummer || ""}` +
    `${adres.toevoeging && "\xa0" + adres.toevoeging}`;

  return <div id={`${c.index}-row-adres-notarissen`}>{getAdres(c.original.adres)}</div>;
};

export const ContactPersonComponent = (c: CellInfo): ReactElement => {
  const getContactPerson = (person: {
    achternaam: string;
    voorvoegsel: string;
    voorletters: string;
    geslacht: string;
  }): string =>
    `${person.geslacht && titleMapper[person.geslacht as GeslachtOpties] + "\xa0"}` +
    `${person.voorletters && person.voorletters + "\xa0"}` +
    `${person.voorvoegsel ? person.voorvoegsel + "\xa0" : ""}` +
    `${person.achternaam && person.achternaam}`;

  return <div id={`${c.index}-row-contact-persoon-notarissen`}>{getContactPerson(c.original.contactpersoon)}</div>;
};

export const getColumns = (formik: FormikContextType<BeschikbareNotarissenState>): Column[] => [
  {
    Header: "Notariskantoor",
    minWidth: 210,
    Cell: createSpanWithId("kantoornaam")
  },
  {
    Header: "Woonplaats",
    minWidth: 210,
    Cell: AdresComponent
  },
  {
    Header: "Contactpersoon",
    minWidth: 210,
    Cell: ContactPersonComponent
  },
  {
    Header: "Telefoonnummer",
    minWidth: 210,
    Cell: createSpanWithId("telefoonnummer")
  },
  {
    Cell: EditModalButton(formik),
    maxWidth: 30
  },
  {
    Cell: DeleteModalButton(formik),
    maxWidth: 30
  }
];
