import {
  Card,
  CardWrapper,
  PageLoading,
  PlatformFoutenSamenvatting,
} from "adviesbox-shared";
import { UiError } from "adviesbox-shared/utils/types";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Prompt } from "react-router-dom";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { Debug } from "../shared/components/formik/Debug";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import Beheer from "./beheer/beheer";
import {
  AutorisatieNiveauProps,
  autorisatieNiveauSchema,
  AutorisatieNiveauState,
  mapAutorisatieNiveauUiToDl,
  getAutorisatieNiveauTextResources,
} from "./infra";
import Instellingen from "./instellingen/instellingen";
import Uitleg from "./uitleg/uitleg";
import Voorkeuren from "./voorkeuren/voorkeuren";
import { SaveButtonWithConfirmation } from "../save-button/save-button-with-confimation";

const AutorisatieNiveau = (
  props: FormikProps<AutorisatieNiveauState> &
    WithSaveData<AutorisatieNiveauState>
): ReactElement => {
  const { saveData, values, resetForm, dirty } = props;
  const { setAutorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const [loading, setLoading] = useState(false);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  const save = async (): Promise<UiError[] | null> => {
    let res = null;
    try {
      setLoading(true);
      res = await saveData(values);
      resetForm({ values });
      const mappedValues = mapAutorisatieNiveauUiToDl(values);
      setAutorisatieNiveau(mappedValues);
      setLoading(false);
      return res;
    } catch {
      /* eslint-disable-next-line no-console */
      console.error("opslaan autorisatieniveau mislukt");
      return res;
    }
  };

  const modalBody = (
    <p>{getAutorisatieNiveauTextResources("ConfirmationTextAutorisatie")}</p>
  );

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset disabled={!isBeheerder}>
        <Prompt message="browserInfo" when={dirty} />
        {loading && <PageLoading />}
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="p-2">
              <h2 className="p-0">Autorisatieniveau&nbsp;</h2>
              <div className="save-btn-position">
                <SaveButtonWithConfirmation
                  props={{ ...props, saveData: save }}
                  modalBody={modalBody}
                  modalTitle={"Autorisatieniveau wijzigen"}
                  saveBtnVariant={"danger"}
                  saveBtnTekst={"Wijzigingen opslaan"}
                  cancelBtnTekst={"Annuleren"}
                />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper maxRowCount={1} className="px-3">
          <Card title="Uitleg autorisatieniveau">
            <Uitleg />
          </Card>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Voorkeuren">
            <Voorkeuren />
          </Card>
          <Card title="Adviesinstellingen">
            <Instellingen />
          </Card>
          <Card title="Beheer partijen">
            <Beheer />
          </Card>
        </CardWrapper>
      </fieldset>

      <Debug />
    </Form>
  );
};

AutorisatieNiveau.displayName = "AutorisatieNiveau";

export default withAdviesboxFormik<
  AutorisatieNiveauProps & WithSaveData<AutorisatieNiveauState>,
  AutorisatieNiveauState
>({
  mapPropsToValues: (e: AutorisatieNiveauProps): AutorisatieNiveauState => e,
  validationSchema: autorisatieNiveauSchema,
})(AutorisatieNiveau);
