import { Verzekeraars, VerzekeraarPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { beschikbareVerzekeraarsPartijen, verzekeraarsSchema } from "./verzekeraars-schema";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapPartijen = createMapToDl(beschikbareVerzekeraarsPartijen).to<VerzekeraarPartij>({
  actief: v => v.actief,
  agentnummer: v => v.agentnummer,
  code: v => v.code,
  partijnaam: v => v.partijnaam,
  serviceproviderId: v => v.serviceproviderId,
  softwareKoppelingId: v => v.softwareKoppelingId
});

export const mapverzekeraarsUiToDl = createMapToDl(verzekeraarsSchema).to<Verzekeraars>({
  partijen: v => v.beschikbareVerzekeraars.partijen.map(c => mapPartijen(c)),
  serviceproviders: v => v.beschikbareVerzekeraars.serviceProviders,
  softwareKoppelingen: v => v.beschikbareVerzekeraars.softwareKoppelingen
});
