import React, { ReactElement, useContext } from "react";
import { FormikProps, Form } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";

import { TarievenState, TarievenProps, tarievenSchema } from "./infra";
import Uitleg from "./uitleg/uitleg";
import Financieringsopzet from "./financieringsopzet/financieringsopzet";
import AdvieskostenMetHypotheek from "./advieskosten-met-hypotheek/advieskosten-met-hypotheek";
import AdvieskostenZonderHypotheek from "./advieskosten-zonder-hypotheek/advieskosten-zonder-hypotheek";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Tarieven = (props: FormikProps<TarievenState> & WithSaveData<TarievenState>): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset disabled={!(isBeheerder || autorisatieNiveau.tarievenNiveau === InstellingNiveauOptions.Medewerker)}>
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className={"p-2"}>
              <h2 className={"p-0"}>Tarieven&nbsp;</h2>

              <AutorisatieNiveauManager manageFor={autorisatieNiveau.tarievenNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper maxRowCount={1} className="px-3">
          <Card title="Uitleg tarieven">
            <Uitleg />
          </Card>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Financieringsopzet">
            <Financieringsopzet />
          </Card>
          <Card title="Advieskosten met hypotheekadvies">
            <AdvieskostenMetHypotheek />
          </Card>
          <Card title="Advieskosten zonder hypotheek">
            <AdvieskostenZonderHypotheek />
          </Card>
        </CardWrapper>
      </fieldset>

      <Debug />
    </Form>
  );
};

Tarieven.displayName = "Tarieven";

export default withAdviesboxFormik<TarievenProps & WithSaveData<TarievenState>, TarievenState>({
  // Transform outer props into form values
  mapPropsToValues: (e: TarievenProps): TarievenState => e,
  validationSchema: tarievenSchema
})(Tarieven);
