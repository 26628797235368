import * as Yup from "yup";

export const NationaleWaarborgSchema = Yup.object({
  emailaddress: Yup.string().default(""),
  password: Yup.string().default(""),
  apifoutNwb: Yup.number().nullable(),
  platformApiFouten: Yup.array(
    Yup.object({
      error: Yup.string(),
      field: Yup.string(),
      label: Yup.string()
    })
  ).nullable(),
  token: Yup.string()
    .nullable()
    .default(""),
  reedsActief: Yup.bool().nullable()
});

export type NationaleWaarborgState = Yup.InferType<typeof NationaleWaarborgSchema>;
export type NationaleWaarborgProps = Yup.InferType<typeof NationaleWaarborgSchema>;
