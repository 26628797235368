import React, { ReactElement, useContext } from "react";
import { FormikProps, Form } from "formik";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import Volledigheid from "./volledigheid/volledigheid";
import { klantprofielMotiveringState, klantprofielMotiveringProps, klantprofielMotiveringSchema } from "./infra";
import { SaveButton } from "../save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Klantprofielmotivering = (
  props: FormikProps<klantprofielMotiveringState> & WithSaveData<klantprofielMotiveringState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={
          !(isBeheerder || autorisatieNiveau.klantprofielMotiveringNiveau === InstellingNiveauOptions.Medewerker)
        }
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="p-2">
              <h2 className="p-0">Klantprofiel en motivering&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.klantprofielMotiveringNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Controle op volledigheid">
            <Volledigheid />
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Klantprofielmotivering.displayName = "klantprofiel-motivering";

export default withAdviesboxFormik<
  klantprofielMotiveringProps & WithSaveData<klantprofielMotiveringState>,
  klantprofielMotiveringState
>({
  mapPropsToValues: (e: klantprofielMotiveringProps): klantprofielMotiveringState => e,
  validationSchema: klantprofielMotiveringSchema
})(Klantprofielmotivering);
