import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";

import { TaxatiebureausState, TaxatiebureausProps, taxatiebureausSchema } from "./infra";

import BeschikbareTaxatiebureaus from "./beschikbare-taxatiebureaus/beschikbare-taxatiebureaus";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import classnames from "classnames";
import classes from "./beschikbare-taxatiebureaus/beschikbare-taxatiebureaus.module.scss";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Taxatiebureaus = (props: FormikProps<TaxatiebureausState> & WithSaveData<TaxatiebureausState>): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <FormFirstFocus>
      <Form>
        <PlatformFoutenSamenvatting />

        <fieldset
          disabled={!(isBeheerder || autorisatieNiveau.taxatiebureausNiveau === InstellingNiveauOptions.Medewerker)}
        >
          <CardWrapper className="px-3">
            <div className={classnames(classes.text_container, "text-container")}>
              <div className={"p-2"}>
                <h2>Taxatiebureaus&nbsp;</h2>
                <AutorisatieNiveauManager manageFor={autorisatieNiveau.taxatiebureausNiveau} />
                <div className="save-btn-position">
                  <SaveButton context={props} />
                </div>
              </div>
            </div>
          </CardWrapper>
          <CardWrapper className="px-3">
            <Card title="Beschikbare taxatiebureaus" className="w-100">
              <div className="px-1">
                <BeschikbareTaxatiebureaus />
              </div>
            </Card>
          </CardWrapper>
        </fieldset>

        {/* istanbul ignore next */
        process.env.NODE_ENV === "development" && <Debug />}
      </Form>
    </FormFirstFocus>
  );
};

Taxatiebureaus.displayName = "Taxatiebureaus";

export default withAdviesboxFormik<TaxatiebureausProps & WithSaveData<TaxatiebureausState>, TaxatiebureausState>({
  // Transform outer props into form values
  mapPropsToValues: (e: TaxatiebureausProps): TaxatiebureausState => e,
  validationSchema: taxatiebureausSchema
})(Taxatiebureaus);
