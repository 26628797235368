import { InferType } from "yup";
import * as Yup from "yup";

export enum AanvraagType {
  Serviceprovider = "Serviceprovider",
  SoftwareKoppeling = "SoftwareKoppeling"
}

export const hypotheekLabelSchema = Yup.object({
  code: Yup.number(),
  omschrijving: Yup.string().nullable(),
  hypotheekopties: Yup.array(
    Yup.object({
      code: Yup.number(),
      omschrijving: Yup.string().nullable(),
      toelichting: Yup.string().nullable(),
      geselecteerd: Yup.boolean(),
      optieGroep: Yup.number().nullable()
    })
  ).default([])
});

export const beschikbareGeldverstrekkersPartijen = Yup.object({
  serviceproviderId: Yup.string().nullable(),
  softwareKoppelingId: Yup.string().nullable(),
  aanvraagNaar: Yup.string().default("").nullable(),
  aanvullendeRetourwijze: Yup.string().nullable(),
  hypotheeklabels: Yup.array(hypotheekLabelSchema).default([]),
  code: Yup.string().nullable(),
  partijnaam: Yup.string().nullable(),
  actief: Yup.boolean(),
  agentnummer: Yup.string().nullable()
});

export const beschikbareGeldverstrekkersServiceProviders = Yup.object({
  serviceproviderId: Yup.string(),
  naam: Yup.string().nullable()
});

export const beschikbareSoftwareKoppelingen = Yup.object({
  softwareKoppelingId: Yup.string().default(""),
  naam: Yup.string().nullable()
});

export const beschikbareGeldverstrekkersSchema = Yup.object({
  partijen: Yup.array(beschikbareGeldverstrekkersPartijen).default([]),
  serviceProviders: Yup.array(beschikbareGeldverstrekkersServiceProviders).default([]),
  softwareKoppelingen: Yup.array(beschikbareSoftwareKoppelingen).default([])
});

export const geldverstrekkersSchema = Yup.object({
  beschikbareGeldverstrekkers: beschikbareGeldverstrekkersSchema
});

export type GeldverstrekkersState = InferType<typeof geldverstrekkersSchema>;
export type GeldverstrekkersProps = InferType<typeof geldverstrekkersSchema>;

export type BeschikbareGeldverstrekkersType = InferType<typeof beschikbareGeldverstrekkersSchema>;
