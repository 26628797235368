import React, { ReactElement } from "react";

import { CheckBox } from "adviesbox-shared";

type KolommenInHetNettoBesteedbaarInkomenOverzichtProps = {};

export const KolommenInHetNettoBesteedbaarInkomenOverzicht = (
  _props: KolommenInHetNettoBesteedbaarInkomenOverzichtProps
): ReactElement => {
  return (
    <>
      <p className="p-3">
        Het aantal kolommen dat op de pagina past is afhankelijk van de afdrukstand. Bij de{" "}
        <span className="font-weight-bold">staande</span> afdrukstand passen{" "}
        <span className="font-weight-bold">maximaal 9 kolommen</span> op de pagina. Kolommen met alleen maar nulwaarden
        worden niet getoond.
      </p>
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.hypotheek" caption="Hypotheek" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.verzekeringen" caption="Verzekeringen" />
      <CheckBox
        name="kolommenInHetNettoBesteedbaarInkomenOverzicht.spaarEnBeleggingsdepots"
        caption="Spaar- en beleggingsdepots"
      />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.kredietLast" caption="Krediet last" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.overigeUitgeven" caption="Overige uitgaven" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.totaleUitgaven" caption="Totale uitgaven" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.brutoInkomen" caption="Bruto inkomen" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.inkomstenbelasting" caption="Inkomstenbelasting" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.vrh" caption="VRH" />
      <CheckBox name="kolommenInHetNettoBesteedbaarInkomenOverzicht.nettpOnkomsten" caption="Netto inkomsten" />
      <CheckBox
        name="kolommenInHetNettoBesteedbaarInkomenOverzicht.nettoBesteedbaarInkomen"
        caption="Netto besteedbaar inkomen"
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  KolommenInHetNettoBesteedbaarInkomenOverzicht.displayName = "KolommenInHetNettoBesteedbaarInkomenOverzicht";
