/* istanbul ignore file*/

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { DocumentenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { Documenten } from "./documenten";
import { mapDlTargetToDocumentenUiField } from "./infra/map-documenten-dl-target-to-ui-field";
import { mapDocumentenDlToUi } from "./infra/map-documenten-dl-to-ui";
import { mapDocumentenUiToDl } from "./infra/map-documenten-ui-to-dl";

const DocumentenAjaxComponent = (): ReactElement => {
  const { settings, user, params, requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/Documenten`;
  const { loading, error, data } = useAbortableFetch<DocumentenOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapDocumentenDlToUi("", data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Documenten
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapDocumentenUiToDl,
        mapDlTargetToDocumentenUiField
      )}
      {...mappedProps}
    />
  );
};

export const DocumentenAjax = withErrorBoundary(DocumentenAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") DocumentenAjax.displayName = "DocumentenAjax";
