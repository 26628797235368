import React, { ReactElement } from "react";
import { connect } from "formik";
import { AdviesonderwerpenState } from "../infra";
import { CheckBox } from "adviesbox-shared";

const Menuonderdelen = (): ReactElement => {
  return (
    <div className="px-1">
      <p className="p-3">
        Als u het selectievakje bij een menu onderdeel uitzet komt deze niet meer terug onder &apos;huidige
        situatie&apos; en &apos;voorstel&apos;. Let op, als u dit uitzet kunt u de productsoorten ook niet meer
        inventariseren en berekenen.
      </p>
      <div>
        <CheckBox caption="Hypotheek" name="menuonderdelen.hypotheek" disabled={true} />
        <CheckBox caption="Kapitaalverzekering" name="menuonderdelen.kapitaalVerzekering" />
        <CheckBox caption="ORV" name="menuonderdelen.ORV" />
        <CheckBox caption="AOV" name="menuonderdelen.AOV" />
        <CheckBox caption="Vermogen" name="menuonderdelen.vermogen" />
        <CheckBox caption="Krediet" name="menuonderdelen.krediet" />
        <CheckBox caption="Uitkerende lijfrente" name="menuonderdelen.uitkerendeLijfrente" />
        <CheckBox caption="Overig" name="menuonderdelen.overig" />
      </div>
    </div>
  );
};

export default connect<{}, AdviesonderwerpenState>(Menuonderdelen);
