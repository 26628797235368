import {
  geldverstrekkersSchema,
  GeldverstrekkersState,
  beschikbareGeldverstrekkersPartijen,
  hypotheekLabelSchema,
  beschikbareGeldverstrekkersServiceProviders,
  beschikbareSoftwareKoppelingen
} from "./geldverstrekkers-schema";
import {
  GeldverstrekkersOutput,
  Geldverstrekkers,
  Hypotheeklabel,
  GeldverstrekkerPartij,
  ServiceproviderReadOnly,
  SoftwareKoppelingReadOnly
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { prefixWithZero } from "../../shared/utils/helpers";

const getAanvraagNaar = (geldverstrekkerPartij: GeldverstrekkerPartij): string | null => {
  if (geldverstrekkerPartij.serviceproviderId) return `Serviceprovider**${geldverstrekkerPartij.serviceproviderId}`
  else if (geldverstrekkerPartij.softwareKoppelingId) return `SoftwareKoppeling**${geldverstrekkerPartij.softwareKoppelingId}`
  return null;
}

const mapHypotheekLabel = createMapToUi(hypotheekLabelSchema).from<Hypotheeklabel>({
  code: v => v.code,
  hypotheekopties: v => v.hypotheekopties,
  omschrijving: v => v.omschrijving
});

const mapPartijen = createMapToUi(beschikbareGeldverstrekkersPartijen).from<GeldverstrekkerPartij>({
  aanvullendeRetourwijze: v => prefixWithZero(v.aanvullendeRetourwijze),
  actief: v => v.actief,
  agentnummer: v => v.agentnummer,
  code: v => v.code,
  hypotheeklabels: v => v.hypotheeklabels?.map(c => mapHypotheekLabel(c)),
  partijnaam: v => v.partijnaam,
  serviceproviderId: v => v.serviceproviderId,
  softwareKoppelingId: v => v.softwareKoppelingId,
  aanvraagNaar: v => getAanvraagNaar(v)
});

const mapServiceProvider = createMapToUi(beschikbareGeldverstrekkersServiceProviders).from<ServiceproviderReadOnly>({
  naam: v => v.naam,
  serviceproviderId: v => v.serviceproviderId
});

const mapSoftwareKoppeling = createMapToUi(beschikbareSoftwareKoppelingen).from<SoftwareKoppelingReadOnly>({
  naam: v => v.naam,
  softwareKoppelingId: v => v.softwareKoppelingId
});

const mapGeldverstrekkers = createMapToUi(geldverstrekkersSchema).from<Geldverstrekkers>({
  beschikbareGeldverstrekkers: v => ({
    partijen: v.partijen?.map(c => mapPartijen(c)) || [],
    serviceProviders:
      v.serviceproviders
        ?.map(c => mapServiceProvider(c))
        .sort((a: any, b: any): number => {
          return new Intl.Collator(["NL"]).compare(a.naam || "", b.naam || "");
        }) || [],
    softwareKoppelingen:
        v.softwareKoppelingen
          ?.map(c => mapSoftwareKoppeling(c))
          .sort((a: any, b: any): number => {
            return new Intl.Collator(["NL"]).compare(a.naam || "", b.naam || "");
          }) || []
  })
});

function dl2ui(values: Geldverstrekkers): GeldverstrekkersState {
  const geldverstrekkers = mapGeldverstrekkers(values);

  return geldverstrekkersSchema.cast(geldverstrekkers);
}

export function mapGeldverstrekkersDlToUi(data: GeldverstrekkersOutput | null): GeldverstrekkersState | null {
  const geldverstrekkers = data ? data.geldverstrekkers : null;

  if (!geldverstrekkers) {
    return null;
  }

  return dl2ui(geldverstrekkers);
}
