import { ControleGewenstInkomenOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { UitgangspuntenToetsingType } from "./uitgangspunten-schema";

export const syncScenarioMinimaalUitgangspuntSideEffects = createISWSideEffect<UitgangspuntenToetsingType>(
  (bag): void => {
    const { has, draft } = bag;

    if (has.controleGewenstInkomen.controleOpGewenstInkomen.changed) {
      if (draft.controleGewenstInkomen.controleOpGewenstInkomen === ControleGewenstInkomenOptions.NettoBesteedbaarInkomen) {
        draft.controleGewenstInkomen.gewijzigdeJarenTonen = true;
        draft.controleGewenstInkomen.benodigdKapitaalTonen = false;
      } else if (
        draft.controleGewenstInkomen.controleOpGewenstInkomen === ControleGewenstInkomenOptions.Geen ||
        draft.controleGewenstInkomen.controleOpGewenstInkomen === ControleGewenstInkomenOptions.BrutoInkomenswens
      ) {
        draft.controleGewenstInkomen.gewijzigdeJarenTonen = null;
        draft.controleGewenstInkomen.benodigdKapitaalTonen = null;
      }
    }

    if (
      has.controleHypotheeknorm.controleMaandlast.changed ||
      has.controleHypotheeknorm.controleerMaximaleHypotheek.changed ||
      has.controleGewenstInkomen.controleOpGewenstInkomen.changed
    ) {
      /* istanbul ignore next */
      if (
        !draft.controleHypotheeknorm?.controleMaandlast &&
        !draft.controleHypotheeknorm?.controleerMaximaleHypotheek &&
        draft.controleGewenstInkomen?.controleOpGewenstInkomen === "Geen"
      ) {
        draft.controleGewenstInkomen.controleOpGewenstInkomen = ControleGewenstInkomenOptions.NettoBesteedbaarInkomen;
      }
    }

    if (draft.controleGewenstInkomen.controleOpGewenstInkomen === ControleGewenstInkomenOptions.NettoBesteedbaarInkomen &&
      (has.controleGewenstInkomen.gewijzigdeJarenTonen.changed || has.controleGewenstInkomen.benodigdKapitaalTonen.changed) && draft.controleGewenstInkomen.gewijzigdeJarenTonen === false && draft.controleGewenstInkomen.benodigdKapitaalTonen === false) {
      draft.controleGewenstInkomen.gewijzigdeJarenTonen = true;
      draft.controleGewenstInkomen.benodigdKapitaalTonen = false;
    }
  }
);
