import React, { ReactElement, ChangeEvent, RefObject, useRef } from "react";
import { FormikContextType } from "formik";
import { Column, CellInfo } from "react-table-6";
import { VermogensbeheerdersState } from "../infra";
import { CheckBox } from "adviesbox-shared";
import classes from "./beschikbarebeheerders.module.scss";
import classnames from "classnames";

export const EmptyComponent = (): null => null;

const createSpanWithId = (c: CellInfo): ReactElement => {
  return <span id={`${c.index}-row-partijnaam-bank-vermogensbeheerders`}>{c.original.partijnaam}</span>;
};

export const createActiveCheckboxCell = (c: CellInfo): ReactElement => {
  return (
    <div className={classes.clickable_label_fix}>
      <CheckBox
        name={`beschikbarebeheerders.partijen[${c.index}].actief`}
        caption=""
        disabled={c.original.code === "XX"}
      />
    </div>
  );
};

export const createTextInputAgentNummerCell = (
  formik: FormikContextType<VermogensbeheerdersState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;
  const inputElement: RefObject<HTMLInputElement> | null = useRef(null);

  const onMouseUp = (): void => {
    inputElement.current?.focus();
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`beschikbarebeheerders.partijen[${c.index}].agentnummer`, e.target.value);
  };

  return (
    <div className="input-group">
      <input
        data-testid={`agentnummer-input-${c.index}`}
        id={`agentnummer-input-${c.index}`}
        type="text"
        // ../../shared/components/tekst-input/TextInput.module.scss: input_control
        className={classnames("input_control", "grid-cell")}
        defaultValue={values.beschikbarebeheerders.partijen[c.index].agentnummer || ""}
        onBlur={onBlur}
        ref={inputElement}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

export const getColumns = (formik: FormikContextType<VermogensbeheerdersState>): Column[] => {
  return [
    {
      Header: "Partijnaam",
      Cell: createSpanWithId
    },
    {
      Header: "Actief",
      Cell: createActiveCheckboxCell,
      maxWidth: 100
    },
    {
      Header: "Agentnummer",
      Cell: createTextInputAgentNummerCell(formik)
    }
  ];
};
