import { InferType } from "yup";
import * as Yup from "yup";

export const beschikbareServiceprovidersSchema = Yup.object({
  partijen: Yup.array(
    Yup.object({
      serviceproviderId: Yup.string().nullable(),
      wijzigbaar: Yup.boolean(),
      naam: Yup.string(),
      actief: Yup.boolean(),
      hdnNummer: Yup.string().nullable()
    })
  ).default([])
});

export const serviceprovidersSchema = Yup.object({
  beschikbareServiceproviders: beschikbareServiceprovidersSchema
});

export type ServiceprovidersState = InferType<typeof serviceprovidersSchema>;
export type ServiceprovidersProps = InferType<typeof serviceprovidersSchema>;

export type BeschikbareServiceprovidersType = InferType<typeof beschikbareServiceprovidersSchema>;
