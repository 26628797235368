import * as Yup from "yup";
import { InferType } from "yup";
import {
  VastBedragBedragPerUurOptions,
  VastBedragOptions,
  VastBedragPercentageOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { yupEnum } from "../../shared/utils/yup-enum";

export const financieringsopzetSchema = Yup.object({
  leveringsAkte: Yup.mixed<VastBedragOptions | VastBedragBedragPerUurOptions | VastBedragPercentageOptions>().default(
    VastBedragOptions.VastBedrag
  ),
  bedragLeveringsAkte: Yup.number()
    .nullable()
    .default(null),
  hypotheekAkte: Yup.mixed<VastBedragOptions | VastBedragBedragPerUurOptions | VastBedragPercentageOptions>().default(
    VastBedragOptions.VastBedrag
  ),
  bedragHypotheekAkte: Yup.number()
    .nullable()
    .default(null),
  taxatieKosten: Yup.mixed<VastBedragOptions | VastBedragBedragPerUurOptions | VastBedragPercentageOptions>().default(
    VastBedragOptions.VastBedrag
  ),
  bedragTaxatieKosten: Yup.number()
    .nullable()
    .default(null),
  hypothecaireInschrijving: Yup.mixed<
    VastBedragOptions | VastBedragBedragPerUurOptions | VastBedragPercentageOptions
  >().default(VastBedragPercentageOptions.IndicatieveWaarde),
  bedragHypothecaireInschrijving: Yup.number()
    .nullable()
    .default(null),
  percentageHypothecaireInschrijving: Yup.number()
    .nullable()
    .default(null)
});

export const advieskostenZonderHypotheekSchema = Yup.object({
  adviesKosten: yupEnum(VastBedragBedragPerUurOptions).default(VastBedragBedragPerUurOptions.VastBedrag),
  bedragAdviesKosten: Yup.number()
    .nullable()
    .default(null),
  bemiddelingsVergoeding: yupEnum(VastBedragBedragPerUurOptions).default(VastBedragBedragPerUurOptions.VastBedrag),
  bedragBemiddelingsVergoeding: Yup.number()
    .nullable()
    .default(null)
});

export const advieskostenMetHypotheekSchema = Yup.object({
  adviesKosten: yupEnum(VastBedragBedragPerUurOptions).default(VastBedragBedragPerUurOptions.VastBedrag),
  bedragAdviesKosten: Yup.number()
    .nullable()
    .default(null),
  bemiddelingsVergoeding: yupEnum(VastBedragBedragPerUurOptions).default(VastBedragBedragPerUurOptions.VastBedrag),
  bedragBemiddelingsVergoeding: Yup.number()
    .nullable()
    .default(null),
  overigeBemiddelingsVergoeding: yupEnum(VastBedragBedragPerUurOptions).default(
    VastBedragBedragPerUurOptions.VastBedrag
  ),
  bedragOverigeBemiddelingsVergoeding: Yup.number()
    .nullable()
    .default(null),
  dossierVergoeding: Yup.number()
    .nullable()
    .default(null)
});

export const tarievenSchema = Yup.object({
  financieringsopzet: financieringsopzetSchema,
  advieskostenMetHypotheek: advieskostenMetHypotheekSchema,
  advieskostenZonderHypotheek: advieskostenZonderHypotheekSchema
});

export type TarievenState = InferType<typeof tarievenSchema>;
export type TarievenProps = InferType<typeof tarievenSchema>;

export type FinancieringsopzetType = InferType<typeof financieringsopzetSchema>;

export type AdvieskostenMetHypotheekType = InferType<typeof advieskostenMetHypotheekSchema>;

export type AdvieskostenZonderHypotheekType = InferType<typeof advieskostenZonderHypotheekSchema>;
