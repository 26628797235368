import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import { GeslachtOpties } from "../../.generated/forms/formstypes";
import { AdviesBoxFormik, LabeledTextInput, LabeledRadioInput } from "adviesbox-shared";
import { AdresInput } from "../../shared/components/adres/adres-input";
import Modal from "../../shared/components/modal/Modal";
import { enum2options } from "../../shared/utils/enum-to-options";
import { PartijType, partijSchema } from "../infra/notarissen-schema";

export const NotariskantoorToevoegenModal = ({
  closeModal,
  onSave,
  data
}: {
  data: PartijType;
  closeModal?: () => {};
  onSave?: (data: PartijType) => void;
}): ReactElement => (
  <AdviesBoxFormik<PartijType>
    initialValues={{ ...data }}
    validationSchema={partijSchema}
    onSave={onSave}
    closeModal={closeModal}
    render={({ submitForm }: FormikProps<PartijType>): ReactElement => (
      <Modal
        title="Notaris toevoegen"
        onSubmitClick={submitForm}
        onCancelClick={closeModal}
        body={
          <>
            <div>
              <LabeledTextInput name="kantoornaam" fullWidth={true} caption="Kantoornaam" />
            </div>
            <h2>Adres</h2>
            <div>
              <AdresInput name="adres" plaatsCaption="Woonplaats" metLand={false} />
            </div>
            <h2>Contactpersoon</h2>
            <div>
              <LabeledRadioInput
                caption="Geslacht"
                name="contactpersoon.geslacht"
                options={enum2options(GeslachtOpties)}
              />
              <LabeledTextInput fullWidth={false} name="contactpersoon.voorletters" caption="Voorletters" />
              <LabeledTextInput fullWidth={true} name="contactpersoon.voorvoegsel" caption="Tussenvoegsel" />
              <LabeledTextInput fullWidth={true} name="contactpersoon.achternaam" caption="Achternaam" />
              <LabeledTextInput fullWidth={true} name="telefoonnummer" caption="Telefoonnummer" />
            </div>
          </>
        }
      />
    )}
  />
);
