import { DataGrid, LabeledNumberInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { AoScenarioOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { UitgangspuntenState } from "../infra";
import ControleNormEnGewenstInkomen from "../infra/controle-norm-gewenst-inkomen";
import { syncScenarioMinimaalUitgangspuntSideEffects } from "../infra/determine-sync-inkomensuitgangspunt";
import { EmptyComponent, getColumns } from "./arbeidsongeschiktheid-data-grid-components";

const Arbeidsongeschiktheid = ({ formik }: { formik: FormikContextType<UitgangspuntenState> }): ReactElement => {
  const { values, setFieldValue } = formik;
  const arbeidsongeschiktheid = values.arbeidsongeschiktheid;

  const addScenario = (index: number): void => {
    const curr = arbeidsongeschiktheid.scenarios;
    if (index === 1) {
      curr?.push({
        scenario: AoScenarioOptions.Extra1,
        aoPercentage: 50,
        duurzaamAo: false,
        verdiencapaciteitPercentage: 50
      });
    }
    if (index === 2) {
      curr?.push({
        scenario: AoScenarioOptions.Extra2,
        aoPercentage: 50,
        duurzaamAo: false,
        verdiencapaciteitPercentage: 50
      });
    }
    setFieldValue("arbeidsongeschiktheid.scenarios", curr);
  };

  return (
    <>
      <ISWSideEffects
        sync={initISWSideEffect<UitgangspuntenState>(
          /* istanbul ignore next */ ({ subset }) => {
            syncScenarioMinimaalUitgangspuntSideEffects(subset.arbeidsongeschiktheid.create());
          }
        )()}
      />
      <ControleNormEnGewenstInkomen formik={formik} namePrefix="arbeidsongeschiktheid" />
      <LabeledNumberInput
        caption="Toetsjaar tekort"
        tooltip={
          "Het toetsjaar is het jaar vanaf passeer(ingangs)datum, waarbij het jaar van passeren(ingaan) meetelt als jaar 1."
        }
        name="arbeidsongeschiktheid.toetsjaarTekort"
      ></LabeledNumberInput>

      <DataGrid
        name="arbeidsongeschiktheid.scenarios"
        editable={true}
        resizable={false}
        sortable={false}
        filterable={false}
        columns={getColumns(formik)}
        showPagination={false}
        minRows={1}
        NoDataComponent={EmptyComponent}
      />
      {arbeidsongeschiktheid.scenarios.length <= 2 && (
        <div className="button-container">
          <button
            id="add-scenario-button"
            className="btn btn-primary"
            type="button"
            onClick={() => {
              addScenario(arbeidsongeschiktheid.scenarios.length);
            }}
          >
            Scenario toevoegen
          </button>
        </div>
      )}
    </>
  );
};

export default connect<{ namePrefix: string }, UitgangspuntenState>(Arbeidsongeschiktheid);
