import { MenuWrapper } from "adviesbox-shared";
import { capitalize } from "lodash";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import { useAutorisatieNiveauData } from "../shared/hooks/use-autorisatie-niveau-data";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { getActivePageTitle } from "../shared/utils/menu-info";
import { AppDataContext } from "./appdata-context";
import { menuConfig } from "./menu-config";

type NavigationListProps = {
  collapsed: boolean;
  collapse(): void;
  isBeheerder: boolean;
};

const NavigationList = ({
  collapsed,
  collapse,
  isBeheerder,
  history
}: NavigationListProps & RouteComponentProps): ReactElement => {
  const { setAutorisatieNiveau, setIsBeheerder } = useContext(AutorisatieNiveauContext);
  const { setActivePage } = useContext(AppDataContext);

  const params = useParams<RouteParams>();

  const { data: menuInfo, loading } = useAutorisatieNiveauData();

  const config = useMemo(() => {
    if (menuInfo !== null) {
      return menuConfig(menuInfo, params, isBeheerder);
    }
    return [];
  }, [isBeheerder, menuInfo, params]);

  useEffect(() => {
    setIsBeheerder(isBeheerder);

    if (!menuInfo) {
      return;
    }
    setAutorisatieNiveau(menuInfo);

    setActivePage(capitalize(getActivePageTitle(config, window.location.pathname)));

    history.listen((location): void => {
      setActivePage(capitalize(getActivePageTitle(config, location.pathname)));
    });
  }, [menuInfo, setAutorisatieNiveau, setIsBeheerder, isBeheerder, history, params, setActivePage, config]);

  return (
    <MenuWrapper
      brandLogoIcon={brandLogoIcon}
      brandCollapsedLogoIcon={brandCollapsedLogoIcon}
      collapse={collapse}
      collapsed={collapsed}
      hasMenuInfo={!!menuInfo}
      loading={loading}
      menuConfig={config}
      appName={"instellingen"}
    />
  );
};

NavigationList.displayName = "NavigationList";

export default withRouter(NavigationList);
