/* istanbul ignore file */
import { ValidatiePopup } from "adviesbox-shared";
import classNames from "classnames";
import { FormikContextType } from "formik";
import React, {
  MutableRefObject,
  ReactElement,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { WithSaveData } from "../shared/utils/save-data";
import { submit, SubmitResultTypes } from "../shared/utils/save-validation";
import classes from "./SaveButton.module.scss";
import { SaveFormContext } from "../shared/save-form/save-form-contex";

type SaveButtonProps = {
  callBack?: () => void;
  preSaveConfirmation?: () => Promise<void> | void;
  triggerPreSaveConfirmation?: boolean;
  name?: string;
  btnTekst?: string;
  initialSaveResult?: SubmitResultTypes; // Voor testen alleen
  context: FormikContextType<any> &
    WithSaveData<any> & {
      berekenSideEffectRef?: MutableRefObject<{
        asyncResult: Promise<unknown>;
      }>;
    };
};

const SaveButtonComponent = (
  props: SaveButtonProps & RouteComponentProps & any
): ReactElement | null => {
  const { version } = useContext(SaveFormContext);
  const [lastSave, setLastSave] = useState(version);
  let result: ReactElement | null = null;

  const {
    context: { saveData, dirty, isSubmitting },
    callBack,
    preSaveConfirmation,
    triggerPreSaveConfirmation,
    btnTekst,
  } = props;

  const [showValidatiePopup, setValidatiePopup] = useState(false);
  const [saveResult, setSaveResultText] = useState<SubmitResultTypes>(
    props.initialSaveResult || "default"
  );

  const handleSubmitCallback = useCallback(async () => {
    if (triggerPreSaveConfirmation) {
      preSaveConfirmation && (await preSaveConfirmation());
      return;
    }
    const validationResult = await submit(
      saveData,
      props.context,
      props.context.berekenSideEffectRef,
      callBack
    );
    setSaveResultText(validationResult);
    setValidatiePopup(
      validationResult !== "default" && validationResult !== "completed"
    );
  }, [
    triggerPreSaveConfirmation,
    preSaveConfirmation,
    saveData,
    props.context,
    callBack,
  ]);

  useEffect(() => {
    if (lastSave === version) return;
    setLastSave(version);
    handleSubmitCallback()
      .then(() => {})
      .catch(() => {});
  }, [version, lastSave, handleSubmitCallback]);

  if (isSubmitting) {
    result = (
      <span id={props.name || "save-button"} className={classes.busy}>
        Bezig met opslaan.
      </span>
    );
  } else if (dirty) {
    const isPlatformError = saveResult === "platformError";
    result = (
      <Button
        id={props.name || "save-button"}
        data-testid={props.name || "save-button"}
        className={classNames({
          [classes.btn_danger]: isPlatformError,
          [classes.default]: !isPlatformError,
        })}
        variant={isPlatformError ? "danger" : "link"}
        onClick={async (): Promise<void> => {
          // trigger het opslaan
          setLastSave(version - 1);
        }}
      >
        {isPlatformError
          ? "Opslag mislukt. Probeer nogmaals"
          : btnTekst
          ? btnTekst
          : "Wijzigingen opslaan"}
      </Button>
    );
  } else {
    result = (
      <span
        id={props.name || "save-button"}
        data-testid={props.name || "save-button"}
        className={classNames({
          [classes.no_save]: saveResult === "default",
          [classes.saved]: saveResult === "completed",
        })}
      >
        Alle gegevens zijn opgeslagen
      </span>
    );
  }

  return (
    <>
      {result}

      <ValidatiePopup
        infotekst={saveResult}
        show={showValidatiePopup}
        onHide={(): void => {
          setValidatiePopup(false);
        }}
      />
    </>
  );
};

SaveButtonComponent.displayName = "SaveButton";

export const SaveButton = withRouter(SaveButtonComponent);
