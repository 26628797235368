import {
  lastenoverzichtSchema,
  LastenoverzichtState,
  meenemenSchema,
  verrekenenSchema
} from "./lastenoverzicht-schema";
import {
  Lastenoverzicht,
  LastenoverzichtMeenemen,
  LastenoverzichtOutput,
  LastenoverzichtVerrekenen
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

const mapMeenemen = createMapToUi(meenemenSchema).from<LastenoverzichtMeenemen>({
  boeterente: v => v.boeterente,
  erfpacht: v => v.erfpacht,
  financieringskosten: v => v.financieringskosten,
  gespreideBetaling: v => v.gespreideBetaling,
  kredietlasten: v => v.kredietlasten,
  servicekosten: v => v.servicekosten
});
const mapVerrekenen = createMapToUi(verrekenenSchema).from<LastenoverzichtVerrekenen>({
  aanvangsstortingen: v => v.aanvangsstortingen,
  aflossingenHypotheek: v => v.aflossingenHypotheek,
  extraOpnames: v => v.extraOpnames,
  extraStortingen: v => v.extraStortingen,
  jaar1AlleenNieuweSituatie: v => v.jaar1AlleenNieuweSituatie,
  onttrekkingen: v => v.onttrekkingen,
  uitkeringenFinancieleDekkingen: v => v.uitkeringenFinancieleDekkingen
});

const mapLastenoverzicht = createMapToUi(lastenoverzichtSchema).from<Lastenoverzicht>({
  meenemen: v => mapMeenemen(v.meenemenInLastenoverzicht),
  verrekenen: v => mapVerrekenen(v.verrekenenInLasten)
});

export function mapLastenoverzichtDlToUi(data: LastenoverzichtOutput): LastenoverzichtState | null {
  const lastenoverzicht = data?.lastenoverzicht ?? null;

  if (!lastenoverzicht) {
    return null;
  }

  return mapLastenoverzicht(lastenoverzicht);
}
