import React, { ReactElement } from "react";
import { connect } from "formik";
import { CheckBox } from "adviesbox-shared";
import { LastenoverzichtState } from "../infra";

const Meenemen = (): ReactElement => {
  return (
    <div className="px-1">
      <div>
        <CheckBox caption="Kredietlasten" name="meenemen.kredietlasten"></CheckBox>
        <CheckBox caption="Erfpacht" name="meenemen.erfpacht"></CheckBox>
        <CheckBox caption="Servicekosten" name="meenemen.servicekosten"></CheckBox>
        <CheckBox caption="Financieringskosten" name="meenemen.financieringskosten"></CheckBox>
        <CheckBox caption="Boeterente" name="meenemen.boeterente"></CheckBox>
        <CheckBox
          caption="Gespreide betaling advieskosten en bemiddelingsvergoeding"
          name="meenemen.gespreideBetaling"
        ></CheckBox>
      </div>
    </div>
  );
};

export default connect<{}, LastenoverzichtState>(Meenemen);
