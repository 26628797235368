import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput } from "adviesbox-shared";
import { klantprofielMotiveringState } from "../infra";
import { SoortControleVolledigheidOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";

const Volledigheid = ({
  formik: {
    values: { volledigheid }
  }
}: {
  formik: FormikContextType<klantprofielMotiveringState>;
}): ReactElement => {
  const klantprofielMotiveringOptions = [
    { label: "Geen controle", value: SoortControleVolledigheidOptions.GeenControle },
    { label: "Alleen klantprofiel", value: SoortControleVolledigheidOptions.AlleenKlantprofiel },
    {
      label: "Klantprofiel en motivering",
      value: SoortControleVolledigheidOptions.KlantprofielEnMotivering
    }
  ];

  return (
    <>
      <p className="p-3">Controle op volledigheid klantprofiel en motivering bij HDN-aanvraag.</p>
      <LabeledRadioInput
        caption="Soort controle"
        name="volledigheid.soortControle"
        options={klantprofielMotiveringOptions}
      />
    </>
  );
};

export default connect<{}, klantprofielMotiveringState>(Volledigheid);
