import * as Yup from "yup";
import { InferType } from "yup";

export const beschikbareTaxatiebureausSchema = Yup.object({
  partijen: Yup.array(
    Yup.object({
      code: Yup.string().nullable(),
      partijnaam: Yup.string(),
      actief: Yup.boolean(),
      agentnummer: Yup.string().nullable()
    })
  ).default([])
});

export const taxatiebureausSchema = Yup.object({
  beschikbareTaxatiebureaus: beschikbareTaxatiebureausSchema
});

export type TaxatiebureausState = InferType<typeof taxatiebureausSchema>;
export type TaxatiebureausProps = InferType<typeof taxatiebureausSchema>;

export type BeschikbareTaxatiebureausType = InferType<typeof beschikbareTaxatiebureausSchema>;
