import React, { ReactElement, useContext } from "react";
import { FormikProps, Form } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";

import { SaveButton } from "../save-button/save-button";
import { LastenoverzichtState, LastenoverzichtProps, lastenoverzichtSchema } from "./infra";

import Meenemen from "./meenemen/meenemen";
import Verrekenen from "./verrekenen/verrekenen";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Lastenoverzicht = (
  props: FormikProps<LastenoverzichtState> & WithSaveData<LastenoverzichtState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.lastenoverzichtenNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Lastenoverzicht</h2>
            <AutorisatieNiveauManager manageFor={autorisatieNiveau.lastenoverzichtenNiveau} />
            <SaveButton context={props} />
          </div>
        </CardWrapper>
        <CardWrapper>
          <Card title="Meenemen in lastenoverzicht">
            <Meenemen />
          </Card>
          <Card title="Verrekenen in lasten">
            <Verrekenen />
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Lastenoverzicht.displayName = "Lastenoverzicht";

export default withAdviesboxFormik<LastenoverzichtProps & WithSaveData<LastenoverzichtState>, LastenoverzichtState>({
  // Transform outer props into form values
  mapPropsToValues: (e: LastenoverzichtProps): LastenoverzichtState => e,
  validationSchema: lastenoverzichtSchema
})(Lastenoverzicht);
