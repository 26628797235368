import { InferType } from "yup";
import * as Yup from "yup";
import { validate as isValidUUID } from "uuid";

export const softwareKoppelingSchema = Yup.object({
  softwareKoppelingId: Yup.string().nullable(),
  wijzigbaar: Yup.boolean(),
  naam: Yup.string(),
  actief: Yup.boolean(),
  hdnNummer: Yup.string()
    .nullable()
    .default(null),
});

export const softwareKoppelingenSchema = Yup.object({
  partijen: Yup.array(softwareKoppelingSchema).default([]),
});

export const elementsInstellingenSchema = Yup.object({
  locatiePortaalUrl: Yup.string()
    .nullable()
    .default(""),
  organisatienummer: Yup.string()
    .nullable()
    .default(""),
  gebruikersnaamApi: Yup.string()
    .nullable()
    .default(""),
  wachtwoordApi: Yup.string()
    .nullable()
    .default(""),
});

export const importkoppelingInstellingenSchema = Yup.object({
  koppelingKey: Yup.string()
    .nullable()
    .default(null)
    .test({
      message: "Ongeldige GUID",
      test: (value: string | null) => value === null || isValidUUID(value),
    }),
  koppelingNaam: Yup.string()
    .nullable()
    .default(""),
});

export type SoftwareKoppelingenType = InferType<
  typeof softwareKoppelingenSchema
>;
export type SoftwareKoppelingType = InferType<typeof softwareKoppelingSchema>;
export type ElementsInstellingenType = InferType<
  typeof elementsInstellingenSchema
>;
export type ImportkoppelingInstellingenType = InferType<
  typeof importkoppelingInstellingenSchema
>;
