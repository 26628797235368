import { CheckBox } from "adviesbox-shared";
import classnames from "classnames";
import { FormikContextType } from "formik";
import React, { ChangeEvent, ReactElement, RefObject, useRef } from "react";
import { CellInfo, Column } from "react-table-6";
import { VerzekeraarsState } from "../infra";
import classes from "./beschikbare-verzekeraars.module.scss";
import { AanvraagType } from "../../geldverstrekkers/infra";
import { LabelValuePairs } from "adviesbox-shared/utils/types";

export const EmptyComponent = (): null => null;

const createSpanWithId = (c: CellInfo): ReactElement => {
  return <span id={`${c.index}-row-partijnaam-verzekeraars`}>{c.original.partijnaam}</span>;
};

const getAanvraagNaarOptions = (values: VerzekeraarsState): LabelValuePairs => {
  const aanvraagNaarOptions: LabelValuePairs = [];
  values.beschikbareVerzekeraars.serviceProviders.forEach(serviceprovider => {
    aanvraagNaarOptions.push({
      label: `${serviceprovider.naam}`,
      value: `${AanvraagType.Serviceprovider}**${serviceprovider.serviceproviderId}`
    });
  });
  values.beschikbareVerzekeraars.softwareKoppelingen.forEach(softwareKoppeling => {
    aanvraagNaarOptions.push({
      label: `${softwareKoppeling.naam}`,
      value: `${AanvraagType.SoftwareKoppeling}**${softwareKoppeling.softwareKoppelingId}`
    });
  });

  aanvraagNaarOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
  return aanvraagNaarOptions;
};

export const createAanvraagNaarSelectInput = (
  formik: FormikContextType<VerzekeraarsState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { setFieldValue, values } = formik;
  const setAanvraagNaar = (event: ChangeEvent<HTMLSelectElement>): void => {
    const val = event.target.value === "null" ? null : event.target.value;
    const [aanvraagType, aanvraagId] = event.target.value.split("**");
    const isServiceprovider = aanvraagType === AanvraagType.Serviceprovider;
    setFieldValue(`beschikbareVerzekeraars.partijen[${c.index}].aanvraagNaar`, val);

    if (isServiceprovider) {
      setFieldValue(`beschikbareVerzekeraars.partijen[${c.index}].serviceproviderId`, aanvraagId);
      setFieldValue(`beschikbareVerzekeraars.partijen[${c.index}].softwareKoppelingId`, null);
    } else {
      setFieldValue(`beschikbareVerzekeraars.partijen[${c.index}].serviceproviderId`, null);
      setFieldValue(`beschikbareVerzekeraars.partijen[${c.index}].softwareKoppelingId`, aanvraagId);
    }
  };

  const aanvraagNaarOptions = getAanvraagNaarOptions(values);

  return (
    <div className="input-group">
      <select
        id={`aanvraag-naar-select-${c.index}`}
        data-testid={`aanvraag-naar-select-${c.index}`}
        onChange={setAanvraagNaar}
        defaultValue={c.original.aanvraagNaar}
        // ../../shared/components/select-input/SelectInput.module.scss: input_control
        className={classnames("input_control", "grid-cell")}
      >
        <option value={"null"}>Geen</option>
        {aanvraagNaarOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const createTextInputAgentNummerCell = (
  formik: FormikContextType<VerzekeraarsState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;
  const inputElement: RefObject<HTMLInputElement> | null = useRef(null);

  const onMouseUp = (): void => {
    inputElement.current?.focus();
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`beschikbareVerzekeraars.partijen[${c.index}].agentnummer`, e.target.value);
  };

  return (
    <div className="input-group">
      <input
        data-testid={`agentnummer-input-${c.index}`}
        id={`agentnummer-input-${c.index}`}
        // ../../shared/components/tekst-input/TextInput.module.scss: input_control
        className={classnames("input_control", "grid-cell")}
        type="text"
        defaultValue={values.beschikbareVerzekeraars.partijen[c.index].agentnummer || ""}
        onBlur={onBlur}
        ref={inputElement}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

export const createActiveCheckboxCell = (c: CellInfo): ReactElement => {
  return (
    <div className={classes.clickable_label_fix}>
      <CheckBox
        name={`beschikbareVerzekeraars.partijen[${c.index}].actief`}
        caption=""
        disabled={c.original.code === "XX"}
      />
    </div>
  );
};

export const getColumns = (formik: FormikContextType<VerzekeraarsState>): Column[] => {
  return [
    {
      minWidth: 200,
      Header: "Partijnaam",
      Cell: createSpanWithId
    },
    {
      width: 100,
      Header: "Actief",
      id: "active",
      Cell: createActiveCheckboxCell
    },
    {
      width: 100,
      Header: "Agentnummer",
      id: "agentNumber",
      Cell: createTextInputAgentNummerCell(formik)
    },
    {
      minWidth: 200,
      Header: "Productaanvraag naar",
      id: "provider",
      Cell: createAanvraagNaarSelectInput(formik)
    }
  ];
};
