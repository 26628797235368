/* istanbul ignore file */
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import { RapportageOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";

export function mapRapportageSamenstellenDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<RapportageOutput> = {};

  return target2field(map, target);
}
