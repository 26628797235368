import { InferType } from "yup";
import * as Yup from "yup";

export const beschikbarebeheerdersSchema = Yup.object({
  partijen: Yup.array(
    Yup.object({
      code: Yup.string(),
      partijnaam: Yup.string(),
      actief: Yup.boolean(),
      agentnummer: Yup.string().nullable()
    })
  ).default([])
});

export const vermogensbeheerdersSchema = Yup.object({
  beschikbarebeheerders: beschikbarebeheerdersSchema
});

export type VermogensbeheerdersState = InferType<typeof vermogensbeheerdersSchema>;
export type VermogensbeheerdersProps = InferType<typeof vermogensbeheerdersSchema>;
export type BeschikbarebeheerdersType = InferType<typeof beschikbarebeheerdersSchema>;
