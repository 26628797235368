/* istanbul ignore file */
// TODO remove istanbul ignore, after the end points for kantoorgegevens are fixed
import { AdviseurModel, BemiddelaarModel } from "../../.generated/bemiddeling/bemiddelingtypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
// import { DossierOutput as DossierdlOutput } from "../../.generated/bemiddeling/bemiddelingtypes";
import {
  bemiddelaarSchema,
  coupledAdviseurSchema,
  BemiddelaarSchemaType,
  CoupledAdviseurSchemaType,
  koppelingenSchema
} from "./koppeling-schema";
// TODO to fix enum types in bemiddeling types and map them

const mapAdviseur = createMapToDl(coupledAdviseurSchema).to<AdviseurModel>({
  adviseurId: v => v.adviseurId || null,
  naam: v => v.naam || null,
  coupledBemiddelaarId: v => v.coupledBemiddelaarId,
  emailadres: _ => null
});

const mapBemiddelaar = createMapToDl(bemiddelaarSchema).to<BemiddelaarModel>({
  bemiddelaarId: v => v.bemiddelaarId || null,
  naam: v => v.naam || null,
  emailadres: _ => null
});

export const Ui2dlKoppeling = createMapToDl(koppelingenSchema).to<any>({
  bemiddelaars: v =>
    v.bemiddelaars ? v.bemiddelaars.map((bemiddelaar: BemiddelaarSchemaType) => mapBemiddelaar(bemiddelaar)) : [],
  adviseurs: v => (v.adviseurs ? v.adviseurs.map((adviseur: CoupledAdviseurSchemaType) => mapAdviseur(adviseur)) : []),
  koppelingen: v =>
    v.adviseurs
      .filter((adviseur: CoupledAdviseurSchemaType) => adviseur.coupledBemiddelaarId)
      .map((adviseur: CoupledAdviseurSchemaType) => adviseur)
});
