/* istanbul ignore file */

import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getAdviesonderwerpenResources = createGetTextResources({
  MenuonderdelenWarning:
    "Als menuonderdelen uitgeschakeld worden, dan zullen de gegevens die deze menuonderdelen bevatten verwijderd worden als je het dossier opent.",
  OnderwerpenWaring:
    "Als adviesonderwerpen uitgeschakeld worden, dan zullen de gegevens die deze adviesonderwerpen bevatten verwijderd worden als je het dossier opent.",
  OnderwerpenEnMenuonderdelenWaring:
    "Als menuonderdelen en adviesonderwerpen uitgeschakeld worden, dan zullen de gegevens die deze menuonderdelen en adviesonderwerpen bevatten verwijderd worden als je het dossier opent."
});
