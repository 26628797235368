/* istanbul ignore file */

import { useRequestInit } from "adviesbox-shared";
import classnames from "classnames";
import React, { ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { ApplicationLogo } from "../application-logo/ApplicationLogo";
import { AppDataProvider } from "../navigation/appdata-context";
import NavigationList from "../navigation/NavigationList";
import {
  GebruikersgroepenOutput,
  MedewerkerOrganisatieOutput,
  VestigingenOutput
} from "../.generated/licenties/licentiestypes";
import UserDetailsContext from "../user-details/user-details-context";
import VestigingenContext, { vestiging } from "../vestigingen-context/vestigingen-context";
import { SaveFormProvider } from "../shared/save-form/save-form-provider";

type DrawerProps = {
  children: ReactNode;
};

const Drawer = (props: DrawerProps): ReactElement => {
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const { setVestigingen } = useContext(VestigingenContext);
  const { children } = props;
  const [collapsed, setcollapsed] = useState(false);
  const { requestInit, settings, user } = useRequestInit();

  if (!user) throw Error("user is not signed in");
  const medewerkerUrl = `${settings.licentiesOrigin}/Medewerkers/current`;
  const medewerkerByEmail = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);
  const gebruiksGroepenUrl =
    medewerkerByEmail.data && typeof medewerkerByEmail.data !== "string" && medewerkerByEmail.data.medewerkerId
      ? `${settings.licentiesOrigin}/Medewerkers/${medewerkerByEmail.data.medewerkerId}/Gebruikersgroepen`
      : null;
  const vestigingenUrl =
    medewerkerByEmail.data && typeof medewerkerByEmail.data !== "string" && medewerkerByEmail.data.medewerkerId
      ? `${settings.licentiesOrigin}/medewerkers/${medewerkerByEmail.data?.medewerkerId}/vestigingen`
      : null;
  const gebruikersGroepen = useAbortableFetch<GebruikersgroepenOutput>(gebruiksGroepenUrl, requestInit);
  const vestigingen = useAbortableFetch<VestigingenOutput>(vestigingenUrl, requestInit);
  const isBeheerder =
    gebruikersGroepen.data &&
    typeof gebruikersGroepen.data !== "string" &&
    gebruikersGroepen.data.gebruikersgroepen &&
    gebruikersGroepen.data.isValid
      ? Object.values(gebruikersGroepen.data.gebruikersgroepen).some((groep): boolean => groep.naam === "Beheerder")
      : null;

  useEffect(() => {
    const medewerkerVestigingen =
      vestigingen.data && typeof vestigingen.data !== "string" && vestigingen.data.vestigingen
        ? Object.keys(vestigingen.data.vestigingen).map(key => {
            if (!vestigingen.data || typeof vestigingen.data === "string" || !vestigingen.data.vestigingen)
              throw Error("Found invalid data");
            return {
              ...vestigingen.data.vestigingen[key],
              vestigingId: key
            };
          })
        : [];

    setVestigingen(medewerkerVestigingen as vestiging[]);
  }, [setVestigingen, vestigingen.data]);

  useEffect(() => {
    if (
      !medewerkerByEmail ||
      !medewerkerByEmail.data ||
      typeof medewerkerByEmail.data === "string" ||
      !medewerkerByEmail.data.organisatieId ||
      !medewerkerByEmail.data.medewerkerId ||
      !medewerkerByEmail.data.medewerker ||
      userDetails.medewerkerId === medewerkerByEmail.data.medewerkerId
    )
      return;

    setUserDetails({
      ...medewerkerByEmail.data.medewerker,
      medewerkerId: medewerkerByEmail.data.medewerkerId,
      organisatieId: medewerkerByEmail.data.organisatieId
    });
  });

  if (isBeheerder === null) {
    return <ApplicationLogo />;
  }

  return (
    <div className="drawer-wrapper">
      <SaveFormProvider>
        <AppDataProvider>
          <div className={classnames("drawer-panel", { collapsed })}>
            <NavigationList
              isBeheerder={isBeheerder}
              collapsed={collapsed}
              collapse={(): void => setcollapsed(!collapsed)}
            />
          </div>
          <div className="drawer-content">{children}</div>
        </AppDataProvider>
      </SaveFormProvider>
    </div>
  );
};

Drawer.displayName = "Drawer";

export default Drawer;
