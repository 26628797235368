/* eslint-disable @typescript-eslint/camelcase */

import React, { ReactElement } from "react";
import { User } from "oidc-client";
import { Button } from "react-bootstrap";

export const AccessTokenDownload = ({ user, document }: { user: User; document: Document }): ReactElement => {
  const handleDownload = ():void => {
    // Create a JSON object with the access token
    const jsonContent = {
      access_token: user.access_token,
      expires_at: user.expires_at,
      user_name: user.profile.name,
    };

    // Convert the JSON object to a string
    const jsonString = JSON.stringify(jsonContent, null, 2);

    // Create a Blob with the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // Set the filename for the downloaded file
    link.download = "koppeling-adviesbox.json";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return <Button className={"primary"} onClick={handleDownload}>Download bestand</Button>;
};
