/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { TarievenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToTarievenUiField, mapTarievenDlToUi, mapTarievenUiToDl } from "./infra";
import Tarieven from "./tarieven";

const TarievenAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData
  const { settings, user, requestInit, params } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/Tarieven`;
  const { loading, error, data } = useAbortableFetch<TarievenOutput>(url, requestInit);
  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapTarievenDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Tarieven
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapTarievenUiToDl,
        mapDlTargetToTarievenUiField
      )}
      {...mappedProps}
    />
  );
};

TarievenAjax.displayName = "TarievenAjax";

export default withErrorBoundary(TarievenAjax);
