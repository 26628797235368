import { Schema } from "yup";

type MapIt<S, D, T> = {
  [P in keyof D]-?: Exclude<T, undefined> extends never
    ? (from: S) => D[P] | null | undefined
    : (from: S, extra: T) => D[P] | null | undefined;
};
type Mapper<S, D, T> = Exclude<T, undefined> extends never
  ? (source: S | null | undefined) => D
  : (source: S | null | undefined, extra: T) => D;

type CreateMapToUiReturnType<D> = {
  from: <S = never, T = undefined>(map: MapIt<Exclude<S, null | undefined>, D, T>) => Mapper<S, D, T>;
};
export const createMapToUi = <D>(schema: Schema<D>): CreateMapToUiReturnType<D> => ({
  from: <S = never, T = undefined>(map: MapIt<Exclude<S, null | undefined>, D, T>): Mapper<S, D, T> => {
    const mapper = (source: S | null | undefined, extra: T): D => {
      const def = schema.default();
      if (typeof source === "undefined" || source === null) {
        return def;
      }

      const keys = Object.keys(map) as (keyof D)[];
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        const val = map[prop](source as Exclude<S, null | undefined>, extra);
        if (typeof val !== "undefined" && val !== null) {
          def[prop] = val;
        }
      }

      return def;
    };

    return mapper as Mapper<S, D, T>;
  }
});
