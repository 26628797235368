import { FileUpload } from "adviesbox-shared";
import React, { ReactElement, useRef } from "react";
import { Button } from "react-bootstrap";
import Modal from "../shared/components/modal/Modal";

export type StandaardTekstenExporterenModalProps = {
  closeModal?: () => void;
  saveModal: (files: File[], overschrijven: boolean) => void;
};

export const StandaardTekstenImporterenModal = (props: StandaardTekstenExporterenModalProps): ReactElement => {
  const filesRef = useRef<File[]>([]);

  const saveAction = (overschrijven: boolean): void => {
    props.saveModal(filesRef.current, overschrijven);
    props.closeModal && props.closeModal();
  };

  return (
    <Modal
      title="Standaardteksen importeren"
      saveButtonTekst={"Teksten overschrijven"}
      onCancelClick={props.closeModal}
      appendToFooter={
        <Button
          id="btntoevoegen"
          data-testid="btntoevoegen"
          onClick={() => {
            saveAction(false);
          }}
        >
          Teksten toevoegen
        </Button>
      }
      onSubmitClick={() => {
        saveAction(true);
      }}
      body={
        <>
          <FileUpload
            name="standaardtekstImporteren"
            variant={"Standaardteksten"}
            updateRef={
              /* istanbul ignore next */ files => {
                filesRef.current = files;
              }
            }
            title={
              <span>
                Sleep het json bestand hierheen om te uploaden
                <br />
                of
                <br />
              </span>
            }
            buttonText={"Klik hier om een bestand te kiezen"}
            hideFormats
            hideIcon
            filesref={filesRef}
          ></FileUpload>
          <div className="form-group form-row">
            <div className={`col-12`}>
              <p className="px-2">
                <br />
                Wilt u de te importeren teksten toevoegen aan de huidige teksten of de huidige teksten overschrijven?
              </p>
            </div>
          </div>
        </>
      }
    />
  );
};
