import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { TaxatiebureausState } from "../infra";
import { getColumns, EmptyComponent } from "./taxatiebureaus-data-grid-components";
import { DataGrid } from "adviesbox-shared";

const BeschikbareTaxatiebureaus = ({ formik }: { formik: FormikContextType<TaxatiebureausState> }): ReactElement => {
  const { values } = formik;

  return (
    <DataGrid
      name="beschikbareTaxatiebureaus.partijen"
      editable={true}
      resizable={false}
      sortable={false}
      filterable={false}
      columns={getColumns(formik)}
      minRows={values.beschikbareTaxatiebureaus.partijen.length || 0}
      pageSize={values.beschikbareTaxatiebureaus.partijen.length || 0}
      showPagination={false}
      NoDataComponent={EmptyComponent}
    />
  );
};

export default connect<{}, TaxatiebureausState>(BeschikbareTaxatiebureaus);
