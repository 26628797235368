import { lastenoverzichtSchema, meenemenSchema, verrekenenSchema } from "./lastenoverzicht-schema";
import {
  Lastenoverzicht,
  LastenoverzichtMeenemen,
  LastenoverzichtVerrekenen
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapMeenemen = createMapToDl(meenemenSchema).to<LastenoverzichtMeenemen>({
  boeterente: v => v.boeterente,
  erfpacht: v => v.erfpacht,
  financieringskosten: v => v.financieringskosten,
  gespreideBetaling: v => v.gespreideBetaling,
  kredietlasten: v => v.kredietlasten,
  servicekosten: v => v.servicekosten
});

const mapVerrekenen = createMapToDl(verrekenenSchema).to<LastenoverzichtVerrekenen>({
  aanvangsstortingen: v => v.aanvangsstortingen,
  aflossingenHypotheek: v => v.aflossingenHypotheek,
  extraOpnames: v => v.extraOpnames,
  extraStortingen: v => v.extraStortingen,
  jaar1AlleenNieuweSituatie: v => v.jaar1AlleenNieuweSituatie,
  onttrekkingen: v => v.onttrekkingen,
  uitkeringenFinancieleDekkingen: v => v.uitkeringenFinancieleDekkingen
});

export const mapLastenoverzichtUiToDl = createMapToDl(lastenoverzichtSchema).to<Lastenoverzicht>({
  meenemenInLastenoverzicht: v => mapMeenemen(v.meenemen),
  verrekenenInLasten: v => mapVerrekenen(v.verrekenen)
});
