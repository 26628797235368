import React, { ReactElement } from "react";
import { connect } from "formik";
import { AutorisatieNiveauState } from "../infra";

const Uitleg = (): ReactElement => {
  return (
    <div className="p-3">
      <span>
        Per Instellingen-scherm kan hier bepaald worden voor wie deze instelling geldt. Gebruikers (zonder
        beheerrechten) kunnen alleen de Instellingen met autorisatieniveau ‘Gebruiker’ zelf wijzigen.
      </span>
    </div>
  );
};

export default connect<{}, AutorisatieNiveauState>(Uitleg);
