import { SimpleModal } from "adviesbox-shared";
import React, { ReactElement, useState, useContext } from "react";
import { ButtonVariant } from "react-bootstrap/esm/types";
import Modal from "../shared/components/modal/Modal";
import { SaveButton } from "./save-button";
import { SaveFormContext } from "../shared/save-form/save-form-contex";

type ConfirmationModalProps = {
  save: () => Promise<void>;
  btnTekst?: string;
  closeModal?: () => void;
};

type SaveButtonWithConfirmProps = {
  modalBody: ReactElement;
  modalTitle: string;
  disabled?: boolean;
  saveBtnVariant?: ButtonVariant;
  saveBtnTekst?: string;
  cancelBtnTekst?: string;
};

export const ConfirmationModal = ({
  save,
  modalBody,
  modalTitle,
  saveBtnVariant,
  saveBtnTekst,
  cancelBtnTekst,
  closeModal,
}: SaveButtonWithConfirmProps & ConfirmationModalProps): ReactElement => {
  return (
    <SimpleModal closeModal={closeModal}>
      <Modal
        onSubmitClick={async () => {
          await save();
          closeModal && closeModal();
        }}
        title={modalTitle}
        body={<div className={"px-2"}>{modalBody}</div>}
        saveButtonTekst={saveBtnTekst}
        cancelButtonTekst={cancelBtnTekst}
        saveBtnVariant={saveBtnVariant}
        onCancelClick={closeModal}
      />
    </SimpleModal>
  );
};

export const SaveButtonWithConfirmation = ({
  props,
  saveBtnTekst,
  modalBody,
  modalTitle,
  disabled,
  saveBtnVariant,
  cancelBtnTekst,
}: { props: any } & SaveButtonWithConfirmProps): ReactElement => {
  const { saveData } = useContext(SaveFormContext);
  const [triggerPreSaveConfirmation, setTriggerPreSaveConfirmation] = useState(
    true
  );
  const [showWarningModal, setShowWarningModal] = React.useState(false);

  const preSaveConfirmation = (): void => {
    setShowWarningModal(true);
  };

  const save = async (): Promise<void> => {
    setShowWarningModal(false);
    setTriggerPreSaveConfirmation(false);
    saveData();
  };

  return (
    <>
      <SaveButton
        context={props}
        triggerPreSaveConfirmation={triggerPreSaveConfirmation}
        preSaveConfirmation={preSaveConfirmation}
      />
      {showWarningModal && (
        <ConfirmationModal
          save={save}
          closeModal={() => {
            setShowWarningModal(false);
            // ConfirmationModal opnieuw tonen nadat data is opgeslagen
            setTriggerPreSaveConfirmation(true);
          }}
          modalBody={modalBody}
          modalTitle={modalTitle}
          disabled={disabled}
          saveBtnVariant={saveBtnVariant}
          cancelBtnTekst={cancelBtnTekst}
          saveBtnTekst={saveBtnTekst}
        />
      )}
    </>
  );
};
