import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { GeldverstrekkersState } from "../infra";
import { getColumns } from "./geldverstrekkers-data-grid-components";
import { useHdnData } from "../../shared/components/hdn-keuzelijst/use-hdn-data";
import { DataGrid } from "adviesbox-shared";

const BeschikbareGeldverstrekkers = ({
  formik
}: {
  formik: FormikContextType<GeldverstrekkersState>;
}): ReactElement => {
  const { values } = formik;
  const hdnData = useHdnData("AX", "AanvullendeVerzendwijzeType");

  return (
    <>
      <div>
        <DataGrid
          name="beschikbareGeldverstrekkers.partijen"
          resizable={false}
          sortable={false}
          filterable={false}
          columns={getColumns(formik, hdnData)}
          minRows={values.beschikbareGeldverstrekkers.partijen.length || 0}
          showPagination={false}
          pageSize={values.beschikbareGeldverstrekkers.partijen.length || 0}
        />
      </div>
    </>
  );
};

export default connect<{}, GeldverstrekkersState>(BeschikbareGeldverstrekkers);
