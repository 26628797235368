import { LocalDateTime } from "@js-joda/core";
import {
  BeschikbareHypotheekProductenType,
  BeschikbareProductenType,
  ProductFilterType
} from "adviesbox-shared/product-filters/filter-types";
import {
  HypotheekvormBasis,
  KredietvormBasis,
  LijfrenteBasis,
  OrvBasis,
  ProductBasis,
  Vermogensvorm
} from "../../.generated/producten/productentypes";
export type AlleMogelijkeProductBasisTypes =
  | HypotheekvormBasis
  | KredietvormBasis
  | LijfrenteBasis
  | OrvBasis
  | ProductBasis
  | Vermogensvorm;

export function isProductSupported(product: ProductFilterType): boolean {
  return (
    product.ondersteunenTot === undefined || LocalDateTime.parse(product.ondersteunenTot).isAfter(LocalDateTime.now())
  );
}

//Filter vanuit de hardcoded lijst in shared, deze word gemocked om huidige tests intact te houden
export const partijFilter = (
  product: AlleMogelijkeProductBasisTypes,
  filterList: BeschikbareHypotheekProductenType[] | BeschikbareProductenType[],
  geenProductenFilteren = false
): boolean => {
  const hypotheek = filterList as BeschikbareHypotheekProductenType[];
  const producten = filterList as BeschikbareProductenType[];

  if (hypotheek[0].hypotheeklabels) {
    return (
      hypotheek.find(
        c =>
          c.code &&
          c.code === product.maatschappijCode &&
          c.actief &&
          isProductSupported(c) &&
          (geenProductenFilteren || c.hypotheeklabels.find(v => v.actief && String(v.code) === String(product.code)))
      ) !== undefined
    );
  } else if (producten[0].producten) {
    return (
      producten.find(
        k =>
          k.code === product.maatschappijCode &&
          k.actief &&
          isProductSupported(k) &&
          (geenProductenFilteren || k.producten.find(p => p.actief && p.code === product.code && isProductSupported(p)))
      ) !== undefined
    );
  }
  return false;
};
