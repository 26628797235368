import React, { ReactElement } from "react";

import { CheckBox } from "adviesbox-shared";

type KolommenInDeLastenoverzichtenProps = {};

export const KolommenInDeLastenoverzichten = (_props: KolommenInDeLastenoverzichtenProps): ReactElement => {
  return (
    <>
      <p className="p-3">
        Het aantal kolommen dat op de pagina past is afhankelijk van de afdrukstand. Bij de{" "}
        <span className="font-weight-bold text-danger">staande</span> afdrukstand passen{" "}
        <span className="font-weight-bold text-danger">maximaal 9 kolommen</span> op de pagina. In de{" "}
        <span className="font-weight-bold">liggende</span> afdrukstand{" "}
        <span className="font-weight-bold">maximaal 14 kolommen</span>. Kolommen met alleen maar nulwaarden worden niet
        getoond.
      </p>
      <CheckBox name="kolommenInDeLastenoverzichten.brutoInkomen" caption="Bruto inkomen" />
      <CheckBox name="kolommenInDeLastenoverzichten.somHypotheken" caption="Som hypotheken" />
      <CheckBox name="kolommenInDeLastenoverzichten.waardeopbouw" caption="Waardeopbouw" />
      <CheckBox name="kolommenInDeLastenoverzichten.huur" caption="Huur" />
      <CheckBox name="kolommenInDeLastenoverzichten.rente" caption="Rente" />
      <CheckBox name="kolommenInDeLastenoverzichten.hypotheekAflossing" caption="Hypotheek aflossing" />
      <CheckBox
        name="kolommenInDeLastenoverzichten.uitkeringKapitaalverzekering"
        caption="Uitkering kapitaalverzekering"
      />
      <CheckBox name="kolommenInDeLastenoverzichten.verzekeringspremies" caption="Verzekeringspremies" />
      <CheckBox name="kolommenInDeLastenoverzichten.spaarEnBeleggingsdepot" caption="Spaar- en beleggingsdepot" />
      <CheckBox name="kolommenInDeLastenoverzichten.opnameUitDepot" caption="Opname uit depot" />
      <CheckBox name="kolommenInDeLastenoverzichten.kredietLast" caption="Krediet last" />
      <CheckBox name="kolommenInDeLastenoverzichten.overigeUitgaven" caption="Overige uitgaven" />
      <CheckBox name="kolommenInDeLastenoverzichten.brutoLast" caption="Bruto last" />
      <CheckBox name="kolommenInDeLastenoverzichten.fiscaalVoordeel" caption="Fiscaal voordeel" />
      <CheckBox name="kolommenInDeLastenoverzichten.vrh" caption="VRH" />
      <CheckBox name="kolommenInDeLastenoverzichten.nettoLast" caption="Netto last" />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  KolommenInDeLastenoverzichten.displayName = "KolommenInDeLastenoverzichten";
