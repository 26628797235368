import { logErrorToApplicationInsights, PlatformData, SettingsType } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { DocumentenState } from "./infra/documenten-schema";
export const uploadFotoVanVoorbladApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  body: FormData,
  formik: FormikContextType<DocumentenState>
): Promise<boolean> => {
  const url = `${settings.instellingenFormsOrigin}/Documenten/VoorbladAfbeelding`;

  try {
    const rsp = await fetch(url, {
      method: "POST",
      body,
      headers: {
        Accept: "*/*",
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        authorization: `${user?.token_type} ${user?.access_token}`,
        vestigingId
      }
    });

    formik.setFieldValue("platformApiFouten", null);

    if (!rsp.ok) {
      /* istanbul ignore next*/
      formik.setFieldValue("platformApiFouten", [
        {
          field: "",
          label: "Op te nemen foto",
          error: "Fout bij aanroep van web-api"
        }
      ]);

      throw Error("Fout bij aanroep VoorbladAfbeelding");
    }

    return true;
  } catch (error) {
    formik.setFieldValue("platformApiFouten", [
      {
        field: "",
        label: "Op te nemen foto",
        error: "Fout bij aanroep van web-api"
      }
    ]);

    throw error;
  }
};

export const getBlobUrlApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  setVoorblad?: React.Dispatch<React.SetStateAction<string | null>>
): Promise<string | null> => {
  try {
    const url = `${settings.instellingenFormsOrigin}/Documenten/VoorbladAfbeelding`;
    const rsp = await fetch(url, {
      method: "GET",
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8",
        vestigingId
      }
    });

    const result: PlatformData & { voorbladAfbeelding: { url: string } } = await rsp.json();

    if (!rsp.ok) {
      throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
    }

    if (result.voorbladAfbeelding && result.voorbladAfbeelding.url) {
      setVoorblad && setVoorblad(result.voorbladAfbeelding.url);
      return result.voorbladAfbeelding.url;
    }

    return null;
  } catch (error) {
    logErrorToApplicationInsights(error as Error);
  }

  return null;
};

export const deleteVoorbladApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string
): Promise<void> => {
  try {
    const url = `${settings.instellingenFormsOrigin}/Documenten/VoorbladAfbeelding`;
    const rsp = await fetch(url, {
      method: "DELETE",
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8",
        vestigingId
      }
    });

    if (!rsp.ok || rsp.status !== 204) {
      throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
    }
  } catch (error) {
    logErrorToApplicationInsights(error as Error);

    throw error;
  }
};
