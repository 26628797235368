/* istanbul ignore file */

import { UiError } from "../types";
import { getIn, setIn, FormikErrors } from "formik";

export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function sleep(ms = 10): Promise<void> {
  return new Promise((resolve): void => {
    setTimeout((): void => resolve(), ms);
  });
}

export function assertNever(value: never): never {
  throw new Error("Unexpected value: " + value);
}

export function prefixWithZero(value: number | null | undefined, size = 2): string | null {
  if (typeof value === "number") {
    return value.toString().padStart(size, "0");
  }

  return null;
}

export const limitCurrent = (current: number | null): number =>
  !hasValue(current) ? 0 : current > 100 ? 100 : current < 0 ? 0 : current;

export const getSum = (input: (number | null | undefined)[]): number => {
  return input.reduce((x, y): number => (x || 0) + (y || 0), 0) || 0;
};

export const keysToLowerCaseFirst = (obj: any): any => {
  Object.keys(obj).forEach(function(key): any {
    // only first char gets lowercased :-)
    const k = key.charAt(0).toLowerCase() + key.slice(1);

    if (k !== key) {
      obj[k] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

export function voegApiFoutToe<Values>(formikErrors: {}, platformApiFouten: UiError[] | null): FormikErrors<Values> {
  if (platformApiFouten) {
    platformApiFouten.forEach((element): void => {
      if (element.field) {
        formikErrors = setIn(formikErrors, element.field, element.error);
      }
    });
  }

  return formikErrors;
}

export function verwijderApiFoutVoor(
  formik: {
    values: {};
    setFieldValue: (field: string, value: any) => void;
  },
  name: string
): void {
  const currentPlaformApiFouten: UiError[] | null = getIn(formik.values, "platformApiFouten");

  if (Array.isArray(currentPlaformApiFouten)) {
    const newPlaformApiFouten = currentPlaformApiFouten.filter((e): boolean => e.field !== name);
    if (currentPlaformApiFouten.length !== newPlaformApiFouten.length) {
      formik.setFieldValue("platformApiFouten", newPlaformApiFouten);
    }
  }
}

export function hasValue<T>(value: T | null | undefined): value is T {
  return typeof value !== "undefined" && value !== null;
}
class AssertValueError extends Error {}
export function assertValue<T>(value: T | null | undefined): asserts value is T {
  if (!hasValue(value)) {
    throw new AssertValueError("value must not be null or undefined");
  }
}
