import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { UitgangspuntenState } from "../infra";
import ControleNormEnGewenstInkomen from "../infra/controle-norm-gewenst-inkomen";
import { syncScenarioMinimaalUitgangspuntSideEffects } from "../infra/determine-sync-inkomensuitgangspunt";

const Werkloosheid = ({ formik }: { formik: FormikContextType<UitgangspuntenState> }): ReactElement => {
  return (
    <>
      <ISWSideEffects
        sync={initISWSideEffect<UitgangspuntenState>(
          /* istanbul ignore next */ ({ subset }) => {
            syncScenarioMinimaalUitgangspuntSideEffects(subset.werkloosheid.create());
          }
        )()}
      />
      <ControleNormEnGewenstInkomen formik={formik} namePrefix="werkloosheid" />
    </>
  );
};

export default connect<{ namePrefix: string }, UitgangspuntenState>(Werkloosheid);
