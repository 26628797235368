/* istanbul ignore file */

import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import { useContext, useEffect } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { Autorisatieniveau, AutorisatieniveauOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { RouteParams } from "../paramrouting/paramrouting-context";

export type UseAutorisatieNiveauDataResult = {
  error: null | Error;
  data: Autorisatieniveau | null;
  loading: boolean;
};

export const useAutorisatieNiveauData = (): UseAutorisatieNiveauDataResult => {
  const { requestInit, settings } = useRequestInit<RouteParams>();
  const url = `${settings.instellingenFormsOrigin}/Autorisatieniveau`;
  const loadingDone = useContext(ForceRerenderContext);
  const { loading, error, data } = useAbortableFetch<AutorisatieniveauOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen-tarieven data"), data: null, loading: false };
  }

  return { error, data: data ? data.autorisatieniveau : null, loading };
};
