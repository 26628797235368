import React, { ReactElement, useContext } from "react";
import { Form, FormikProps } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";
import { Card, CardWrapper, PlatformFoutenSamenvatting } from "adviesbox-shared";

import { GeldverstrekkersState, GeldverstrekkersProps, geldverstrekkersSchema } from "./infra";

import BeschikbareGeldverstrekkers from "./beschikbare-geldverstrekkers/beschikbare-geldverstrekkers";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import classnames from "classnames";
import classes from "./beschikbare-geldverstrekkers/beschikbare-geldverstrekkers.module.scss";
import { SaveButton } from "../save-button/save-button";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Geldverstrekkers = (
  props: FormikProps<GeldverstrekkersState> & WithSaveData<GeldverstrekkersState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.geldverstrekkersNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className={classnames(classes.text_container, "text-container")}>
            <div className={"p-2"}>
              <h2>Geldverstrekkers&nbsp;</h2>

              <AutorisatieNiveauManager manageFor={autorisatieNiveau.geldverstrekkersNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper maxRowCount={12} className="px-3">
          <Card title="Beschikbare Geldverstrekkers" className="w-100">
            <div className="px-1">
              <BeschikbareGeldverstrekkers />
            </div>
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Geldverstrekkers.displayName = "Geldverstrekkers";

export default withAdviesboxFormik<GeldverstrekkersProps & WithSaveData<GeldverstrekkersState>, GeldverstrekkersState>({
  // Transform outer props into form values
  mapPropsToValues: (e: GeldverstrekkersProps): GeldverstrekkersState => e,
  validationSchema: geldverstrekkersSchema
})(Geldverstrekkers);
