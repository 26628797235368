import { InferType } from "yup";
import * as Yup from "yup";

export const bankgarantieInstellingPartij = Yup.object({
  code: Yup.string().default(""),
  partijnaam: Yup.string().default(""),
  actief: Yup.boolean().default(false),
  agentnummer: Yup.string().default("")
});

export const beschikbareBankgarantieinstellingenSchema = Yup.object({
  partijen: Yup.array(bankgarantieInstellingPartij).default([])
});

export const bankgarantieinstellingenSchema = Yup.object({
  beschikbareBankgarantieinstellingen: beschikbareBankgarantieinstellingenSchema
});

export type BankgarantieinstellingenState = InferType<typeof bankgarantieinstellingenSchema>;
export type BankgarantieinstellingenProps = InferType<typeof bankgarantieinstellingenSchema>;

export type BeschikbareBankgarantieinstellingenType = InferType<typeof beschikbareBankgarantieinstellingenSchema>;
