import React, { ReactElement, ReactNode } from "react";
import logo from "../assets/new-brand-logo.svg";
import classes from "./ApplicationLogo.module.scss";

export const ApplicationLogo = ({ children }: { children?: ReactNode }): ReactElement => {
  return (
    <div className={classes.page}>
      <div className={classes.centered_card}>
        <div>
          <img className={classes.logo} src={logo} alt="Adviesbox" title="Adviesbox" />
        </div>
        {children}
      </div>
    </div>
  );
};
