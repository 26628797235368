/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { SoftwareKoppelingenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToSoftwareKoppelingenUiField, mapSoftwareKoppelingenUiToDl } from "./infra";
import { mapSoftwareKoppelingenDlToUi } from "./infra/map-software-koppelingen";
import SoftwareKoppelingen from "./software-koppelingen";

const SoftwareKoppelingenAjax = (): ReactElement => {
  const { requestInit, settings, user, params } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/SoftwareKoppelingen`;
  const { loading, error, data } = useAbortableFetch<SoftwareKoppelingenOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapSoftwareKoppelingenDlToUi("softwareKoppelingen", data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <SoftwareKoppelingen
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapSoftwareKoppelingenUiToDl,
        mapDlTargetToSoftwareKoppelingenUiField
      )}
      {...mappedProps}
    />
  );
};

SoftwareKoppelingenAjax.displayName = "SoftwareKoppelingenAjax";

export default withErrorBoundary(SoftwareKoppelingenAjax);
