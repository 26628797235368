import * as Yup from "yup";
import { InferType } from "yup";

export enum MeldingType {
  aanvraagOntvangen = "Hypotheek aanvraag ontvangen",
  documentenOntvangen = "Documenten ontvangen",
  documentGekoppeld = "Document gekoppeld",
  foutmelding = "Foutmelding verzending document"
}

export const bemiddelaarSchema = Yup.object({
  bemiddelaarId: Yup.string().default(""),
  naam: Yup.string().default(""),
  emailadres: Yup.string().default("")
});
export type BemiddelaarSchemaType = InferType<typeof bemiddelaarSchema>;

export const coupledAdviseurSchema = Yup.object({
  adviseurId: Yup.string().default(""),
  naam: Yup.string().default(""),
  coupledBemiddelaarId: Yup.string()
    .nullable()
    .default(""),
  emailadres: Yup.string().default("")
});
export type CoupledAdviseurSchemaType = InferType<typeof coupledAdviseurSchema>;

export const gekoppeldSchema = Yup.object({
  naam: Yup.string().default(""),
  id: Yup.string().default("")
});

export const adviseurSchema = Yup.object({
  adviseurId: Yup.string().default(""),
  naam: Yup.string().default("")
});

export const koppelSchema = Yup.object({
  adviseur: adviseurSchema,
  bemiddelaar: bemiddelaarSchema
});
export type KoppelSchemaType = InferType<typeof koppelSchema>;

//  TODO remove defaults
export const koppelingenSchema = Yup.object({
  adviseurs: Yup.array(coupledAdviseurSchema).default([]),
  bemiddelaars: Yup.array(bemiddelaarSchema).default([]),
  koppelingen: Yup.array(koppelSchema).default([]),
  update: Yup.boolean().default(false),
  deleteEvent: Yup.array(Yup.string()).default([])
});

export type KoppelingType = InferType<typeof koppelingenSchema>;
export type KoppelingProps = InferType<typeof koppelingenSchema>;
export type KoppelingState = InferType<typeof koppelingenSchema>;
