import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { ServiceprovidersState } from "../infra";
import { getColumns, EmptyComponent } from "./serviceproviders-data-grid-components";
import { DataGrid } from "adviesbox-shared";

const BeschikbareServiceproviders = ({
  formik
}: {
  formik: FormikContextType<ServiceprovidersState>;
}): ReactElement => {
  const { values, setFieldValue } = formik;

  const addServiceProvider = (): void => {
    const curr = values.beschikbareServiceproviders.partijen;
    curr.push({
      serviceproviderId: "",
      actief: true,
      hdnNummer: null,
      naam: "",
      wijzigbaar: true
    });

    setFieldValue("beschikbareServiceproviders.partijen", curr);
  };

  return (
    <>
      <DataGrid
        name="beschikbareServiceproviders.partijen"
        editable={true}
        resizable={false}
        sortable={false}
        filterable={false}
        columns={getColumns(formik)}
        minRows={values.beschikbareServiceproviders.partijen.length || 0}
        pageSize={values.beschikbareServiceproviders.partijen.length || 0}
        showPagination={false}
        NoDataComponent={EmptyComponent}
      />

      <div className="button-container">
        <button id="add-serviceprovider-button" className="btn btn-primary" type="button" onClick={addServiceProvider}>
          + Serviceprovider toevoegen
        </button>
      </div>
    </>
  );
};

export default connect<{}, ServiceprovidersState>(BeschikbareServiceproviders);
