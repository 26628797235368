import * as Yup from "yup";
import { InferType } from "yup";
import { InstellingNiveauOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";

export const voorkeurenSchema = Yup.object({
  Lastenoverzicht: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Berekeningen: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Tarieven: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Documenten: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Algemeen: Yup.string().default(InstellingNiveauOptions.Medewerker)
});

export const beheerSchema = Yup.object({
  "Software Koppelingen": Yup.string().default(InstellingNiveauOptions.Medewerker),
  Serviceproviders: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Notarissen: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Taxatiebureaus: Yup.string().default(InstellingNiveauOptions.Medewerker),
  "Bankgarantie instellingen": Yup.string().default(InstellingNiveauOptions.Medewerker),
  Vermogensbeheerders: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Verzekeraars: Yup.string().default(InstellingNiveauOptions.Medewerker),
  Geldverstrekkers: Yup.string().default(InstellingNiveauOptions.Medewerker),
});

export const externSchema = Yup.object({
  // TODO: Op een later tijdstip toevoegen
  // "Nationale Hypotheekbond": Yup.string().default(InstellingNiveauOptions.Medewerker),
  // Elements: Yup.string().default(InstellingNiveauOptions.Medewerker),
  // HDN: Yup.string().default(InstellingNiveauOptions.Medewerker),
  // "Generieke koppelingen": Yup.string().default(InstellingNiveauOptions.Medewerker),
  "Nationale Waarborg": Yup.string().default(InstellingNiveauOptions.Medewerker)
});

export const instellingenSchema = Yup.object({
  "Standaard teksten": Yup.string().default(InstellingNiveauOptions.Medewerker),
  "Uitgangspunten advies": Yup.string().default(InstellingNiveauOptions.Medewerker),
  Adviesonderwerpen: Yup.string().default(InstellingNiveauOptions.Medewerker),
  "Klantprofiel en motivering": Yup.string().default(InstellingNiveauOptions.Medewerker)
});

export const autorisatieNiveauSchema = Yup.object({
  voorkeuren: voorkeurenSchema,
  beheer: beheerSchema,
  extern: externSchema,
  instellingen: instellingenSchema
});

export type AutorisatieNiveauState = InferType<typeof autorisatieNiveauSchema>;
export type AutorisatieNiveauProps = InferType<typeof autorisatieNiveauSchema>;
export type VoorkeurenType = InferType<typeof voorkeurenSchema>;
export type BeheerType = InferType<typeof beheerSchema>;
export type ExternType = InferType<typeof externSchema>;
export type InstellingenType = InferType<typeof instellingenSchema>;
export type AutorisatieNiveauGridProps = { options: string[]; formikKey: string };
export type AutorisatieNiveauRowProps = { formikKey: string; name: string; index: number };
