import {
  autorisatieNiveauSchema,
  voorkeurenSchema,
  beheerSchema,
  externSchema,
  instellingenSchema,
  AutorisatieNiveauState
} from "./autorisatie-niveau-schema";
import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
import {
  InstellingNiveauOptions,
  Autorisatieniveau,
  AutorisatieniveauOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

const mapVoorkeuren = createMapToUi(voorkeurenSchema).from<Autorisatieniveau>({
  Lastenoverzicht: v => v.lastenoverzichtenNiveau as InstellingNiveauOptions,
  Berekeningen: v => v.berekeningenNiveau as InstellingNiveauOptions,
  Tarieven: v => v.tarievenNiveau as InstellingNiveauOptions,
  Documenten: v => v.documentNiveau as InstellingNiveauOptions,
  Algemeen: v => v.algemeenNiveau as InstellingNiveauOptions
});

const mapBeheer = createMapToUi(beheerSchema).from<Autorisatieniveau>({
  "Software Koppelingen": v => v.softwareKoppelingenNiveau as InstellingNiveauOptions,
  Serviceproviders: v => v.serviceprovidersNiveau as InstellingNiveauOptions,
  Notarissen: v => v.notarissenNiveau as InstellingNiveauOptions,
  Taxatiebureaus: v => v.taxatiebureausNiveau as InstellingNiveauOptions,
  "Bankgarantie instellingen": v => v.bankgarantieInstellingenNiveau as InstellingNiveauOptions,
  Vermogensbeheerders: v => v.vermogensbeheerdersNiveau as InstellingNiveauOptions,
  Verzekeraars: v => v.verzekeraarsNiveau as InstellingNiveauOptions,
  Geldverstrekkers: v => v.geldverstrekkersNiveau as InstellingNiveauOptions,
});

const mapExtern = createMapToUi(externSchema).from<Autorisatieniveau>({
  // TODO: Op een later tijdstip toevoegen
  // "Nationale Hypotheekbond": v => v.nationaleHypotheekbondNiveau as InstellingNiveauOptions,
  // Elements: v => v.elementsNiveau as InstellingNiveauOptions,
  // HDN: v => v.hdnNiveau as InstellingNiveauOptions,
  // "Generieke koppelingen": v => v.generiekeKoppelingenNiveau as InstellingNiveauOptions,
  "Nationale Waarborg": v => v.nationaleWaarborgNiveau as InstellingNiveauOptions
});

const mapInstellingen = createMapToUi(instellingenSchema).from<Autorisatieniveau>({
  "Standaard teksten": v => v.standaardTekstenNiveau as InstellingNiveauOptions,
  "Uitgangspunten advies": v => v.uitgangspuntenAdviesNiveau as InstellingNiveauOptions,
  Adviesonderwerpen: v => v.adviesonderwerpenNiveau as InstellingNiveauOptions,
  "Klantprofiel en motivering": v => v.klantprofielMotiveringNiveau as InstellingNiveauOptions
});

function dl2ui(values: Autorisatieniveau): AutorisatieNiveauState {
  const autorisatieNiveau = {
    voorkeuren: mapVoorkeuren(values),
    beheer: mapBeheer(values),
    extern: mapExtern(values),
    instellingen: mapInstellingen(values)
  };

  return autorisatieNiveauSchema.cast(autorisatieNiveau);
}

export function mapAutorisatieNiveauDlToUi(data: AutorisatieniveauOutput): AutorisatieNiveauState | null {
  const autorisatieNiveau = data && data.autorisatieniveau ? data.autorisatieniveau : null;

  if (!autorisatieNiveau) {
    return null;
  }

  return dl2ui(autorisatieNiveau);
}

export const mapAutorisatieNiveauUiToDl = createMapToDl(autorisatieNiveauSchema).to<Autorisatieniveau>({
  algemeenNiveau: v => v.voorkeuren.Algemeen as InstellingNiveauOptions,
  documentNiveau: v => v.voorkeuren.Documenten as InstellingNiveauOptions,
  tarievenNiveau: v => v.voorkeuren.Tarieven as InstellingNiveauOptions,
  berekeningenNiveau: v => v.voorkeuren.Berekeningen as InstellingNiveauOptions,
  lastenoverzichtenNiveau: v => v.voorkeuren.Lastenoverzicht as InstellingNiveauOptions,
  geldverstrekkersNiveau: v => v.beheer.Geldverstrekkers as InstellingNiveauOptions,
  verzekeraarsNiveau: v => v.beheer.Verzekeraars as InstellingNiveauOptions,
  vermogensbeheerdersNiveau: v => v.beheer["Vermogensbeheerders"] as InstellingNiveauOptions,
  bankgarantieInstellingenNiveau: v => v.beheer["Bankgarantie instellingen"] as InstellingNiveauOptions,
  taxatiebureausNiveau: v => v.beheer.Taxatiebureaus as InstellingNiveauOptions,
  notarissenNiveau: v => v.beheer.Notarissen as InstellingNiveauOptions,
  serviceprovidersNiveau: v => v.beheer.Serviceproviders as InstellingNiveauOptions,
  klantprofielMotiveringNiveau: v => v.instellingen["Klantprofiel en motivering"] as InstellingNiveauOptions,
  adviesonderwerpenNiveau: v => v.instellingen.Adviesonderwerpen as InstellingNiveauOptions,
  uitgangspuntenAdviesNiveau: v => v.instellingen["Uitgangspunten advies"] as InstellingNiveauOptions,
  standaardTekstenNiveau: v => v.instellingen["Standaard teksten"] as InstellingNiveauOptions,
  // TODO: Op een later tijdstip toevoegen
  // generiekeKoppelingenNiveau: v => v.extern["Generieke koppelingen"] as InstellingNiveauOptions,
  // hdnNiveau: v => v.extern.HDN as InstellingNiveauOptions,
  // elementsNiveau: v => v.extern.Elements as InstellingNiveauOptions,
  // nationaleHypotheekbondNiveau: v =>
  //   v.extern["Nationale Hypotheekbond"] as InstellingNiveauOptions,
  generiekeKoppelingenNiveau: v => InstellingNiveauOptions.Organisatie, //TODO: Op een later tijdstip toevoegen
  hdnNiveau: v => InstellingNiveauOptions.Organisatie, // TODO: Op een later tijdstip toevoegen
  elementsNiveau: v => InstellingNiveauOptions.Organisatie, // TODO: Op een later tijdstip toevoegen
  nationaleHypotheekbondNiveau: v => InstellingNiveauOptions.Organisatie, // TODO: Op een later tijdstip toevoegen
  nationaleWaarborgNiveau: v => v.extern["Nationale Waarborg"] as InstellingNiveauOptions,
  softwareKoppelingenNiveau: v => v.beheer["Software Koppelingen"] as InstellingNiveauOptions
});

export function mapDlTargetToAutorisatieNiveauUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};
  return target2field(map, target);
}
