import { FieldMap, UiName } from "../../shared/types";
import { vermogensbeheerdersSchema, VermogensbeheerdersState } from "./vermogensbeheerders-schema";
import { target2field } from "../../shared/utils/target-to-field";

function dl2ui(values: any): VermogensbeheerdersState {
  const vermogensbeheerders = {
    ...vermogensbeheerdersSchema.default(),
    beschikbarebeheerders: {
      ...vermogensbeheerdersSchema.default().beschikbarebeheerders,
      partijen: values.partijen
    }
  };

  return vermogensbeheerdersSchema.cast(vermogensbeheerders);
}

export function mapVermogensbeheerdersDlToUi(
  vermogensbeheerdersId: string,
  data: any
): VermogensbeheerdersState | null {
  const vermogensbeheerders = data ? data[vermogensbeheerdersId] : null;

  if (!vermogensbeheerders) {
    return null;
  }

  return dl2ui(vermogensbeheerders);
}

export function mapVermogensbeheerdersUiToDl(vermogensbeheerders: VermogensbeheerdersState): any {
  return {
    partijen: vermogensbeheerders.beschikbarebeheerders.partijen
  };
}

export function mapDlTargetToVermogensbeheerdersUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
