import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { BankgarantieinstellingenState } from "../infra";
import { getColumns, EmptyComponent } from "./bankgarantie-data-grid-components";
import { DataGrid } from "adviesbox-shared";

const BeschikbareBankgarantieinstellingen = ({
  formik
}: {
  formik: FormikContextType<BankgarantieinstellingenState>;
}): ReactElement => {
  const { values } = formik;
  return (
    <DataGrid
      name="beschikbareBankgarantieinstellingen.partijen"
      editable={true}
      resizable={false}
      sortable={false}
      filterable={false}
      columns={getColumns(formik)}
      minRows={values.beschikbareBankgarantieinstellingen.partijen.length || 0}
      showPagination={false}
      pageSize={values.beschikbareBankgarantieinstellingen.partijen.length || 0}
      NoDataComponent={EmptyComponent}
    />
  );
};

export default connect<{}, BankgarantieinstellingenState>(BeschikbareBankgarantieinstellingen);
