import * as Yup from "yup";
import { InferType } from "yup";

export const menuonderdelenSchema = Yup.object({
  hypotheek: Yup.boolean().default(true),
  kapitaalVerzekering: Yup.boolean().default(false),
  ORV: Yup.boolean().default(false),
  AOV: Yup.boolean().default(false),
  vermogen: Yup.boolean().default(false),
  krediet: Yup.boolean().default(false),
  uitkerendeLijfrente: Yup.boolean().default(false),
  overig: Yup.boolean().default(false)
});

export const onderwerpenSchema = Yup.object({
  hypotheek: Yup.boolean().default(false),
  overlijden: Yup.boolean().default(false),
  arbeidsongeschiktheid: Yup.boolean().default(false),
  werkloosheid: Yup.boolean().default(false),
  pensioen: Yup.boolean().default(false),
  vermogensopbouw: Yup.boolean().default(false)
});

export const adviesonderwerpenSchema = Yup.object({
  menuonderdelen: menuonderdelenSchema,
  onderwerpen: onderwerpenSchema
});

export type AdviesonderwerpenState = InferType<typeof adviesonderwerpenSchema>;
export type AdviesonderwerpenProps = InferType<typeof adviesonderwerpenSchema>;

export type OnderwerpenType = InferType<typeof onderwerpenSchema>;

export type MenuonderdelenType = InferType<typeof menuonderdelenSchema>;
