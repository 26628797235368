/* istanbul ignore file */
import { PageLoading, useRequestInit } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { NationaleWaarborgSchema } from "../infra/nationale-waarborg-types";
import NationaleWaarborg from "./nationalewaarborg";

const NationaleWaarborgAjax = (): ReactElement => {
  const { user, settings, requestInit } = useRequestInit();
  const medewerkerUrl =
    user && user.profile ? `${settings.instellingenFormsOrigin}/ExterneKoppelingen/NationaleWaarborg/Actief` : null;
  const { data, loading, error } = useAbortableFetch<{ isActief: boolean; isValid: boolean }>(
    medewerkerUrl,
    requestInit
  );

  if (loading) {
    return <PageLoading />;
  }

  if (data && typeof data !== "string" && data.isValid)
    return <NationaleWaarborg {...NationaleWaarborgSchema.default()} reedsActief={data.isActief} />;

  if (error || typeof data === "string") throw Error("Onbekende fout bij laden gegevens NationaleWaarborg-Ajax");

  return <></>;
};

NationaleWaarborgAjax.displayName = "NationaleWaarborgAjax";

export default NationaleWaarborgAjax;
