/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { KlantprofielMotiveringOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import {
  mapDlTargetToklantprofielmotiveringUiField,
  mapklantprofielmotiveringDlToUi,
  mapklantprofielmotiveringUiToDl
} from "./infra";
import Klantprofielmotivering from "./klantprofiel-motivering";

const KlantprofielmotiveringAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData

  const { params, settings, requestInit, user } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/KlantprofielMotivering`;
  const { loading, error, data } = useAbortableFetch<KlantprofielMotiveringOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapklantprofielmotiveringDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Klantprofielmotivering
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapklantprofielmotiveringUiToDl,
        mapDlTargetToklantprofielmotiveringUiField
      )}
      {...mappedProps}
    />
  );
};

KlantprofielmotiveringAjax.displayName = "klantprofielmotiveringAjax";

export default withErrorBoundary(KlantprofielmotiveringAjax);
