import React, { ReactElement } from "react";
import VestigingenSelect from "../vestigingen-select/vestigingen-select";

enum GeneralAutorisatieNiveau {
  Vestiging,
  Organisatie
}

export const AutorisatieNiveauManager = ({ manageFor }: { manageFor: string | null }): ReactElement => {
  const autorisatieNiveau = {
    vestiging:
      GeneralAutorisatieNiveau[manageFor as keyof typeof GeneralAutorisatieNiveau] ===
      GeneralAutorisatieNiveau.Vestiging,
    organisatie:
      GeneralAutorisatieNiveau[manageFor as keyof typeof GeneralAutorisatieNiveau] ===
      GeneralAutorisatieNiveau.Organisatie
  };

  return (
    <div data-testid="autorisatieniveau-manager-wrapper" className="d-inline">
      {autorisatieNiveau.vestiging && <VestigingenSelect />}
      {autorisatieNiveau.organisatie && (
        <h2 id="gehele-organisatie-text" className="p-0">
          voor de hele organisatie&nbsp;
        </h2>
      )}
    </div>
  );
};
