import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { CheckBox } from "adviesbox-shared";
import { AdviesonderwerpenState } from "../infra";

const Onderwerpen = ({
  formik: {
    setFieldValue,
    values: { onderwerpen }
  }
}: {
  formik: FormikContextType<AdviesonderwerpenState>;
}): ReactElement => {
  return (
    <div>
      <p className="p-3">
        Als u het selectievakje bij een onderwerp uitzet komt deze niet meer terug in aanleiding, scenario, dashboard,
        motivering, advies en rapportage.
      </p>
      <div>
        <h2 className="ml-3">Financiering</h2>
        <div className="px-1">
          <CheckBox caption="Hypotheek" name="onderwerpen.hypotheek" />
        </div>
      </div>
      <div className="py-4">
        <h2 className="ml-3">Financiële planning</h2>
        <div className="px-1">
          <CheckBox caption="Overlijden" name="onderwerpen.overlijden" />
          <CheckBox caption="Arbeidsongeschiktheid" name="onderwerpen.arbeidsongeschiktheid" />
          <CheckBox caption="Werkloosheid" name="onderwerpen.werkloosheid" />
          <CheckBox caption="Pensioen" name="onderwerpen.pensioen" />
          <CheckBox caption="Vermogensopbouw" name="onderwerpen.vermogensopbouw" />
        </div>
      </div>
    </div>
  );
};

export default connect<{}, AdviesonderwerpenState>(Onderwerpen);
