/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { TaxatiebureausOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import { mapDlTargetToTaxatiebureausUiField, mapTaxatiebureausDlToUi, mapTaxatiebureausUiToDl } from "./infra";
import Taxatiebureaus from "./taxatiebureaus";

const TaxatiebureausAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData
  const { requestInit, settings, user, params } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Taxatiebureaus`;
  const { loading, error, data } = useAbortableFetch<TaxatiebureausOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapTaxatiebureausDlToUi("taxatiebureaus", data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Taxatiebureaus
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapTaxatiebureausUiToDl,
        mapDlTargetToTaxatiebureausUiField
      )}
      {...mappedProps}
    />
  );
};

TaxatiebureausAjax.displayName = "TaxatiebureausAjax";

export default withErrorBoundary(TaxatiebureausAjax);
