import React, { ReactElement } from "react";
import { LabeledBevestigingInput } from "adviesbox-shared";

type InstelingenOverzichtenProps = {};
const logoFunctionaliteitTonen = false;

// const overzichtVanOptions: LabelValuePairs = [
//   { label: "Alle jaren", value: OverzichtTonenVanOptions.AlleJaren },
//   { label: "Alleen jaren met wijzigingen", value: OverzichtTonenVanOptions.AllenJarenMetWijzigingen }
// ];

export const InstelingenOverzichten = (_props: InstelingenOverzichtenProps): ReactElement => {
  return (
    <>
    {logoFunctionaliteitTonen && (
      <LabeledBevestigingInput
        name="instelingenOverzichten.bedrijfslogoTonenOpOverzichten"
        caption="Bedrijfslogo tonen op overzichten"
    />)}
      {/* <LabeledSelectInput
        name="instelingenOverzichten.overzichtTonenVan"
        caption="Overzicht tonen van"
        options={overzichtVanOptions}
      /> */}
      <LabeledBevestigingInput
        name="instelingenOverzichten.toonGrafiekenBijScenarioOverzichten"
        caption="Toon grafieken bij scenario overzichten"
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  InstelingenOverzichten.displayName = "InstelingenOverzichten";
