import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  AdviseurModel,
  BemiddelaarModel,
  GetAdviseursAndBemiddelaarsApiOutputModel
} from "../../.generated/bemiddeling/bemiddelingtypes";
import {
  KoppelingState,
  koppelSchema,
  bemiddelaarSchema,
  coupledAdviseurSchema,
  koppelingenSchema
} from "./koppeling-schema";

const mapAdviseur = createMapToUi(coupledAdviseurSchema).from<AdviseurModel>({
  adviseurId: v => v.adviseurId,
  naam: v => v.naam,
  coupledBemiddelaarId: v => v.coupledBemiddelaarId,
  emailadres: v => v.emailadres
});

const mapBemiddelaar = createMapToUi(bemiddelaarSchema).from<BemiddelaarModel>({
  bemiddelaarId: v => v.bemiddelaarId,
  naam: v => v.naam,
  emailadres: v => v.emailadres
});

const mapKoppelingen = createMapToUi(koppelSchema).from<{
  adviseur: AdviseurModel;
  bemiddelaars: BemiddelaarModel[] | null;
}>({
  adviseur: v => {
    return {
      naam: v.adviseur.naam ?? /*istanbul ignore next */ "",
      adviseurId: v.adviseur.adviseurId ?? /*istanbul ignore next */ "",
      emailadres: v.adviseur.emailadres ?? /*istanbul ignore next */ ""
    };
  },
  bemiddelaar: v => {
    const bemiddelaar = v.bemiddelaars?.find(
      (b: BemiddelaarModel) => b.bemiddelaarId === v.adviseur.coupledBemiddelaarId
    );

    return {
      bemiddelaarId: bemiddelaar?.bemiddelaarId ?? /*istanbul ignore next */ "",
      naam: bemiddelaar?.naam ?? /*istanbul ignore next */ "",
      emailadres: bemiddelaar?.emailadres ?? /*istanbul ignore next */ ""
    };
  }
});

const dl2uiKoppeling = createMapToUi(koppelingenSchema).from<GetAdviseursAndBemiddelaarsApiOutputModel>({
  bemiddelaars: v =>
    v.bemiddelaars ? v.bemiddelaars.map((bemiddelaar: BemiddelaarModel) => mapBemiddelaar(bemiddelaar)) : [],
  adviseurs: v => (v.adviseurs ? v.adviseurs.map((adviseur: AdviseurModel) => mapAdviseur(adviseur)) : []),
  koppelingen: v =>
    v.adviseurs
      ?.filter((adviseur: AdviseurModel) => adviseur.coupledBemiddelaarId)
      .map((a: AdviseurModel) => mapKoppelingen({ adviseur: a, bemiddelaars: v.bemiddelaars })),
  update: v => false,
  deleteEvent: v => []
});

export function mapKoppelingDlNaarUi(
  adviesdossierId: string,
  data: GetAdviseursAndBemiddelaarsApiOutputModel
): KoppelingState | null {
  return dl2uiKoppeling(data);
}
