/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { AutorisatieniveauOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { Title } from "../shared/components/title/title";
import { saveData } from "../shared/utils/save-data";
import AutorisatieNiveau from "./autorisatie-niveau";
import { mapAutorisatieNiveauDlToUi, mapAutorisatieNiveauUiToDl, mapDlTargetToAutorisatieNiveauUiField } from "./infra";

type RouteParams = {
  vestiging: string;
};

const AutorisatieNiveauAjax = (): ReactElement => {
  const { settings, user, requestInit, params } = useRequestInit<RouteParams>();
  const url = `${settings.instellingenFormsOrigin}/Autorisatieniveau`;
  const { loading, error, data } = useAbortableFetch<AutorisatieniveauOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapAutorisatieNiveauDlToUi(data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <>
      <Title appName="Instellingen" altTitle="Autorisatieniveau" />
      <AutorisatieNiveau
        saveData={saveData(
          user,
          params.vestiging,
          url,
          settings.OcpApimSubscriptionKey,
          mapAutorisatieNiveauUiToDl,
          mapDlTargetToAutorisatieNiveauUiField
        )}
        {...mappedProps}
      />
    </>
  );
};

AutorisatieNiveauAjax.displayName = "AutorisatieNiveauAjax";

export default withErrorBoundary(AutorisatieNiveauAjax);
