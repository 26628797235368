/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from "react";
import { FormikConsumer } from "formik";
import { detailedDiff } from "deep-object-diff";
import classes from "./debug.module.scss";

type DebugProps = {
  force?: boolean;
};

type DebugToggleProps = {
  label: string;
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const DebugToggle = ({ label, name, checked, onChange }: DebugToggleProps): ReactElement => {
  const id = Math.random();

  return (
    <div className={classes.checkbox}>
      <input
        type="checkbox"
        checked={checked}
        id={`name-${id}`}
        onChange={(): void => {
          sessionStorage[name] = !checked;
          onChange(!checked);
        }}
      />
      <label className={classes.checkbox_label} htmlFor={`name-${id}`}>
        <span>{label}</span>
      </label>
    </div>
  );
};

export const Debug = ({ force = false }): ReactElement | null => {
  const [traceAdvies, setTraceAdvies] = React.useState<boolean>(sessionStorage.traceAdvies === "true");
  const [showValues, setShowValues] = React.useState<boolean>(sessionStorage.showValues === "true");
  const [showInitialValues, setShowInitialValues] = React.useState<boolean>(
    sessionStorage.showInitialValues === "true"
  );
  const valuesRef = React.useRef<any>(null);

  if (process.env.NODE_ENV !== "development" && !force) {
    return null;
  }

  return (
    <div
      style={{
        margin: "3rem 0",
        borderRadius: 4,
        background: "#f6f8fa",
        maxHeight: "50vh",
        overflow: "scroll",
        boxShadow: "0 0 1px  #eee inset"
      }}
    >
      <div
        style={{
          textTransform: "uppercase",
          fontSize: 11,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          fontWeight: 500,
          padding: ".5rem",
          background: "#555",
          color: "#fff",
          letterSpacing: "1px"
        }}
      >
        Formik State
      </div>
      <div>
        <DebugToggle label="Trace changes" name={"traceAdvies"} checked={traceAdvies} onChange={setTraceAdvies} />
        <DebugToggle label="Values" name={"showValues"} checked={showValues} onChange={setShowValues} />
        <DebugToggle
          label="Initial values"
          name={"showInitialValues"}
          checked={showInitialValues}
          onChange={setShowInitialValues}
        />
      </div>
      <FormikConsumer>
        {({ validationSchema, validate, onSubmit, ...rest }: any): ReactElement => {
          if (traceAdvies && valuesRef.current !== rest.values) {
            const difference = detailedDiff(valuesRef.current, rest.values) as DetailedDiff;
            if (!Object.keys(difference.added).length) {
              delete difference.added;
            }
            if (!Object.keys(difference.deleted).length) {
              delete difference.deleted;
            }
            if (!difference.updated || !Object.keys(difference.updated).length) {
              delete difference.updated;
            }

            /* eslint-disable-next-line no-console */
            console.log(difference);
            valuesRef.current = rest.values;
          }

          if (!showValues) {
            delete rest.values;
          }

          if (!showInitialValues) {
            delete rest.initialValues;
          }

          return <pre>{JSON.stringify(rest, null, 2)}</pre>;
        }}
      </FormikConsumer>
    </div>
  );
};

type DetailedDiff = {
  added: object;
  deleted: object;
  updated: object;
};
