import React, { ReactElement, useContext } from "react";
import { FormikProps, Form, useFormikContext } from "formik";
import {
  Card,
  CardWrapper,
  PlatformFoutenSamenvatting,
} from "adviesbox-shared";
import {
  AdviesonderwerpenState,
  AdviesonderwerpenProps,
  adviesonderwerpenSchema,
} from "./infra";
import Onderwerpen from "./onderwerpen/onderwerpen";
import Menuonderdelen from "./menuonderdelen/menuonderdelen";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import { WithSaveData } from "../shared/utils/save-data";
import { Debug } from "../shared/components/formik/Debug";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { getAdviesonderwerpenResources } from "./infra/adviesonderwerpen-resources";
import { isEqual } from "lodash";
import { SaveButtonWithConfirmation } from "../save-button/save-button-with-confimation";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Adviesonderwerpen = (
  props: FormikProps<AdviesonderwerpenState> &
    WithSaveData<AdviesonderwerpenState>
): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { initialValues, values } = useFormikContext<AdviesonderwerpenState>();
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  const onderwerpenDirty = !isEqual(
    values.onderwerpen,
    initialValues.onderwerpen
  );
  const menuonderdelenDirty = !isEqual(
    values.menuonderdelen,
    initialValues.menuonderdelen
  );

  let body = <></>;
  let title = "";
  if (onderwerpenDirty && menuonderdelenDirty) {
    title =
      "Menuonderdelen en adviesonderwerpen uitschakelen en data verwijderen";
    body = (
      <p>
        {getAdviesonderwerpenResources("OnderwerpenEnMenuonderdelenWaring")}
      </p>
    );
  } else if (onderwerpenDirty) {
    title = "Adviesonderwerpen uitschakelen en data verwijderen";
    body = <p>{getAdviesonderwerpenResources("OnderwerpenWaring")}</p>;
  } else if (menuonderdelenDirty) {
    title = "Menuonderdelen uitschakelen en data verwijderen";
    body = <p>{getAdviesonderwerpenResources("MenuonderdelenWarning")}</p>;
  }

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={
          !(
            isBeheerder ||
            autorisatieNiveau.adviesonderwerpenNiveau ===
              InstellingNiveauOptions.Medewerker
          )
        }
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="p-2">
              <h2 className="p-0">Adviesonderwerpen&nbsp; </h2>
              <AutorisatieNiveauManager
                manageFor={autorisatieNiveau.adviesonderwerpenNiveau}
              />
              <div className="save-btn-position">
                <SaveButtonWithConfirmation
                  props={props}
                  modalBody={body}
                  modalTitle={title}
                  disabled={!onderwerpenDirty && !menuonderdelenDirty}
                  saveBtnVariant={"danger"}
                  saveBtnTekst={"Wijziging opslaan en data verwijderen"}
                  cancelBtnTekst={"Wijziging niet opslaan"}
                />
              </div>
            </div>
          </div>
        </CardWrapper>

        <CardWrapper className="px-3">
          <Card title="Adviesonderwerpen">
            <Onderwerpen />
          </Card>
          <Card title="Menuonderdelen">
            <Menuonderdelen />
          </Card>
        </CardWrapper>
      </fieldset>
      <Debug />
    </Form>
  );
};

Adviesonderwerpen.displayName = "Adviesonderwerpen";

export default withAdviesboxFormik<
  AdviesonderwerpenProps & WithSaveData<AdviesonderwerpenState>,
  AdviesonderwerpenState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: AdviesonderwerpenProps): AdviesonderwerpenState => e,
  validationSchema: adviesonderwerpenSchema,
})(Adviesonderwerpen);
