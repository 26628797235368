/* istanbul ignore file*/
import { Documenten as DocumentenDlEntry } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

export function mapDlTargetToDocumentenUiField(target: string): UiName | null {
  const map: FieldMap<DocumentenDlEntry> = {};

  return target2field(map, target);
}
