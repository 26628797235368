import {
  AdviesBoxFormik,
  LabeledSelectInput,
  LabeledText,
  LabeledTextInput,
  LabeledCheckBox,
} from "adviesbox-shared";
import { FormikProps, getIn, setIn, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { LabelValuePairs } from "../../types";
import Modal from "../modal/Modal";
import {
  RapportageSamenstellenState,
  RapportageElementState,
} from "./infra/rapportage-samenstellen-types";
import { rapportageSamenstellenSchema } from "./rapportage-samenstellen-schema";
import { Button } from "react-bootstrap";
import { RapportageElementSoort } from "./infra/rapportage-structuur-types";

type TitelAanpassen = {
  onSave?: (data: any) => void;
  closeModal?: () => void;
  parent: string;
  adoptionList?: LabelValuePairs;
};

export const TitelAanpassenModal = ({
  onSave,
  closeModal,
  parent,
  adoptionList,
}: TitelAanpassen): ReactElement => {
  const { values } = useFormikContext<RapportageSamenstellenState>();

  const body = (
    values: RapportageSamenstellenState,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ): ReactElement => {
    const elementData: RapportageElementState = getIn(values, parent);
    return (
      <>
        <LabeledText value={elementData.naam} caption={"Huidige titel"} />
        <LabeledTextInput
          name={`${parent}.naam`}
          caption={"Nieuwe titel"}
          fieldSize={"xxl"}
        />
        {adoptionList &&
          elementData.soortElement !== RapportageElementSoort.Tekstblok && (
            <LabeledSelectInput
              caption={"Dit element verplaatsen naar"}
              name={"movetoparent"}
              options={adoptionList}
            />
          )}
        {elementData.soortElement === RapportageElementSoort.Tekstblok && (
          <>
            <LabeledTextInput
              name={`${parent}.inhoud`}
              value={elementData.inhoud ?? ""}
              caption={"Inhoud"}
              fieldSize={"xxl"}
            />
            <LabeledCheckBox
              caption="Per advies aanpasbaar"
              name={`${parent}.perAdviesAanpasbaar`}
            ></LabeledCheckBox>
            <Button
              onClick={() => {
                setFieldValue(`${parent}.inhoud`, elementData.defaultInhoud);
              }}
              data-testid={`${parent}-testid`}
            >
              Terug naar ABO-default
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <AdviesBoxFormik<RapportageSamenstellenState>
      initialValues={{ ...values }}
      validationSchema={rapportageSamenstellenSchema}
      onSave={(values) => {
        let newValues = values;
        if (values.movetoparent) {
          const elementToMove = getIn(newValues, parent);
          const arrayToAddTo = getIn(newValues, values.movetoparent);

          const nameList = parent.split(".");

          nameList.pop();

          const parentListName = nameList.join(".");

          const parentList = getIn(newValues, parentListName).elementen;

          const filteredParentList = parentList
            .filter((c: any) => {
              return c !== elementToMove;
            })
            .map((c: any, i: number) => ({ ...c, volgnummer: i + 1 }));

          newValues = setIn(
            newValues,
            `${parentListName}.elementen`,
            filteredParentList
          );
          newValues = setIn(
            newValues,
            `${values.movetoparent}.elementen`,
            [
              ...arrayToAddTo.elementen,
              elementToMove,
            ].map((c: any, i: number) => ({ ...c, volgnummer: i + 1 }))
          );
        }
        onSave && onSave(newValues);
      }}
      closeModal={closeModal}
      render={({
        submitForm,
        setFieldValue,
        values,
      }: FormikProps<RapportageSamenstellenState>): ReactElement => (
        <>
          <Modal
            title="Titel aanpassen"
            body={body(values, setFieldValue)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};
