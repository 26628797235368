import {
  Rapportage,
  RapportageElement,
  RapportageKolommenLasten,
  RapportageKolommenNbi,
  RapportageKolomselectie,
  RapportageOutput,
  RapportageStructuur,
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { RapportageSamenstellenState } from "../../shared/components/rapportage/infra/rapportage-samenstellen-types";
import { RapportageElementSoort } from "../../shared/components/rapportage/infra/rapportage-structuur-types";
import {
  kolommenInstellenModalSchema,
  kolommenLastenSchema,
  kolommenNbiSchema,
  rapportageElementSchema,
  rapportageSamenstellenSchema,
  rapportageStructuurSchema,
} from "../../shared/components/rapportage/rapportage-samenstellen-schema";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

const mapRapportageElementenToUi: any = createMapToUi(
  rapportageElementSchema
).from<RapportageElement>({
  code: (v) => v.code,
  geselecteerd: (v) => v.geselecteerd,
  naam: (v) => v.naam,
  soortElement: (v) => (v.soortElement as unknown) as RapportageElementSoort,
  volgnummer: (v) => v.volgnummer,
  elementen: (v) => v.elementen?.map((c) => mapRapportageElementenToUi(c)),
  inhoud: (v) => v.inhoud || null,
  defaultInhoud: (v) => v.defaultInhoud || null,
  perAdviesAanpasbaar: (v) => v.inhoudWijzigbaar,
});

const mapKolommenLastenToUi = createMapToUi(kolommenLastenSchema).from<
  RapportageKolommenLasten
>({
  brutoInkomen: (v) => v.brutoInkomen,
  brutoLast: (v) => v.brutoLast,
  fiscaalVoordeel: (v) => v.fiscaalVoordeel,
  huur: (v) => v.huur,
  hypotheekAflossing: (v) => v.hypotheekAflossing,
  kredietLast: (v) => v.kredietLast,
  nettoLast: (v) => v.nettoLast,
  opnameUitDepot: (v) => v.opnameUitDepot,
  overigeUitgaven: (v) => v.overigeUitgaven,
  rente: (v) => v.rente,
  somHypotheken: (v) => v.somHypotheken,
  spaarBeleggingsdepots: (v) => v.spaarBeleggingsdepots,
  uitkeringKapitaalverzekering: (v) => v.uitkeringKapitaalverzekering,
  verzekeringspremies: (v) => v.verzekeringspremies,
  vrh: (v) => v.vrh,
  waardeopbouw: (v) => v.waardeopbouw,
});
const mapKolommenNbiToUi = createMapToUi(kolommenNbiSchema).from<
  RapportageKolommenNbi
>({
  brutoLast: (v) => v.brutoLast,
  hypotheek: (v) => v.hypotheek,
  inkomstenbelasting: (v) => v.inkomstenbelasting,
  kredietLast: (v) => v.kredietLast,
  nbi: (v) => v.nbi,
  nettoInkomen: (v) => v.nettoInkomen,
  overigeUitgaven: (v) => v.overigeUitgaven,
  spaarBeleggingsdepots: (v) => v.spaarBeleggingsdepots,
  verzekeringen: (v) => v.verzekeringen,
  vrh: (v) => v.vrh,
});

const mapRapportageKolomSelectieToUi = createMapToUi(
  kolommenInstellenModalSchema
).from<RapportageKolomselectie>({
  kolommenLasten: (v) => mapKolommenLastenToUi(v.kolommenLasten),
  kolommenNbi: (v) => mapKolommenNbiToUi(v.kolommenNbi),
});

const mapRapportageStructuurToUi = createMapToUi(
  rapportageStructuurSchema
).from<RapportageStructuur>({
  elementen: (v) => v.elementen.map((c) => mapRapportageElementenToUi(c)),
  kolomselectie: (v) => mapRapportageKolomSelectieToUi(v.kolomselectie),
  naam: (v) => v.naam,
  standaardRapport: (v) => v.standaardRapport,
  structuurId: (v) => v.structuurId,
  volgnummer: (v) => v.volgnummer,
});

const dl2ui = createMapToUi(rapportageSamenstellenSchema).from<Rapportage>({
  structuren: (v) => v.structuren.map((c) => mapRapportageStructuurToUi(c)),
  movetoparent: (_) => null,
});

export function mapRapportageSamenstellenDlToUi(
  data: RapportageOutput | null
): RapportageSamenstellenState | null {
  const rapporageSamenstellen =
    data && data.rapportage ? data.rapportage : null;

  if (!rapporageSamenstellen) return null;

  const res = dl2ui(rapporageSamenstellen);

  return res;
}
