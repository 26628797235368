import React, { ReactElement } from "react";
import { connect } from "formik";
import { LabeledBevestigingInput, LabeledPercentageInput } from "adviesbox-shared";
import { BerekeningenState } from "../infra";
import { getBerekeningenTextResources } from "../infra/berekeningen-resources";

const Algemeen = (): ReactElement => {
  return (
    <div>
      <LabeledPercentageInput
        caption="Huurstijging per jaar"
        name="algemeen.huurstijgingPercentage"
        decimalen={2}
      ></LabeledPercentageInput>

      <LabeledPercentageInput
        caption="Indexatie waarde pand per jaar"
        name="algemeen.indexatieWaardePandPercentage"
        decimalen={2}
        tooltip={getBerekeningenTextResources("TooltipIndexatieWaardePandPercentage")}
      ></LabeledPercentageInput>

      <LabeledBevestigingInput
        caption="Mindering overwaarde op de gewenste hypotheek"
        name="algemeen.minderingOverwaardeGewensteHypotheek"
        tooltip={getBerekeningenTextResources("TooltipminderingOverwaardeGewensteHypotheek")}
      />
    </div>
  );
};

export default connect<{}, BerekeningenState>(Algemeen);
