import { GeslachtOpties } from "./../../.generated/forms/formstypes";
import { InferType } from "yup";
import * as Yup from "yup";
import { yupEnum } from "../../shared/utils/yup-enum";

export const contactpersoonSchema = Yup.object({
  achternaam: Yup.string().default(""),
  voorvoegsel: Yup.string().default(""),
  voorletters: Yup.string().default(""),
  geslacht: yupEnum(GeslachtOpties).default(GeslachtOpties.Man)
});

export type ContactpersoonType = InferType<typeof contactpersoonSchema>;

export const notarisAdresSchema = Yup.object({
  straat: Yup.string()
    .nullable()
    .default(""),
  huisnummer: Yup.number()
    .nullable()
    .default(null),
  toevoeging: Yup.string()
    .nullable()
    .default(""),
  postcode: Yup.string()
    .nullable()
    .default("")
    .when("landCode", {
      is: (val): boolean => val === "NL",
      then: Yup.string().matches(/^$|^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i, "Ongeldige postcode.")
    }),
  plaats: Yup.string()
    .nullable()
    .default(""),
  landCode: Yup.string()
    .nullable()
    .default("NL")
});
export type NotarisAdresSchemaType = InferType<typeof notarisAdresSchema>;

export const partijSchema = Yup.object({
  notariskantoorId: Yup.string().default(""),
  kantoornaam: Yup.string().default(""),
  adres: notarisAdresSchema,
  contactpersoon: contactpersoonSchema,
  telefoonnummer: Yup.string().default("")
});
export type PartijType = InferType<typeof partijSchema>;

export const beschikbareNotarissenSchema = Yup.object({
  partijen: Yup.array(partijSchema).default([])
});

export type BeschikbareNotarissenState = InferType<typeof beschikbareNotarissenSchema>;
export type BeschikbareNotarissenProps = InferType<typeof beschikbareNotarissenSchema>;
