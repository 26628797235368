import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput, LabeledCurrencyInput } from "adviesbox-shared";
import { TarievenState } from "../infra";
import {
  VastBedragBedragPerUurOptions,
  VastBedragOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";

const AdvieskostenMetHypotheek = ({
  formik: {
    values: { advieskostenMetHypotheek }
  }
}: {
  formik: FormikContextType<TarievenState>;
}): ReactElement => {
  return (
    <div>
      <LabeledRadioInput
        caption="Advieskosten"
        name="advieskostenMetHypotheek.adviesKosten"
        options={[
          {
            label: "Vast bedrag",
            value: VastBedragOptions.VastBedrag
          },
          {
            label: "Per uur",
            value: VastBedragBedragPerUurOptions.BedragPerUur
          }
        ]}
      />
      <LabeledCurrencyInput
        caption="Bedrag advieskosten"
        name="advieskostenMetHypotheek.bedragAdviesKosten"
        appendChildren={
          advieskostenMetHypotheek.adviesKosten === VastBedragBedragPerUurOptions.BedragPerUur ? (
            <div className="d-flex align-items-center h-100 ml-2">
              <span>Per uur</span>
            </div>
          ) : null
        }
      />
      <LabeledRadioInput
        caption="Bemiddelingsvergoeding geldlening"
        name="advieskostenMetHypotheek.bemiddelingsVergoeding"
        options={[
          {
            label: "Vast bedrag",
            value: VastBedragBedragPerUurOptions.VastBedrag
          },
          {
            label: "Per uur",
            value: VastBedragBedragPerUurOptions.BedragPerUur
          }
        ]}
      />
      <LabeledCurrencyInput
        caption="Bedrag bemiddelingsvergoeding"
        name="advieskostenMetHypotheek.bedragBemiddelingsVergoeding"
        appendChildren={
          advieskostenMetHypotheek.bemiddelingsVergoeding === VastBedragBedragPerUurOptions.BedragPerUur ? (
            <div className="d-flex align-items-center h-100 ml-2">
              <span>Per uur</span>
            </div>
          ) : null
        }
      />
      <LabeledRadioInput
        caption="Overige bemiddelingsvergoeding"
        name="advieskostenMetHypotheek.overigeBemiddelingsVergoeding"
        options={[
          {
            label: "Vast bedrag",
            value: VastBedragBedragPerUurOptions.VastBedrag
          },
          {
            label: "Per uur",
            value: VastBedragBedragPerUurOptions.BedragPerUur
          }
        ]}
      />
      <LabeledCurrencyInput
        caption="Bedrag overige bemiddelingsvergoeding"
        name="advieskostenMetHypotheek.bedragOverigeBemiddelingsVergoeding"
        appendChildren={
          advieskostenMetHypotheek.overigeBemiddelingsVergoeding === VastBedragBedragPerUurOptions.BedragPerUur ? (
            <div className="d-flex align-items-center h-100 ml-2">
              <span>Per uur</span>
            </div>
          ) : null
        }
      />
      <LabeledCurrencyInput
        fieldSize="xl"
        caption="Dossiervergoeding"
        name="advieskostenMetHypotheek.dossierVergoeding"
      />
    </div>
  );
};

export default connect<{}, TarievenState>(AdvieskostenMetHypotheek);
