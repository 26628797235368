import React, { ReactElement } from "react";
import { connect } from "formik";
import { CheckBox, LabeledBevestigingInput } from "adviesbox-shared";
import { LastenoverzichtState, getLastenoverzichtTextResources } from "../infra";

const Verrekenen = (): ReactElement => {
  return (
    <div className="px-1">
      <div>
        <CheckBox
          caption="Uitkeringen uit financiële dekkingen"
          name="verrekenen.uitkeringenFinancieleDekkingen"
        ></CheckBox>
        <CheckBox
          caption="Aflossingen hypotheek (niet zijnde: annuïtair en lineair)"
          name="verrekenen.aflossingenHypotheek"
        ></CheckBox>
        <CheckBox caption=" Aanvangsstortingen / eerste inleg" name="verrekenen.aanvangsstortingen"></CheckBox>
        <CheckBox caption="Extra stortingen" name="verrekenen.extraStortingen"></CheckBox>
        <CheckBox caption="Onttrekkingen" name="verrekenen.onttrekkingen"></CheckBox>
        <CheckBox caption="Extra opnames" name="verrekenen.extraOpnames"></CheckBox>
        <LabeledBevestigingInput
          caption="1e jaar alleen nieuwe situatie tonen"
          name="verrekenen.jaar1AlleenNieuweSituatie"
          tooltip={getLastenoverzichtTextResources("TooltipJaar1AlleenNieuweSituatie")}
        ></LabeledBevestigingInput>
      </div>
    </div>
  );
};

export default connect<{}, LastenoverzichtState>(Verrekenen);
