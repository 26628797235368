import React, { ReactElement, useContext } from "react";
import { FormikProps, Form } from "formik";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { UitgangspuntenState, UitgangspuntenProps, uitgangspuntenSchema } from "./infra";
import Uitleg from "./uitleg/uitleg";
import Werkloosheid from "./werkloosheid/werkloosheid";
import Pensioen from "./pensioen/pensioen";
import Overlijden from "./overlijden/overlijden";
import Arbeidsongeschiktheid from "./arbeidsongeschiktheid/arbeidsongeschiktheid";
import { AutorisatieNiveauManager } from "../autorisatieniveau-manager/autorisatieniveau-manager";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { SaveButton } from "../save-button/save-button";
import { CardWrapper, Card, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Debug } from "../shared/components/formik/Debug";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

const Uitgangspunten = (props: FormikProps<UitgangspuntenState> & WithSaveData<UitgangspuntenState>): ReactElement => {
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  return (
    <Form>
      <PlatformFoutenSamenvatting />

      <fieldset
        disabled={!(isBeheerder || autorisatieNiveau.uitgangspuntenAdviesNiveau === InstellingNiveauOptions.Medewerker)}
      >
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="p-2">
              <h2 className="p-0">Uitgangspunten Advies&nbsp;</h2>
              <AutorisatieNiveauManager manageFor={autorisatieNiveau.uitgangspuntenAdviesNiveau} />
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <CardWrapper maxRowCount={1} className="px-3">
          <Card title="Let op">
            <Uitleg />
          </Card>
        </CardWrapper>
        <CardWrapper className="px-3" maxRowCount={7}>
          <Card title="Werkloosheid">
            <Werkloosheid namePrefix="werkloosheid" />
          </Card>
          <Card title="Pensioen">
            <Pensioen namePrefix="pensioen" />
          </Card>
          <Card title="Overlijden">
            <Overlijden namePrefix="overlijden" />
          </Card>
          <Card title="Arbeidsongeschiktheid">
            <Arbeidsongeschiktheid namePrefix="arbeidsongeschiktheid" />
          </Card>
        </CardWrapper>
      </fieldset>

      {/* istanbul ignore next */
      process.env.NODE_ENV === "development" && <Debug />}
    </Form>
  );
};

Uitgangspunten.displayName = "Uitgangspunten";

export default withAdviesboxFormik<UitgangspuntenProps & WithSaveData<UitgangspuntenState>, UitgangspuntenState>({
  mapPropsToValues: (e: UitgangspuntenProps): UitgangspuntenState => e,
  validationSchema: uitgangspuntenSchema
})(Uitgangspunten);
