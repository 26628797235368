import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { LabeledRadioInput, LabeledCurrencyInput } from "adviesbox-shared";
import { TarievenState } from "../infra";
import {
  VastBedragBedragPerUurOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";

const AdvieskostenZonderHypotheek = ({
  formik: {
    setFieldValue,
    values: { advieskostenZonderHypotheek }
  }
}: {
  formik: FormikContextType<TarievenState>;
}): ReactElement => {
  return (
    <div>
      <LabeledRadioInput
        caption="Advieskosten"
        name="advieskostenZonderHypotheek.adviesKosten"
        options={[
          {
            label: "Vast bedrag",
            value: VastBedragBedragPerUurOptions.VastBedrag
          },
          {
            label: "Per uur",
            value: VastBedragBedragPerUurOptions.BedragPerUur
          }
        ]}
      />
      <LabeledCurrencyInput
        caption="Bedrag Advieskosten"
        name="advieskostenZonderHypotheek.bedragAdviesKosten"
        appendChildren={
          advieskostenZonderHypotheek.adviesKosten === VastBedragBedragPerUurOptions.BedragPerUur ? (
            <div className="d-flex align-items-center h-100 ml-2">
              <span>Per uur</span>
            </div>
          ) : null
        }
      />
      <LabeledRadioInput
        caption="Bemiddelingsvergoeding"
        name="advieskostenZonderHypotheek.bemiddelingsVergoeding"
        options={[
          {
            label: "Vast bedrag",
            value: VastBedragBedragPerUurOptions.VastBedrag
          },
          {
            label: "Per uur",
            value: VastBedragBedragPerUurOptions.BedragPerUur
          }
        ]}
      />
      <LabeledCurrencyInput
        caption="Bedrag Bemiddelingsvergoeding"
        name="advieskostenZonderHypotheek.bedragBemiddelingsVergoeding"
        appendChildren={
          advieskostenZonderHypotheek.bemiddelingsVergoeding ===
          VastBedragBedragPerUurOptions.BedragPerUur ? (
            <div className="d-flex align-items-center h-100 ml-2">
              <span>Per uur</span>
            </div>
          ) : null
        }
      />
    </div>
  );
};

export default connect<{}, TarievenState>(AdvieskostenZonderHypotheek);
