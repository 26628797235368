import React, {
  ReactElement,
  useRef,
  RefObject,
  ChangeEvent,
  useContext,
} from "react";
import { FormikContextType } from "formik";
import { Column, CellInfo } from "react-table-6";
import { CheckBox, Icon, ModalButton } from "adviesbox-shared";
import classes from "./beschikbare-software-koppelingen.module.scss";
import { SoftwareKoppelingenType, SoftwareKoppelingType } from "../infra";
import classnames from "classnames";
import { ElementsInstellingenModal } from "../elements-instellingen-modal/elements-instellingen-modal";
import { HdnInstellingenModal } from "../hdn-instellingen-modal/hdn-instellingen-modal";
import { SaveFormContext } from "../../shared/save-form/save-form-contex";
import { ImportkoppelingInstellingenModal } from "../importkoppeling-instellingen-modal/importkoppeling-instellingen-modal";

/* istanbul ignore next */
export const EmptyComponent = (): null => null;

export const DeleteButton = (
  formik: FormikContextType<SoftwareKoppelingenType>
) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;

  const onClick = (): void => {
    const curr = values.partijen;
    curr.splice(c.index, 1);
    setFieldValue("partijen", curr);
    setFieldValue("dirty", true);
  };

  if (!values.partijen[c.index].wijzigbaar) {
    return <div />;
  }

  return (
    <div
      data-testid={`delete-software-koppeling-${c.index}`}
      id={`delete-software-koppeling-${c.index}`}
      onClick={onClick}
    >
      <Icon name="prullenbak" alt="prullenbak" />
    </div>
  );
};

export const createActiveCheckboxCell = (c: CellInfo): ReactElement => {
  return (
    <div className={classes.clickable_label_fix}>
      <CheckBox
        name={`partijen[${c.index}].actief`}
        caption=""
        disabled={false}
      />
    </div>
  );
};

export const createTextInputSoftwareKoppelingNaamCell = (
  formik: FormikContextType<SoftwareKoppelingenType>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;
  const inputElement: RefObject<HTMLInputElement> | null = useRef(null);

  const onMouseUp = (): void => {
    inputElement.current?.focus();
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`partijen[${c.index}].naam`, e.target.value);
  };

  return (
    <div
      className={classnames(
        !values.partijen[c.index].wijzigbaar ? "not-underlined" : "",
        "input-group"
      )}
    >
      <input
        readOnly={!values.partijen[c.index].wijzigbaar}
        disabled={!values.partijen[c.index].wijzigbaar}
        data-testid={`software-koppeling-naam-input-${c.index}`}
        id={`software-koppeling-naam-input-${c.index}`}
        type="text"
        className={classnames(
          "input_control",
          "grid-cell",
          !values.partijen[c.index].wijzigbaar ? "not-underlined" : ""
        )}
        defaultValue={values.partijen[c.index].naam}
        onBlur={onBlur}
        ref={inputElement}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

export const softwareKoppelingInstellingenCell = (
  formik: FormikContextType<SoftwareKoppelingenType>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;
  const { saveData } = useContext(SaveFormContext);

  const softwareKoppeling = values.partijen[c.index];
  const naam = softwareKoppeling.naam;
  const isImportKoppeling = softwareKoppeling.isImportkoppeling;

  const getModalContent = (): ReactElement => {
    switch (true) {
      case isImportKoppeling:
        return (
          <ImportkoppelingInstellingenModal
            importkoppelingId={softwareKoppeling.softwareKoppelingId}
            onSave={() => {}}
          />
        );
      case naam === "Elements":
        return <ElementsInstellingenModal onSave={() => {}} />;
      default:
        return (
          <HdnInstellingenModal
            data={softwareKoppeling}
            onSave={(data: SoftwareKoppelingType) => {
              setFieldValue(
                `partijen[${c.index}].hdnNummer`,
                data.hdnNummer || null
              );
              saveData();
            }}
          />
        );
    }
  };

  const modalContent = getModalContent();

  return (
    <ModalButton
      parent={`software-koppeling-${c.index}`}
      aria-label="Instellingen software koppeling button"
      content={
        <Icon name="specificatie" alt="Instellingen software koppeling" />
      }
      size="lg"
    >
      {modalContent}
    </ModalButton>
  );
};

export const getColumns = (
  formik: FormikContextType<SoftwareKoppelingenType>
): Column[] => {
  return [
    {
      Header: "Software koppeling",
      Cell: createTextInputSoftwareKoppelingNaamCell(formik),
    },
    {
      Header: "Actief",
      Cell: createActiveCheckboxCell,
      maxWidth: 100,
    },
    {
      Header: "Instellingen",
      Cell: softwareKoppelingInstellingenCell(formik),
    },
    {
      Cell: DeleteButton(formik),
      maxWidth: 30,
    },
  ];
};
