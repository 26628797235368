/* istanbul ignore file */

/**
 * DEZE FILE STAAT NIET ONDER TEST. WIJZIGINGEN -ALTIJD- CONTROLEREN!
 */

import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit } from "adviesbox-shared";

export type HdnBerichtSoortType = "AX" | "DA" | "DX" | "KX";
export type UseHdnDataResult = {
  error: null | Error;
  hdnKeuzelijst: { [index: string]: string };
};

export function useHdnData(berichtSoortType: HdnBerichtSoortType, keuzelijst: string): UseHdnDataResult {
  const {
    requestInit,
    settings: { hdnKeuzelijstenOrigin }
  } = useRequestInit();
  const url = `${hdnKeuzelijstenOrigin}/${berichtSoortType}/${keuzelijst}`;
  const { error, data } = useAbortableFetch<UseHdnDataResult>(url, requestInit);

  const listWithLoadingEntry = { "-1": "Laden..." };

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van HDN data"), hdnKeuzelijst: {} };
  }

  return { error, hdnKeuzelijst: !data || !data.hdnKeuzelijst ? listWithLoadingEntry : data.hdnKeuzelijst };
}
