import * as Yup from "yup";

// If you want global module augmentation instead
// declare module "yup" {
//     function enumeration<T>(e: T): Yup.MixedSchema<T[keyof T]>;
// }
// Yup.enumeration = <T>(e: T): Yup.MixedSchema<T[keyof T]> => {
//     return Yup.mixed<T[keyof T]>().oneOf(Object.values(e))
// }

export function yupEnum<T>(e: T): Yup.MixedSchema<T[keyof T]> {
  return Yup.mixed<T[keyof T]>().oneOf(Object.values(e));
}

export function yupNullableEnum<T>(e: T): Yup.MixedSchema<T[keyof T] | null> {
  return Yup.mixed<T[keyof T] | null>().oneOf([...Object.values(e), null]);
}
