/* istanbul ignore file */
import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";
import { SubmitResultTypes } from "../../utils/save-validation";

type ValidatiePopupProps = {
  infotekst: SubmitResultTypes | "browserInfo" | "navigatieInfo";
  show: boolean;
  onHide: () => void;
  onSubmit?: () => void;
  navigatiepopup?: boolean;
};
const ValidatiePopup = ({ ...props }: ValidatiePopupProps): ReactElement => {
  return (
    <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.infotekst === "browserInfo" && "Er zijn niet opgeslagen gegevens op deze pagina"}
          {props.infotekst !== "browserInfo" && "Er zijn niet gevalideerde gegevens op deze pagina"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-modal">
        <>
          {props.infotekst === "navigatieInfo" && (
            <p>
              Er zijn niet gevalideerde gegevens op deze pagina. Als je toch van pagina wisselt dan wordt alle door jou
              gewijzigde data niet opgeslagen. De niet gevalideerde onderdelen zijn met rood gemarkeerd.
            </p>
          )}
          {(props.infotekst === "clientError" || props.infotekst === "platformError") && (
            <p>
              De rode velden op de pagina kunnen niet gevalideerd worden. Zorg ervoor dat er geen rode velden meer op de
              pagina staan en probeer dan nogmaals op te slaan.
            </p>
          )}
          {props.infotekst === "browserInfo" && (
            <>
              <p>
                Er zijn niet opgeslagen gegevens op deze pagina. Als je toch van pagina wisselt dan wordt alle door jou
                gewijzigde data niet opgeslagen.
              </p>
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <>
          {props.navigatiepopup ? (
            <>
              <Button id="btnannuleren" data-testid="btnannuleren" variant="link" onClick={props.onHide}>
                Annuleren
              </Button>
              <Button id="btnopslaan" data-testid="btnopslaan" variant="danger" onClick={props.onSubmit}>
                Verwijder mijn aanpassingen en navigeer
              </Button>
            </>
          ) : (
            <Button id="btnopslaan" data-testid="btnopslaan" variant="primary" onClick={props.onHide}>
              Sluiten
            </Button>
          )}
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ValidatiePopup;
