import React, { ReactElement, InputHTMLAttributes } from "react";
import HdnKeuzelijst, { HdnKeuzelijstProps } from "./HdnKeuzelijst";
import classnames from "classnames";
import { LabelProps, Label } from "adviesbox-shared";

type LabeledHdnKeuzelijstProps = LabelProps & HdnKeuzelijstProps;

const LabeledHdnKeuzelijst = ({
  berichtSoortType,
  caption,
  keuzelijst,
  name,
  tooltip,
  appendChildren,
  readonly,
  visible,
  fieldSize,
  emptyValue,
  labelColSize = 7,
  onChange,
  useHdnData
}: LabeledHdnKeuzelijstProps & InputHTMLAttributes<HTMLSelectElement>): ReactElement => {
  const inputColSize = labelColSize < 12 ? 12 - labelColSize : 12;

  return (
    <div
      className={classnames("form-group form-row", {
        invisible: visible === false
      })}
    >
      <div className={`col-${labelColSize}`}>
        <Label caption={caption} name={name} tooltip={tooltip} />
      </div>
      <div className={`col-${inputColSize}`}>
        <HdnKeuzelijst
          berichtSoortType={berichtSoortType}
          keuzelijst={keuzelijst}
          name={name}
          appendChildren={appendChildren}
          readonly={readonly}
          fieldSize={fieldSize}
          emptyValue={emptyValue}
          onChange={onChange}
          useHdnData={useHdnData}
        />
      </div>
    </div>
  );
};

export default LabeledHdnKeuzelijst;
