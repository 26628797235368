import { useContext, useEffect } from 'react';
import { 
  getCookie, 
  getOTAP, 
  setCookie,
  useRequestInit,
  SettingsContext 
} from 'adviesbox-shared';
import useAbortableFetch from 'use-abortable-fetch';
import { 
  VestigingenOutput, 
  MedewerkerOrganisatieOutput 
} from './.generated/licenties/licentiestypes';

interface UseVestigingFallbackResult {
  loading: boolean;
  error: Error | null;
  vestigingId: string | null;
}

const getHoofdvestigingId = (data: VestigingenOutput | null | string): string | null => {
  if (!data || typeof data === 'string' || !data.isValid || !data.vestigingen) {
    return null;
  }

  const hoofdvestigingEntry = Object.entries(data.vestigingen)
    .find(([_, vestiging]) => vestiging.isHoofdvestiging === true);

  return hoofdvestigingEntry ? hoofdvestigingEntry[0] : null;
};

export const useCurrentVestiging = (): UseVestigingFallbackResult => {
  const settings = useContext(SettingsContext);
  const { requestInit, user } = useRequestInit();
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  const medewerkerUrl = user ? `${settings.licentiesOrigin}/Medewerkers/current` : null;
  const { 
    data: medewerkerData, 
    loading: medewerkerLoading, 
    error: medewerkerError 
  } = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);

  const medewerkerId = medewerkerData && typeof medewerkerData !== 'string' 
    ? medewerkerData.medewerkerId 
    : null;

  const vestigingenUrl = medewerkerId 
    ? `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Vestigingen` 
    : null;

  const { 
    data: vestigingenData, 
    loading: vestigingenLoading, 
    error: vestigingenError 
  } = useAbortableFetch<VestigingenOutput>(vestigingenUrl, requestInit);

  useEffect(() => {
    // als de cookie al bestaat, early return geen cookie overschrijven
    if (vestigingenLoading || vestigingenError || vestigingIdCookie) return;

    const hoofdvestigingId = getHoofdvestigingId(vestigingenData);
    if (!hoofdvestigingId) {
      /* eslint-disable no-console */
      console.warn('No hoofdvestiging found');
      return;
    }

    const cookieDomain = window.location.hostname.includes('localhost') 
      ? window.location.hostname 
      : 'adviesbox.nl';

    const cookieName = `${getOTAP(window.location.hostname)}_vestigingid`;
    /* eslint-disable no-console */
    console.log('Setting fallback hoofdvestigingId cookie:', hoofdvestigingId);
    setCookie(cookieName, hoofdvestigingId, cookieDomain);
  }, [vestigingenData, vestigingenLoading, vestigingenError, vestigingIdCookie]);

  return {
    loading: medewerkerLoading || vestigingenLoading,
    error: medewerkerError || vestigingenError,
    vestigingId: vestigingIdCookie && typeof vestigingIdCookie === "string"
      ? vestigingIdCookie 
      : getHoofdvestigingId(vestigingenData),
  };
};