import { createContext } from "react";
import { Autorisatieniveau, InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";

/* istanbul ignore next */
export const defaultSettings: {
  autorisatieNiveau: Autorisatieniveau;
  setAutorisatieNiveau: (autorisatieNiveau: Autorisatieniveau) => void;
  isBeheerder: boolean;
  setIsBeheerder: (isBeheerder: boolean) => void;
} = {
  isBeheerder: false,
  setIsBeheerder: (_isbeheerder: boolean) => {},
  autorisatieNiveau: {
    algemeenNiveau: InstellingNiveauOptions.Medewerker,
    documentNiveau: InstellingNiveauOptions.Medewerker,
    tarievenNiveau: InstellingNiveauOptions.Medewerker,
    berekeningenNiveau: InstellingNiveauOptions.Medewerker,
    lastenoverzichtenNiveau: InstellingNiveauOptions.Medewerker,
    geldverstrekkersNiveau: InstellingNiveauOptions.Medewerker,
    verzekeraarsNiveau: InstellingNiveauOptions.Medewerker,
    vermogensbeheerdersNiveau: InstellingNiveauOptions.Medewerker,
    bankgarantieInstellingenNiveau: InstellingNiveauOptions.Medewerker,
    taxatiebureausNiveau: InstellingNiveauOptions.Medewerker,
    notarissenNiveau: InstellingNiveauOptions.Medewerker,
    serviceprovidersNiveau: InstellingNiveauOptions.Medewerker,
    klantprofielMotiveringNiveau: InstellingNiveauOptions.Medewerker,
    adviesonderwerpenNiveau: InstellingNiveauOptions.Medewerker,
    uitgangspuntenAdviesNiveau: InstellingNiveauOptions.Medewerker,
    standaardTekstenNiveau: InstellingNiveauOptions.Medewerker,
    generiekeKoppelingenNiveau: InstellingNiveauOptions.Medewerker,
    hdnNiveau: InstellingNiveauOptions.Medewerker,
    elementsNiveau: InstellingNiveauOptions.Medewerker,
    nationaleHypotheekbondNiveau: InstellingNiveauOptions.Medewerker,
    nationaleWaarborgNiveau: InstellingNiveauOptions.Medewerker,
    softwareKoppelingenNiveau: InstellingNiveauOptions.Medewerker
  },
  setAutorisatieNiveau: (_autorisatieNiveau: Autorisatieniveau): void => {}
};

export type AutorisatieNiveauContextType = typeof defaultSettings;

const AutorisatieNiveauContext = createContext(defaultSettings);

export default AutorisatieNiveauContext;
