import {
  Card,
  CardWrapper,
  ErrorPage,
  FormFirstFocus,
  ModalButton,
  uInt8ArrayToDownload,
  useRequestInit,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useContext, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { SaveButton } from "../save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import { saveDataWithFiles } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { standaardTekstenImporterenExporterenSchema } from "./infra/standaard-teksten-schema";
import { StandaardTekstenImporterenExporterenState } from "./infra/standaard-teksten-types";
import { mapStandaardTekstenImporterenExporterenDlTargetToUiField } from "./map-standaard-teksten-importeren-exporteren-dl-target-to-ui";
import { StandaardTekstenImporterenModal } from "./standaard-teksten-importeren-modal";

export type StandaardTekstenImporterenExporterenProps = StandaardTekstenImporterenExporterenState;

const StandaardTekstenImporterenExporterenComponent = (
  props: FormikProps<StandaardTekstenImporterenExporterenState> & StandaardTekstenImporterenExporterenProps
): ReactElement => {
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  const filesRef = useRef<File[]>([]);
  const [filesLength, setFilesLength] = useState<number>(0);

  const { params, user, settings, requestInit } = useRequestInit();

  const url = `${settings.instellingenFormsOrigin}/StandaardTeksten/Import`;
  const formik = useFormikContext<StandaardTekstenImporterenExporterenState>();
  const { setFieldValue } = formik;
  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  return (
    <FormFirstFocus>
      <Form>
        <fieldset disabled={!isBeheerder}>
          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>Standaardteksten importeren en exporteren</h2>
              <SaveButton
                callBack={() => {
                  formik.resetForm({ values: standaardTekstenImporterenExporterenSchema.default() });
                }}
                context={{
                  ...props,

                  saveData: saveDataWithFiles(
                    user,
                    params.vestiging ?? "",
                    url + `?overschrijven=${formik.values.overschrijven ? "true" : "false"}`,
                    settings.OcpApimSubscriptionKey,
                    filesRef.current,
                    mapStandaardTekstenImporterenExporterenDlTargetToUiField,
                    "standaardTekstenImportBestand"
                  )
                }}
                btnTekst={`${filesLength === 1 ? "bestand" : filesLength + " bestanden"} opslaan`}
              />
            </div>
          </CardWrapper>
          <PlatformFoutenSamenvatting />

          <div className={"px-3 w-75"}>
            <Card>
              <p className="p-3">
                Op deze pagina kan je standaardteksten importeren en exporteren. Let op, bij importeren en exporteren
                worden alle teksten die aanwezig zijn in het standaard teksten onderdeel meegenomen. Bij importeren
                krijg je een vervolgkeuze om de teksten te overschrijven of toe te voegen.
              </p>
              <div className={"text-center"}>
                <ModalButton
                  parent="standaardteksten-importeren"
                  size="lg"
                  content={"Standaardteksten importeren"}
                  className={"btn-light mr-3"}
                >
                  <StandaardTekstenImporterenModal
                    saveModal={(files: File[], overschrijven) => {
                      setFilesLength(files.length);
                      setFieldValue("newFileAdded", true);
                      setFieldValue("overschrijven", overschrijven);

                      filesRef.current = files;
                    }}
                  />
                </ModalButton>
                <Button
                  variant={"light"}
                  id={"export-btn"}
                  data-testid={"export-btn"}
                  onClick={async () => {
                    const exportUrl = `${settings.instellingenFormsOrigin}/StandaardTeksten/Export`;
                    const rsp: Response = await fetch(exportUrl, requestInit);
                    try {
                      const data = new TextEncoder().encode(await rsp.text());
                      await uInt8ArrayToDownload(
                        data,
                        rsp.headers.get("Content-Disposition")?.split("filename=")[1] || "StandaardtekstExport.json"
                      );
                    } catch (e) {
                      /* istanbul ignore next */
                      /* eslint-disable no-console */
                      if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") console.warn(e);
                    }
                  }}
                >
                  Standaardteksten exporteren
                </Button>
              </div>
            </Card>
          </div>
        </fieldset>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const StandaardTekstenImporterenExporteren = withAdviesboxFormik<
  StandaardTekstenImporterenExporterenProps,
  StandaardTekstenImporterenExporterenState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: StandaardTekstenImporterenExporterenProps): StandaardTekstenImporterenExporterenState =>
    standaardTekstenImporterenExporterenSchema.default(),
  validationSchema: standaardTekstenImporterenExporterenSchema
})(StandaardTekstenImporterenExporterenComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  StandaardTekstenImporterenExporteren.displayName = "StandaardTeksten";
