import {
  AuthContext,
  getAppLocation,
  getCookie,
  getNaamAdviseur,
  getOTAP,
  getPathname,
  HttpErrorPage,
  NotificatiesProvider,
  SettingsContext,
  useUpscope,
  Footer,
  FeatureComponent,
  Notificaties2Provider
} from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";
import adviesonderwerpenAjax from "../adviesonderwerpen/adviesonderwerpen-ajax";
import autorisatieNiveauAjax from "../autorisatie-niveau/autorisatie-niveau-ajax";
import bankgarantieinstellingenAjax from "../bankgarantieinstellingen/bankgarantieinstellingen-ajax";
import berekeningenAjax from "../berekeningen/berekeningen-ajax";
import { DocumentenAjax } from "../documenten/documenten-ajax";
import Drawer from "../drawer/Drawer";
import geldverstrekkersAjax from "../geldverstrekkers/geldverstrekkers-ajax";
import klantprofielMotiveringAjax from "../klantprofiel-motivering/klantprofiel-motivering-ajax";
import koppelingschermAjax from "../koppeling-adviseur-bemiddelaar/koppelingscherm-ajax";
import lastenoverzichtAjax from "../lastenoverzicht/lastenoverzicht-ajax";
import notarissenAjax from "../notarissen/notarissen-ajax";
import { RapportageSamenstellenAjax } from "../rapportage/rapportage-samenstellen-ajax";
import serviceprovidersAjax from "../serviceproviders/serviceproviders-ajax";
import { Title } from "../shared/components/title/title";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import ParamRouteProvider from "../shared/paramrouting/paramrouting-provider";
import { StandaardTekstenAjax } from "../standaard-teksten/standaard-teksten-ajax";
import { StandaardTekstenImporterenExporteren } from "../standaard-teksten/standaard-teksten-importeren-exporteren";
import tarievenAjax from "../tarieven/tarieven-ajax";
import taxatiebureausAjax from "../taxatiebureaus/taxatiebureaus-ajax";
import TopNavbar from "../topnav-dossier/TopNavbar";
import uitgangspuntenAjax from "../uitgangspunten/uitgangspunten-ajax";
import UserDetailsContext from "../user-details/user-details-context";
import vermogensbeheerdersAjax from "../vermogensbeheerders/vermogensbeheerders-ajax";
import verzekeraarsAjax from "../verzekeraars/verzekeraars-ajax";
import NationaleWaarborgAjax from "../externe-koppelingen/nationale-waarborg/nationalewaarborg-ajax";
import "./AuthenticatedInstellingenApp.scss";
import softwareKoppelingenAjax from "../software-koppelingen/software-koppelingen-ajax";
import AbcKoppeling from "../abc-koppeling/abc-koppeling";

const InnerAuthenticatedInstellingenApp = ({params, userDetails}: any): ReactElement => {
  const settingsContext = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "INS", "POR", settingsContext.baseUrls);
  const { user } = useContext(AuthContext);
  useUpscope(user?.profile.name, [getNaamAdviseur(userDetails)]);
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  return (<div data-testid="authenticated-app">
    <Drawer>
      <Title appName="Instellingen" />
      <TopNavbar />
      <fieldset>
        <div className="authenticated-schermen">
          <Switch>
            {/* Start page route redirect */}
            <Route path="/vestiging/:vestiging/koppeling" component={AbcKoppeling} exact/>
            <Route
              exact
              path={"/vestiging/:vestiging/"}
              render={() => {
                return <Redirect to={`/vestiging/${params.vestiging}/autorisatieniveau`} />;
              }}
            />
            <Route path={"/vestiging/:vestiging/autorisatieniveau"} component={autorisatieNiveauAjax} />
            <Route
              path="/vestiging/:vestiging/vaste-koppeling-advies-en-bemiddeling"
              component={koppelingschermAjax}
            />
            <Route path="/vestiging/:vestiging/tarieven" component={tarievenAjax} />
            <Route path="/vestiging/:vestiging/documenten" component={DocumentenAjax} />
            <Route
              path="/vestiging/:vestiging/standaardteksten/importeren-en-exporteren"
              component={StandaardTekstenImporterenExporteren}
            />
            <Route path="/vestiging/:vestiging/standaardteksten/:soort/:onderwerp">
              <StandaardTekstenAjax />
            </Route>
            <Route path="/vestiging/:vestiging/standaardteksten/algemeen" component={StandaardTekstenAjax} />
            <Route path="/vestiging/:vestiging/rapportage-samenstellen" component={RapportageSamenstellenAjax} />
            <Route path="/vestiging/:vestiging/adviesonderwerpen" component={adviesonderwerpenAjax} />
            <Route path="/vestiging/:vestiging/verzekeraars" component={verzekeraarsAjax} />
            <Route
              path="/vestiging/:vestiging/bankgarantie-instellingen"
              component={bankgarantieinstellingenAjax}
            />
            <Route path="/vestiging/:vestiging/vermogensbeheerders" component={vermogensbeheerdersAjax} />
            <Route path="/vestiging/:vestiging/notarissen" component={notarissenAjax} />
            <Route path="/vestiging/:vestiging/geldverstrekkers" component={geldverstrekkersAjax} />
            <Route path="/vestiging/:vestiging/taxatiebureaus" component={taxatiebureausAjax} />
            <Route path="/vestiging/:vestiging/serviceproviders" component={serviceprovidersAjax} />
            <Route path="/vestiging/:vestiging/software-koppelingen" component={softwareKoppelingenAjax} />
            <Route path="/vestiging/:vestiging/klantprofiel-en-motivering" component={klantprofielMotiveringAjax} />
            <Route path="/vestiging/:vestiging/berekeningen" component={berekeningenAjax} />
            <Route path="/vestiging/:vestiging/lastenoverzicht" component={lastenoverzichtAjax} />
            <Route path="/vestiging/:vestiging/uitgangspunten-Advies" component={uitgangspuntenAjax} />
            <Route path="/vestiging/:vestiging/extern/nationale-waarborg" component={NationaleWaarborgAjax} />

            <Route
              exact
              path={"/vestiging/:vestiging"}
              render={
              /* istanbul ignore next */ ({ history }: RouteComponentProps<RouteParams>) => {
                  const base = getPathname().charAt(getPathname().length - 1);
                  if (base === "/") {
                    history.push(`${getPathname()}autorisatieniveau`);
                    return <></>;
                  }
                  history.push(`${getPathname()}/autorisatieniveau`);
                  return <></>;
                }
              }
            />
            <Route
              exact
              path={"/"}
              render={
              /* istanbul ignore next */ ({ history }: RouteComponentProps) => {
                  if (!vestigingIdCookie) {
                    window.location.assign(linkToPortal);
                    return <></>;
                  }

                  history.push(`/vestiging/${vestigingIdCookie}/autorisatieniveau`);
                  return <></>;
                }
              }
            />
            <Route>
              <HttpErrorPage status={404} returnUrl={linkToPortal} />
            </Route>
          </Switch>
        </div>
      </fieldset>
      <Footer lastMutationDate={null} />
    </Drawer>
  </div>)
}

export const AuthenticatedInstellingenApp = (): ReactElement => {
  const { params } = useRouteMatch<RouteParams>();
  const { userDetails } = useContext(UserDetailsContext);

  return (
    <ParamRouteProvider route={params}>
      <FeatureComponent feature="FeatureNotificaties2">
        <Notificaties2Provider vestigingId={params.vestiging} sourceApp={"INS"}>
          <InnerAuthenticatedInstellingenApp params={params} userDetails={userDetails} />
        </Notificaties2Provider>
      </FeatureComponent>
      <FeatureComponent feature="FeatureNotificaties2" isDisabled>
        <NotificatiesProvider reloadTimeInSeconds={60} medewerkerId={userDetails.medewerkerId} vestigingId={params.vestiging} sourceApp={"INS"}>
          <InnerAuthenticatedInstellingenApp params={params} userDetails={userDetails} />
        </NotificatiesProvider>
      </FeatureComponent>
    </ParamRouteProvider>
  );
};
