/* istanbul ignore file */
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

import {
  StandaardTeksten as StandaardTekstDlEntry,
  StandaardTekstenOutput as StandaardTekstDlOutput
} from "../../.generated/instellingen-forms/instellingen-formstypes";

import { standaardTekstenSchema } from "./standaard-teksten-schema";
import { StandaardTekstenState, StandaardTekstType } from "./standaard-teksten-types";

const mapStandaardTeksten = createMapToUi(standaardTekstenSchema).from<StandaardTekstDlEntry>({
  teksten: v =>
    v.standaardTekstenMotiveringOnderwerp.map(
      (c): StandaardTekstType => {
        return {
          korteOmschrijving: c.korteOmschrijving,
          standaardTekst: c.tekst,
          tekstVoor: c.standaardtekstVoor
        };
      }
    )
});

export function mapStandaardTekstenDlToUi(data: StandaardTekstDlOutput): StandaardTekstenState | null {
  const standaardteksten =
    data && data.isValid && data.standaardTeksten && data.standaardTeksten.standaardTekstenMotiveringOnderwerp
      ? data.standaardTeksten
      : null;

  if (standaardteksten) {
    return mapStandaardTeksten(standaardteksten);
  }

  return null;
}
