import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { ElementsInstellingenType } from "../infra";

export const softwareKoppelingWijzigenModalApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  elementsInstellingen: ElementsInstellingenType,
): Promise<boolean> => {
  const url = `${settings.instellingenFormsOrigin}/ExterneKoppelingen/Elements`;
  const body = { ...elementsInstellingen, wachtwoordApi: elementsInstellingen.wachtwoordApi || null };
  const rsp = await fetch(url, {
    method: "PUT",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId,
    },
    body: JSON.stringify(body)
  });

  if (!rsp.ok) {
    throw Error(`Fout bij aanroep van web-api '${url}'.`);
  }

  return true;
};
