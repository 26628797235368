import React, { ReactElement, ChangeEvent, useRef, RefObject } from "react";
import { FormikContextType } from "formik";
import { Column, CellInfo } from "react-table-6";
import { CheckBox, Icon } from "adviesbox-shared";
import classes from "./beschikbare-serviceproviders.module.scss";
import { ServiceprovidersState } from "../infra";
import classnames from "classnames";

export const EmptyComponent = (): null => null;

export const DeleteButton = (formik: FormikContextType<ServiceprovidersState>) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;

  const onClick = (): void => {
    const curr = values.beschikbareServiceproviders.partijen;
    curr.splice(c.index, 1);
    setFieldValue("beschikbareServiceproviders.partijen", curr);
    setFieldValue("dirty", true);
  };

  if (!values.beschikbareServiceproviders.partijen[c.index].wijzigbaar) {
    return <div />;
  }

  return (
    <div data-testid={`delete-serviceprovider-${c.index}`} id={`delete-serviceprovider-${c.index}`} onClick={onClick}>
      <Icon name="prullenbak" alt="prullenbak" />
    </div>
  );
};

export const createActiveCheckboxCell = (c: CellInfo): ReactElement => {
  return (
    <div className={classes.clickable_label_fix}>
      <CheckBox name={`beschikbareServiceproviders.partijen[${c.index}].actief`} caption="" disabled={false} />
    </div>
  );
};

export const createTextInputServiceProviderNaamCell = (
  formik: FormikContextType<ServiceprovidersState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;
  const inputElement: RefObject<HTMLInputElement> | null = useRef(null);

  const onMouseUp = (): void => {
    inputElement.current?.focus();
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`beschikbareServiceproviders.partijen[${c.index}].naam`, e.target.value);
  };

  return (
    <div
      className={classnames(
        !values.beschikbareServiceproviders.partijen[c.index].wijzigbaar ? "not-underlined" : "",
        "input-group"
      )}
    >
      <input
        readOnly={!values.beschikbareServiceproviders.partijen[c.index].wijzigbaar}
        disabled={!values.beschikbareServiceproviders.partijen[c.index].wijzigbaar}
        data-testid={`serviceprovidernaam-input-${c.index}`}
        id={`serviceprovidernaam-input-${c.index}`}
        type="text"
        // ../../shared/components/tekst-input/TextInput.module.scss: input_control
        className={classnames(
          "input_control",
          "grid-cell",
          !values.beschikbareServiceproviders.partijen[c.index].wijzigbaar ? "not-underlined" : ""
        )}
        defaultValue={values.beschikbareServiceproviders.partijen[c.index].naam}
        onBlur={onBlur}
        ref={inputElement}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

export const createTextInputHdnNummerCell = (
  formik: FormikContextType<ServiceprovidersState>
): ((c: CellInfo) => ReactElement) => (c: CellInfo): ReactElement => {
  const { values, setFieldValue } = formik;
  const inputElement: RefObject<HTMLInputElement> | null = useRef(null);

  const onMouseUp = (): void => {
    inputElement.current?.focus();
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(`beschikbareServiceproviders.partijen[${c.index}].hdnNummer`, e.target.value);
  };

  return (
    <div
      className={classnames(
        !values.beschikbareServiceproviders.partijen[c.index].wijzigbaar ? "not-underlined" : "",
        "input-group"
      )}
    >
      <input
        readOnly={!values.beschikbareServiceproviders.partijen[c.index].wijzigbaar}
        disabled={!values.beschikbareServiceproviders.partijen[c.index].wijzigbaar}
        data-testid={`hdnNummer-input-${c.index}`}
        id={`hdnNummer-input-${c.index}`}
        type="text"
        // ../../shared/components/tekst-input/TextInput.module.scss: input_control
        className={classnames("input_control", "grid-cell")}
        defaultValue={values.beschikbareServiceproviders.partijen[c.index].hdnNummer || ""}
        onBlur={onBlur}
        ref={inputElement}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

export const getColumns = (formik: FormikContextType<ServiceprovidersState>): Column[] => {
  return [
    {
      Header: "Serviceprovider",
      Cell: createTextInputServiceProviderNaamCell(formik)
    },
    {
      Header: "Actief",
      Cell: createActiveCheckboxCell,
      maxWidth: 100
    },
    {
      Header: "HDN Nummer",
      Cell: createTextInputHdnNummerCell(formik)
    },
    {
      Cell: DeleteButton(formik),
      maxWidth: 30
    }
  ];
};
