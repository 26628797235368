import * as Yup from "yup";
import { yupEnum } from "../../shared/utils/yup-enum";
import { StandaardtekstVoorOptions } from "../../.generated/instellingen-forms/instellingen-formstypes";

export const tekstSchema = Yup.object({
  tekstVoor: yupEnum(StandaardtekstVoorOptions),
  korteOmschrijving: Yup.string().nullable(),
  standaardTekst: Yup.string().nullable()
});

export const standaardTekstenSchema = Yup.object({
  teksten: Yup.array(tekstSchema).default([])
});

export const standaardTekstenImporterenExporterenSchema = Yup.object({
  overschrijven: Yup.bool().default(false),
  newFileAdded: Yup.bool()
});
