/* istanbul ignore file */
import { UiName, FieldMap } from "../shared/types";
import { target2field } from "../shared/utils/target-to-field";
import { StandaardTekstenImporterenExporterenState } from "./infra/standaard-teksten-types";

export function mapStandaardTekstenImporterenExporterenDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<StandaardTekstenImporterenExporterenState> = {};

  return target2field(map, target);
}
