import { AuthContext, PageLoading } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AuthenticatedInstellingenApp } from "./AuthenticatedInstellingenApp";
import UnauthenticatedInstellingenApp from "./UnauthenticatedInstellingenApp";
import { useCurrentVestiging } from "../use-current-vestiging";

const InstellingenApp = (): ReactElement => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const { loading, vestigingId } = useCurrentVestiging();
  
  /* istanbul ignore next */
  useEffect(() => {
    if (loading) {
      return;
    }   
    // bij openen '/koppeling' direct doorsturen naar '/vestiging/:vestiging/koppeling'
    if (location.pathname === '/koppeling' && vestigingId) {
      history.push(`/vestiging/${vestigingId}/koppeling`);
    }
  }, [location.pathname, vestigingId, history, loading]);

  /* istanbul ignore next */
  if (loading) {
    return <PageLoading />;
  } 

  if (user) {
    return (
      <div data-testid="authenticated-routes">
        <Switch>
          <Route path="/vestiging/:vestiging" component={AuthenticatedInstellingenApp} />
        </Switch>
      </div>
    );
  }

  return <UnauthenticatedInstellingenApp />;
};

InstellingenApp.displayName = "InstellingenApp";

export default InstellingenApp;