import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { VermogensbeheerdersState } from "../infra";
import { getColumns, EmptyComponent } from "./beheerders-data-grid-components";
import { DataGrid } from "adviesbox-shared";

const Beschikbarebeheerders = ({ formik }: { formik: FormikContextType<VermogensbeheerdersState> }): ReactElement => {
  const { values } = formik;

  return (
    <>
      <DataGrid
        name="beschikbarebeheerders.partijen"
        editable={true}
        resizable={false}
        sortable={false}
        filterable={false}
        columns={getColumns(formik)}
        minRows={values.beschikbarebeheerders.partijen.length || 0}
        pageSize={values.beschikbarebeheerders.partijen.length || 0}
        showPagination={false}
        NoDataComponent={EmptyComponent}
      />
    </>
  );
};

export default connect<{}, VermogensbeheerdersState>(Beschikbarebeheerders);
