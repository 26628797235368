import { jaarMaandInputSchema, mapPeriodeInMaandenDl2Ui } from "adviesbox-shared";
import * as Yup from "yup";
import { InferType } from "yup";
import {
  AoScenarioOptions,
  ControleGewenstNbiOptions,
  ControleGewenstInkomenOptions,
  ControleMaandlastOptions,
  DuurOverzichtOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { yupEnum } from "../../shared/utils/yup-enum";

export const controleHypotheeknormSchema = Yup.object({
  controleMaandlast: Yup.boolean().default(true),
  controleMaandlastEnumWaarde: yupEnum(ControleMaandlastOptions).default(ControleMaandlastOptions.BenodigdInkomen),
  controleerMaximaleHypotheek: Yup.boolean().default(true)
});

export const controleGewenstInkomenSchema = Yup.object({
  controleOpGewenstInkomen: yupEnum(ControleGewenstInkomenOptions).default(
    ControleGewenstInkomenOptions.NettoBesteedbaarInkomen
  ),
  controleGewenstNbi: yupEnum(ControleGewenstNbiOptions).default(ControleGewenstNbiOptions.BehoudenHuidigNbi),
  margeOndergrensPercentage: Yup.number()
    .nullable()
    .default(null),
  margeBovengrensPercentage: Yup.number()
    .nullable()
    .default(null),
  vastBedrag: Yup.number()
    .nullable()
    .default(null),
  huidigNbiPercentage: Yup.number()
    .nullable()
    .default(null),
  gewijzigdeJarenTonen: Yup.boolean()
    .nullable()
    .default(true),
  benodigdKapitaalTonen: Yup.boolean()
    .nullable()
    .default(true)
});

export const uitgangspuntenToetsingSchema = Yup.object({
  controleHypotheeknorm: controleHypotheeknormSchema,
  controleGewenstInkomen: controleGewenstInkomenSchema
});

export const werkloosheidSchema = Yup.object({
  controleHypotheeknorm: controleHypotheeknormSchema,
  controleGewenstInkomen: controleGewenstInkomenSchema
});

export const pensioenSchema = Yup.object({
  controleHypotheeknorm: controleHypotheeknormSchema,
  controleGewenstInkomen: controleGewenstInkomenSchema
});

export const overlijdenSchema = Yup.object({
  duurOverzicht: yupEnum(DuurOverzichtOptions).default(DuurOverzichtOptions.Levenslang),
  overzichtDuurInJaren: Yup.number()
    .nullable()
    .default(10),
  overzichtTotLeeftijdInMaanden: jaarMaandInputSchema.default(mapPeriodeInMaandenDl2Ui(67 * 12)),
  controleHypotheeknorm: controleHypotheeknormSchema,
  controleGewenstInkomen: controleGewenstInkomenSchema
});

export const arbeidsongeschiktheidScenarioSchema = Yup.object({
  scenario: yupEnum(AoScenarioOptions).default(AoScenarioOptions.Standaard),
  aoPercentage: Yup.number().default(50),
  duurzaamAo: Yup.boolean()
    .nullable()
    .default(false),
  verdiencapaciteitPercentage: Yup.number().default(50)
});

export const arbeidsongeschiktheidSchema = Yup.object({
  toetsjaarTekort: Yup.number().default(7),
  scenarios: Yup.array(arbeidsongeschiktheidScenarioSchema).default([
    {
      scenario: AoScenarioOptions.Standaard,
      aoPercentage: 50,
      duurzaamAo: null,
      verdiencapaciteitPercentage: 50
    }
  ]),
  controleHypotheeknorm: controleHypotheeknormSchema,
  controleGewenstInkomen: controleGewenstInkomenSchema
});

export const uitgangspuntenSchema = Yup.object({
  werkloosheid: werkloosheidSchema,
  pensioen: pensioenSchema,
  overlijden: overlijdenSchema,
  arbeidsongeschiktheid: arbeidsongeschiktheidSchema
});

export type UitgangspuntenState = InferType<typeof uitgangspuntenSchema>;
export type UitgangspuntenProps = InferType<typeof uitgangspuntenSchema>;
export type ArbeidsongeschiktheidType = InferType<typeof arbeidsongeschiktheidSchema>;
export type UitgangspuntenToetsingType = InferType<typeof uitgangspuntenToetsingSchema>;
export type OverlijdenType = InferType<typeof overlijdenSchema>;
export type PensioenType = InferType<typeof pensioenSchema>;
export type WerkloosheidType = InferType<typeof werkloosheidSchema>;
export type ControleHypotheeknormType = InferType<typeof controleHypotheeknormSchema>;
export type ControleGewenstInkomenType = InferType<typeof controleGewenstInkomenSchema>;
