import { beschikbareTaxatieProducten } from "adviesbox-shared";
import { Taxatiebureaus, TaxatiebureausOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { ProductBasis } from "../../.generated/producten/productentypes";
import { FieldMap, UiName } from "../../shared/types";
import { partijFilter } from "../../shared/utils/partij-filter";
import { target2field } from "../../shared/utils/target-to-field";
import { BeschikbareTaxatiebureausType, taxatiebureausSchema, TaxatiebureausState } from "./taxatiebureaus-schema";

function dl2ui(values: Taxatiebureaus): TaxatiebureausState {
  const taxatiebureaus = {
    ...taxatiebureausSchema.default(),
    beschikbareTaxatiebureaus: {
      ...taxatiebureausSchema.default().beschikbareTaxatiebureaus,
      partijen: values.partijen?.filter(c =>
        partijFilter({ maatschappijCode: c.code } as ProductBasis, beschikbareTaxatieProducten, true)
      )
    }
  };

  return taxatiebureausSchema.cast(taxatiebureaus);
}

export function mapTaxatiebureausDlToUi(
  taxatiebureausId: string,
  data: TaxatiebureausOutput | null
): TaxatiebureausState | null {
  const taxatiebureaus = data ? data.taxatiebureaus : null;

  if (!taxatiebureaus) {
    return null;
  }

  return dl2ui(taxatiebureaus);
}

export function mapTaxatiebureausUiToDl(taxatiebureaus: TaxatiebureausState): BeschikbareTaxatiebureausType {
  return {
    partijen: taxatiebureaus.beschikbareTaxatiebureaus.partijen
  };
}

export function mapDlTargetToTaxatiebureausUiField(target: string): UiName | null {
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
