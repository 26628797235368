/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { BankgarantieinstellingenOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { saveData } from "../shared/utils/save-data";
import Bankgarantieinstellingen from "./bankgarantieinstellingen";
import {
  mapBankgarantieinstellingenDlToUi,
  mapBankgarantieinstellingenUiToDl,
  mapDlTargetToBankgarantieinstellingenUiField
} from "./infra";

const BankgarantieinstellingenAjax = (): ReactElement => {
  // TODO: replace useRequestInit/useAbortableFetch with useAdviesboxData
  const { settings, user, params, requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/BeheerPartijen/Bankgarantieinstellingen`;
  const { loading, error, data } = useAbortableFetch<BankgarantieinstellingenOutput>(url, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("Missing AuthContext")} />;
  }

  if (error) {
    return <ErrorPage error={error} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error("Invalid data")} data={data} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  const mappedProps = mapBankgarantieinstellingenDlToUi("bankgarantieinstellingen", data);
  if (!mappedProps) {
    return <PageLoading />;
  }

  return (
    <Bankgarantieinstellingen
      saveData={saveData(
        user,
        params.vestiging ?? "",
        url,
        settings.OcpApimSubscriptionKey,
        mapBankgarantieinstellingenUiToDl,
        mapDlTargetToBankgarantieinstellingenUiField
      )}
      {...mappedProps}
    />
  );
};

BankgarantieinstellingenAjax.displayName = "BankgarantieinstellingenAjax";

export default withErrorBoundary(BankgarantieinstellingenAjax);
