import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { DataGrid } from "adviesbox-shared";
import { SoftwareKoppelingenType } from "../infra/software-koppelingen-schema";
import { getColumns, EmptyComponent } from "./software-koppelingen-data-grid-components";

const BeschikbareSoftwareKoppelingen = ({
  formik
}: {
  formik: FormikContextType<SoftwareKoppelingenType>;
}): ReactElement => {
  const { values, setFieldValue } = formik;

  const addSoftwareKoppeling = (): void => {
    const curr = values.partijen;
    curr.push({
      softwareKoppelingId: "",
      actief: true,
      hdnNummer: null,
      naam: "",
      wijzigbaar: true
    });

    setFieldValue("partijen", curr);
  };

  return (
    <>
      <DataGrid
        name="partijen"
        editable={true}
        resizable={false}
        sortable={false}
        filterable={false}
        columns={getColumns(formik)}
        minRows={values.partijen.length || 0}
        pageSize={values.partijen.length || 0}
        showPagination={false}
        NoDataComponent={EmptyComponent}
      />

      <div className="button-container">
        <button
          id="add-software-koppeling-button"
          className="btn btn-primary"
          type="button"
          onClick={addSoftwareKoppeling}
        >
          + Software koppeling toevoegen
        </button>
      </div>
    </>
  );
};

export default connect<{}, SoftwareKoppelingenType>(BeschikbareSoftwareKoppelingen);
