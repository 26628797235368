import { FormikContextType, FormikHelpers } from "formik";
import { UiError } from "../types";

export const setFormikUiErrors = (errors: UiError[], formik: FormikContextType<any> | FormikHelpers<any>): void => {
  formik.setFieldValue("platformApiFouten", errors);

  errors.forEach((element): void => {
    if (element.field) {
      formik.setFieldError(element.field, element.error);
      formik.setFieldTouched(element.field);
    }
  });
};
